import React, { ReactNode } from 'react';

import { MainMenu } from './MainMenu';

import './MainLayout.css'; 

interface MainLayoutProps {
	children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => (
	<div className="flex">
		<MainMenu />
		<div className="flex w-full h-auto min-h-screen bg-gray7 pl-[17rem] pb-10 items-start">
			{children}
		</div>
	</div>
);

export default MainLayout;