import React, { useState, useEffect, useRef, ChangeEventHandler, ChangeEvent } from 'react';
import axios from 'axios';

import { AddressData } from '../interfaces';

type SearchAddressInputProps = {
  id?: string | undefined;
  name?: string;
  placeholder?: string;
  value?: string | number | readonly string[] | undefined;
  error?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, addressData?: AddressData) => void;
  suggestionProvider?: (query: string) => Promise<AddressData[]> | undefined;
  disabled?: boolean;
  displayFormat?: (addressData: AddressData) => string;
  inputFormat?: (addressData: AddressData) => string;
};

export const SearchAddressInput = ({
  id,
  name,
  placeholder,
  value,
  error,
  className,
  onChange,
  suggestionProvider,
  disabled = false,
  displayFormat = (data) => `${data.ZipCode}, ${data.Canton}, ${data.TownName}, ${data.StreetName}`,
  inputFormat = (data) => data.StreetName || '',
}: React.PropsWithChildren<SearchAddressInputProps>) => {

  const [suggestionList, setSuggestionList] = useState<AddressData[]>([]);
  const [isFromSuggestion, setIsFromSuggestion] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!isFromSuggestion && value) {
      const delayDebounceFn = setTimeout(async () => {
        try {
          if (typeof suggestionProvider === 'function') {
            const providedSuggestions = await suggestionProvider(value.toString());
            setSuggestionList(providedSuggestions ?? []);
          }
        } catch (error) {
          console.error('Error getting suggestions:', error);
        }
      }, 300);

      return () => clearTimeout(delayDebounceFn);
    } else {
      setSuggestionList([]);
    }
  }, [value, isFromSuggestion, suggestionProvider]);


  const handleSuggestionClick = (suggestion: AddressData) => {
    const inputValue = inputFormat(suggestion);
    const event = {
      target: {
        value: inputValue,
        name: name,
      },
    } as ChangeEvent<HTMLInputElement>;

    onChange?.(event, suggestion);
    setSuggestionList([]);
    setIsFromSuggestion(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    setIsFromSuggestion(false);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  return (
    <div className="relative">
      <input
        ref={inputRef}
        id={id}
        name={name}
        type="text"
        value={value}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        disabled={disabled}
        className={`w-full p-3 border rounded-lg outline-none text-sm ${error ? 'border-ceRed' : 'border-gray5 focus:border-main'} disabled:bg-gray6`}
      />
      {suggestionList.length > 0 && isFocused && (
        <ul className="absolute top-full left-0 w-full max-h-52 overflow-scroll bg-white border border-gray5 rounded-lg p-3 mt-2 text-sm space-y-2 z-50">
          {suggestionList.map((suggestion, index) => (
            <li 
              key={index}
              className="item cursor-pointer py-0.5 hover:font-medium"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              {displayFormat(suggestion)}
            </li>
          ))}
        </ul>
      )}
      {error && <div className="text-ceRed text-xs mt-2">{error}</div>}
    </div>
  );
};