import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import ConfirmModal from '../components/ui/ConfirmModal';

import { ProjectTemplate } from '../entities/ProjectTemplate';
import { Partner } from '../entities/Partner';

import { Select } from "../components/Select";
import { SelectOption, OptionItem } from "../types";

type ProjectTemplatesConfig = {
  [key: string]: {
    label: string;
    sortKey?: boolean;
  };
};

const ProjectTemplates = () => {

	const location = useLocation();
  	const queryParams = new URLSearchParams(location.search);

	const [sortOrder, setSortOrder] = useState('asc');
  	const [sortKey, setSortKey] = useState(null);
  	const [searchTerm, setSearchTerm] = useState('');
  	const [selectedRows, setSelectedRows] = useState<string[]>([]);
  	const [entityData, setEntityData] = useState<{ id: string; name: string; }[]>([]);
  	const [showConfirmModal, setShowConfirmModal] = useState(false);
  	const navigate = useNavigate();
  	const baseUrl = window.location.pathname;

  	// Filters
  	const [optionsPartner, setOptionsPartner] = useState<Record<string, string>>({});
  	const [selectedPartner, setSelectedPartner] = useState('');
  	

  	const columnConfig: ProjectTemplatesConfig = {
		name: { label: 'Name', sortKey: true },
		description: { label: 'Description', sortKey: true },
		category: { label: 'Category', sortKey: true },
	};

  	const handleSort = (key: any) => {
	    if (sortKey === key) {
	      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	    } else {
	      setSortKey(key);
	      setSortOrder('asc');
	    }
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
	    setSearchTerm(event.target.value);
	};

	const handleRowCheckboxChange = (id: string) => {
	  	setSelectedRows((prevSelectedRows) => {
		    if (prevSelectedRows.includes(id)) {
		      	return prevSelectedRows.filter((rowId) => rowId !== id);
		    } else {
		      	return [...prevSelectedRows, id];
		    }
	  	});
	};

	const handleHeaderCheckboxChange = () => {
	  	if (selectedRows.length === entityData.length) {
	   	 	setSelectedRows([]);
	  	} else {
	    	setSelectedRows(entityData.map((item) => item.id));
	  	}
	};

	const filteredData = sortKey
	    ? entityData
	        .sort((a, b) => {
	          const valueA = a[sortKey];
	          const valueB = b[sortKey];

	          if (valueA < valueB) {
	            return sortOrder === 'asc' ? -1 : 1;
	          }
	          if (valueA > valueB) {
	            return sortOrder === 'asc' ? 1 : -1;
	          }
	          return 0;
	        })
	        .filter((item) =>
	          Object.values(item).some((value) =>
	            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	          )
	        )
	    : entityData.filter((item) =>
	        Object.values(item).some((value) =>
	          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	        )
	      );

	const fetchTemplates = async () => {
      	try {
      		const partnerId = selectedPartner && selectedPartner !== '0' ? selectedPartner : '';
      		if(!partnerId) return;

      		console.log("partnerId", partnerId);

        	const response = await ProjectTemplate.getAll(partnerId);
        	if (typeof response === 'string') {
		    	console.error('Error fetching project templates:', response);
		    } else {
		    	const templates = response.content;
			    setEntityData(templates);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
    };

    const fetchFilter = async () => {
    	try {
        	const response = await Partner.getAll();
        	if (typeof response === 'string') {
		    	console.error('Error fetching partners:', response);
		    } else {
		    	const partners = response.content;
		    	const optionsPartnerObj = partners.reduce((acc, option) => {
				  acc[option.id] = option.name;
				  return acc;
				}, {} as Record<string, string>);

			    setOptionsPartner(optionsPartnerObj);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
    };

    useEffect(() => {
	    fetchFilter();
	}, []);

	useEffect(() => {
	  	fetchTemplates();
	}, [selectedPartner]);

	useEffect(() => {
		const partnerId = queryParams.get('partnerId') ?? '';

		if(partnerId) {
			setSelectedPartner(partnerId);
		} else {
		  	const firstPartnerId = Object.keys(optionsPartner || {})[0];
		  	if(!firstPartnerId) return;

		  	if (!partnerId) {
		    	setSelectedPartner(firstPartnerId);
		  	}
	  	}
	}, [optionsPartner, location.search]);

	const filterChange = (value: string, option: SelectOption) => {
		const selectedVal = option.value;
		setSelectedPartner(selectedVal);
	};

	const confirmDelete = async () => {
	  	for (const rowId of selectedRows) {
	    	try {
	      		console.log("delete = ", rowId);
	      		await ProjectTemplate.delete(rowId);
	    	} catch (error) {
	      		console.error(`Error deleting the project template ${rowId}:`, error);
	    	}
	  	}
	  	closeConfirmModal();
	};

	const handleDelete = async () => {
		if (selectedRows.length > 0) {
		    openConfirmModal();
		}
	};

	const openConfirmModal = () => {
	  	setShowConfirmModal(true);
	};

	const closeConfirmModal = () => {
		fetchTemplates();
		setSelectedRows([]);
	  	setShowConfirmModal(false);
	};

	return (
		<div className="px-8 py-3 w-full h-auto min-h-screen m-auto max-w-[1330px]">
			<ConfirmModal
		        isOpen={showConfirmModal}
		        onConfirm={confirmDelete}
		        onCancel={closeConfirmModal}
		    />

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Project Template management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<span className="text-main">Project Templates</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-full flex mb-10 justify-between gap-8">
							<div className="w-1/3 relative h-10">
								<Select
				                    id="partner"
				                    placeholder={`Choose partner`}
				                    value={selectedPartner}
				                    options={optionsPartner}
				                    name="partner"
				                    onSelected={filterChange} 
				                />
							</div>

							<div className="h-10 flex gap-8">
								<button 
									className="bg-red-300 px-6 h-12 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] disabled:bg-gray4" 
									disabled={selectedRows.length === 0}
									onClick={handleDelete}
								>
									Delete
								</button>
								<Link to={`${baseUrl}/add?partnerId=${selectedPartner}`} className="bg-brandGreen px-6 h-12 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Template</Link>
							</div>
						</div>

						<table className="w-full text-xs">
	  						<thead>
								<tr className="text-gray3 border-b border-b-gray3 text-left">
									<th className="px-2 pb-4 w-10">
										<input 
											type="checkbox" 
									        checked={selectedRows.length > 0 && selectedRows.length === entityData.length}
									        onChange={handleHeaderCheckboxChange}
										/>
									</th>
							    {Object.entries(columnConfig).map(([key, config]) => (
				                    <th
				                      key={key}
				                      className={`font-normal pb-4 ${config.sortKey ? 'cursor-pointer hover:font-semibold' : ''}`}
				                      onClick={() => config.sortKey && handleSort(key)}
				                    >
				                      {config.label}
				                      {config.sortKey && sortKey === key && (
				                        <span className="ml-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>
				                      )}
				                    </th>
				                ))}
								</tr>
							</thead>
	  						<tbody>
							{filteredData.map((item) => (
			                  <tr
							    key={item.id}
							    className={`text-main border-b border-b-gray4 cursor-pointer hover:bg-green8 ${
							        selectedRows.includes(item.id) ? 'bg-gray6' : ''
							    }`}
							  >
			                  	<td className="px-2 w-10">
									<input 
										type="checkbox" 
										id={`item_${item.id}`} 
								        checked={selectedRows.includes(item.id)}
								        onChange={() => handleRowCheckboxChange(item.id)}
									/>
								</td>
								{Object.entries(columnConfig).map(([key, value], index) => (
			                    <td 
			                    	key={`${item.id}-${key}-${index}`} 
			                    	className="py-4"
							    	onClick={() => navigate(`${baseUrl}/edit/${item.id}`)}
			                    >
			                      	{(() => {
						                const cellValue = (item as any)[key];
						                if (typeof cellValue === 'object' && cellValue !== null) {
						                	return 'name' in cellValue ? cellValue.name : JSON.stringify(cellValue);
						                }
						                return cellValue;
					                })()}
			                    </td>
			                    ))}
			                  </tr>
			                ))}
							</tbody>
	  					</table>
					</div>

				</div>

			</div>

		</div>
	)
};

export default ProjectTemplates;