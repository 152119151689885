import React from 'react';

import ContractorCard from '../../components/ui/ContractorCard';


const ProjectContractors = () => (

	<div className="flex space-x-6 my-6">
		<ContractorCard />
		<ContractorCard />
		<ContractorCard />
		<ContractorCard />
	</div>

);

export default ProjectContractors;