import React, { useState, useRef, useEffect } from 'react';

const ActionList = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isRightDisabled, setIsRightDisabled] = useState(false);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const slides = [
        {
            id: 1,
            date: "1 hour",
            action: "Description of the Action",
            user: "Eduard Franz",
            userPic: "avatar.png",
            duration: "3 h 29 min",
        },
        {
            id: 2,
            date: "2 hours",
            action: "Description of the Action",
            user: "Eduard Franz",
            userPic: "avatar.png",
            duration: "3 h 29 min",
        },
        {
            id: 3,
            date: "3 hours",
            action: "Description of the Action",
            user: "Eduard Franz",
            userPic: "avatar.png",
            duration: "3 h 29 min",
        },
        {
            id: 4,
            date: "1 hour",
            action: "Description of the Action",
            user: "Eduard Franz",
            userPic: "avatar.png",
            duration: "3 h 29 min",
        },
        {
            id: 5,
            date: "1 hour",
            action: "Description of the Action",
            user: "Eduard Franz",
            userPic: "avatar.png",
            duration: "3 h 29 min",
        },
        {
            id: 6,
            date: "6 hours",
            action: "Description of the Action",
            user: "Eduard Franz",
            userPic: "avatar.png",
            duration: "3 h 29 min",
        },
    ];

    const getContainerInfo = () => {
        const container = containerRef.current;
        if (!container) return null;

        const firstChild = container.firstChild as HTMLElement | null;
        const itemWidth = firstChild?.offsetWidth ? firstChild.offsetWidth + 16 : 0;
        const containerWidth = container.offsetWidth;
        const maxIndex = slides.length - Math.floor(containerWidth / itemWidth);

        return { itemWidth, containerWidth, maxIndex };
    };

    const handleLeftClick = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleRightClick = () => {
        const containerInfo = getContainerInfo();

        if (containerInfo && currentIndex < containerInfo.maxIndex) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const updateRightDisabled = () => {
        const containerInfo = getContainerInfo();
        if (containerInfo) {
            setIsRightDisabled(currentIndex >= containerInfo.maxIndex);
        }
    };

    useEffect(() => {
        const containerInfo = getContainerInfo();

        if (containerInfo) {
            const { itemWidth, containerWidth, maxIndex } = containerInfo;
            const delta = currentIndex * itemWidth;
            const maxTranslate = (slides.length - Math.floor(containerWidth / itemWidth)) * itemWidth;

            const container = containerRef.current;
            if (container) {
                container.style.transform = `translateX(-${Math.min(delta, maxTranslate)}px)`;
            }
        }

        updateRightDisabled();

    }, [currentIndex]);

    return (
        <div className="p-6 border-gray5 border rounded-lg bg-white w-full mt-6 space-y-6 hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
            <div className="flex space-x-4 items-stretch">
                <h3 className="font-semibold text-main text-lg inline-block mr-auto">Last Actions</h3>
                <div className="flex space-x-2">
                    <button 
                        className="w-9 h-9 bg-white border border-gray4 rounded-md drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)] flex items-center justify-center"
                        onClick={handleLeftClick}
                    >
                        <svg className="rotate-180" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={`${currentIndex ? 'stroke-main' : 'stroke-gray4'}`} d="M6.93077 6.82709L1.43057 1.07704C1.33488 0.97792 1.17667 0.973514 1.07705 1.06923C0.977439 1.1647 0.974018 1.3229 1.06925 1.42274L6.4039 6.99994L1.06922 12.5772C0.973992 12.677 0.977414 12.8352 1.07703 12.9307C1.12538 12.9771 1.18787 13 1.24989 13C1.31582 13 1.38123 12.9741 1.43055 12.9228L6.93077 7.1728C7.02307 7.07611 7.02307 6.92377 6.93077 6.82709Z" />
                        </svg>
                    </button>
                    <button 
                        className="w-9 h-9 bg-white border border-gray4 rounded-md drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)] flex items-center justify-center"
                        onClick={handleRightClick}
                    >
                        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={`${isRightDisabled ? 'stroke-gray4' : 'stroke-main'}`} d="M6.93077 6.82709L1.43057 1.07704C1.33488 0.97792 1.17667 0.973514 1.07705 1.06923C0.977439 1.1647 0.974018 1.3229 1.06925 1.42274L6.4039 6.99994L1.06922 12.5772C0.973992 12.677 0.977414 12.8352 1.07703 12.9307C1.12538 12.9771 1.18787 13 1.24989 13C1.31582 13 1.38123 12.9741 1.43055 12.9228L6.93077 7.1728C7.02307 7.07611 7.02307 6.92377 6.93077 6.82709Z" />
                        </svg>
                    </button>
                </div>
            </div>

            <div className="w-full overflow-hidden">
                <div className="flex space-x-4 transition-transform duration-500 ease-in-out" ref={containerRef}>

                    {slides.map((slide, index) => (
                        <div key={index} className="border-gray5 border bg-white rounded-md w-1/5 min-w-[11.5rem] py-4 px-[1.125rem] space-y-4 text-xs drop-shadow-[0_2px_8px_rgba(0,0,0,0.05)] hover:border-brandGreen hover:border-2">
                            <div className="py-1 px-2 border border-gray4 rounded inline-flex mx-auto">
                                <img src="/img/icons/timer.svg" className="mr-1 w-3 h-3 mt-0.5" />
                                Date:
                                <span className="font-semibold text-main ml-1">{slide.date} ago</span>
                            </div>
                            <div className="w-16 h-16 rounded-full bg-brandGreen mx-auto flex items-center justify-center">
                                <img src="/img/icons/like.svg" className="w-8 h-8" />
                            </div>
                            <div className="text-center space-y-2">
                                <div className="font-semibold text-base">{slide.action}</div>
                                <div className="flex place-items-center justify-center">
                                    <img src={`/img/${slide.userPic}`} className="w-6 h-6 rounded-full border-gray5 mr-1" />
                                    {slide.user}
                                </div>
                                <div className="text-gray3">Duration: {slide.duration}</div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
};

export default ActionList;