import { useState, useEffect } from 'react';

import { FieldConfig } from '../interfaces';


export const useLoadFormFields = (
  fields: Record<string, FieldConfig>,
  setFields: React.Dispatch<React.SetStateAction<Record<string, FieldConfig>>>
) => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});

  const loadSelectOptions = async (fieldKey?: string) => {
    const fieldsToLoad = fieldKey
      ? Object.entries(fields).filter(([key, field]) => key === fieldKey && field.type === 'select' && field.dataSource)
      : Object.entries(fields).filter(([_, field]) => field.type === 'select' && field.dataSource);

    for (const [key, field] of fieldsToLoad) {
      const { dataSource, dataFilter, emptyOption } = field;
      setLoadingStates((prevState) => ({ ...prevState, [key]: true }));

      try {
        if (dataSource) {
          const dataSourceClass = (window as any)[dataSource];
          if (dataSourceClass && typeof dataSourceClass.getForSelect === 'function') {
            const response = await dataSourceClass.getForSelect(dataFilter);
            // console.log(dataSource, response);
            if (typeof response !== 'string') {
              let options: Record<string, string> = {};

              if (emptyOption) {
                options[''] = (typeof emptyOption === 'string') ? emptyOption : '----';
              }

              response.forEach((item: any) => {
                options[item.id] = item.name;
              });

              setFields((prevFields) => ({
                ...prevFields,
                [key]: { ...prevFields[key], options },
              }));
              console.log("setFields", fields);
            } else {
              console.error(`Error fetching ${dataSource} data:`, response);
            }
          } else {
            console.error(`Invalid dataSource or missing getForSelect method for field ${key}`);
          }
        } else {
          console.error(`Invalid dataSource for field ${key}`);
        }
      } catch (error) {
        console.error(`Error fetching ${dataSource} data:`, error);
      } finally {
        setLoadingStates((prevState) => ({ ...prevState, [key]: false }));
      }
    }
  };

  useEffect(() => {
    loadSelectOptions();
  }, []);

  return { loadingStates, loadSelectOptions };
};