import axios, { AxiosError } from 'axios';

import { AddressData, AutoCompleteRequest } from '../interfaces';

export class GeoApiService {
  private readonly baseUrl: string;
  private readonly username: string;
  private readonly password: string;

  constructor() {
    this.baseUrl = 'https://webservices.post.ch:17023/IN_SYNSYN_EXT/REST/v1';
    this.username = 'TU_7729339_0001';
    this.password = '2XSPzYh2BRJZ';
  }

  private getAuthHeader = () => {
    const credentials = `${this.username}:${this.password}`;
    const base64Credentials = btoa(credentials);
    return `Basic ${base64Credentials}`;
  }

  private async makeRequest(request: Partial<AutoCompleteRequest>): Promise<AddressData[]> {
    const url = `${this.baseUrl}/autocomplete4`;
    const fullRequest: AutoCompleteRequest = {
      ONRP: 0,
      ZipCode: '',
      ZipAddition: '',
      TownName: '',
      STRID: 0,
      StreetName: '',
      HouseKey: 0,
      HouseNo: '',
      HouseNoAddition: '',
      ...request
    };

    const requestBody = {
      request: fullRequest,
      zipOrderMode: 0,
      zipFilterMode: 0,
    };

    try {
      const response = await axios.post(url, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: this.getAuthHeader(),
        },
      });

      return response.data.QueryAutoComplete4Result.AutoCompleteResult.map((result: any) => ({
        ZipCode: result.ZipCode,
        Canton: result.Canton,
        TownName: result.TownName,
        StreetName: result.StreetName,
        HouseNo: result.HouseNo,
      }));
    } catch (error) {
      console.error(`Error getting suggestions:`, error);
      throw error;
    }
  }

  getZipSuggestions = (query: string) => this.makeRequest({ ZipCode: query });

  getCitySuggestions = (zip: string, city: string) => this.makeRequest({ ZipCode: zip, TownName: city });

  getStreetSuggestions = (zip: string, street: string) => this.makeRequest({ ZipCode: zip, StreetName: street });

  getHouseSuggestions = (zip: string, street: string, number: string) => this.makeRequest({ ZipCode: zip, StreetName: street, HouseNo: number });
}
