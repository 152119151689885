import React, { useState } from 'react';

import { Input } from "../components/Input";
import { PasswordInput } from "../components/PasswordInput";
import { Select } from "../components/Select";
import { SearchAddressInput } from "../components/SearchAddressInput";
import { SelectOption, OptionItem } from "../types";

import { countriesDict, languagesDict } from '../utils/constants';

import { GeoApiService } from '../classes/GeoApiService';

import { useFormik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';

const optionsCountry = countriesDict;
const optionsLanguage = languagesDict;

const validationSchema = Yup.object().shape({
  	Email: Yup.string()
                .email('E-mail wrong format')
                .required('E-mail is required'),
    Password: Yup.string()
    			.matches(
			    	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]{8,}$/,
			      	'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character'
			    )
    			.matches(/^\S*$/, 'Must not contain spaces')
    			.required('Password is required'),
    ConfirmPassword: Yup.string()
    			.test('passwords-match', 'Passwords must match', function(value) {
			      	return this.parent.Password === value;
			    })
    			.required('Confirm Password is required'),
});

const step2ValidationSchema = Yup.object().shape({
	Language: Yup.string().required('Language is required'),
	Country: Yup.string().required('Country is required'),
	Zip: Yup.string().required('Zip Code is required'),
	City: Yup.string().required('City is required'),
	Street: Yup.string().required('Street address is required'),
	House: Yup.string().required('Building number is required'),
});

interface FormValues {
  Email: string;
  Password: string;
  ConfirmPassword: string;
};

const initialValues: FormValues = {
  Email: 'testmail@testmail.com',
  Password: '',
  ConfirmPassword: '',
};

// Step2 form
interface FormStep2Values {
  Language: string;
  Country: string;
  Zip: string;
  City: string;
  Street: string;
  House: string;
};

const initialStep2Values: FormStep2Values = {
  Language: '',
  Country: 'CH',
  Zip: '',
  City: '',
  Street: '',
  House: '',
};
// =====

interface RequestProps {
  formik: FormikProps<FormValues>;
}

const SignUp = () => {

	const [activeBlock, setActiveBlock] = useState('signup');
	const geoApiService = new GeoApiService();

	const switchBlocks = (tab: string) => {
	    console.log(tab);
	    setActiveBlock(tab);
	};

	const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
	    try {
	      const errors = await formik.validateForm();

	      if (Object.keys(errors).length === 0) {
	        console.log('Submitted values:', formik.values);
	      }

	      switchBlocks('step2');

	      //formik.handleSubmit();
	    } catch (error) {
	      console.error('Error fetching property by number in component:', error);
	    }
	};

	const handleSubmit2 = async (values: FormStep2Values, actions: FormikHelpers<FormStep2Values>) => {
	    console.log('Submitted values:', formik2.values);
	};

	const formik: FormikProps<FormValues> = useFormik<FormValues>({
	    initialValues,
	    validationSchema,
	    onSubmit: handleSubmit,
	});

	const formik2: FormikProps<FormStep2Values> = useFormik<FormStep2Values>({
	    initialValues: initialStep2Values,
	    validationSchema: step2ValidationSchema,
	    onSubmit: handleSubmit2,
	});

	const handleSelect = (field: string, option: SelectOption) => {
    	formik2.setFieldValue(field, option.value);
  	};


  	return (
	  	<div className="px-9 pt-6 pb-10 w-full flex flex-1">

	      	<form className={`w-1/2 flex justify-center ${activeBlock === 'signup' ? '' : 'hidden'}`} onSubmit={formik.handleSubmit}>
		        <div className="flex items-center">
		           <div className="text-center">
		               	<h1 className="text-main font-semibold text-2xl">Create your account</h1>

		                <div className="my-12 space-y-4 w-[360px]">

			                <div className="w-full space-y-2 text-left">
			                    <label className={`text-sm text-main`}>Organization</label>
			                    <div className="py-3 font-semibold">Wincasa AG</div>
			                </div>
			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="Email" className={`text-sm ${formik.touched.Email && formik.errors.Email ? 'text-ceRed' : 'text-main'}`}>Email</label>
			                    <Input 
			                      type="email" 
			                      id="Email" 
			                      placeholder="Enter your email" 
			                      name="Email"
			                      value={formik.values.Email}
			                      onChange={formik.handleChange} 
			                      error={
			                        formik.touched.Email && formik.errors.Email
			                          ? formik.errors.Email
			                          : undefined
			                      }
			                    />
			                </div>
			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="Password" className={`text-sm ${formik.touched.Password && formik.errors.Password ? 'text-ceRed' : 'text-main'}`}>Password</label>
			                    <PasswordInput 
			                      id="Password" 
			                      placeholder="Enter your password" 
			                      name="Password"
			                      onChange={formik.handleChange} 
			                      error={
			                        formik.touched.Password && formik.errors.Password
			                          ? formik.errors.Password
			                          : undefined
			                      }
			                    />
			                </div>
			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="ConfirmPassword" className={`text-sm ${formik.touched.ConfirmPassword && formik.errors.ConfirmPassword ? 'text-ceRed' : 'text-main'}`}>Confirm password</label>
			                    <PasswordInput 
			                      id="ConfirmPassword" 
			                      placeholder="Confirm your password" 
			                      name="ConfirmPassword"
			                      onChange={formik.handleChange} 
			                      error={
			                        formik.touched.ConfirmPassword && formik.errors.ConfirmPassword
			                          ? formik.errors.ConfirmPassword
			                          : undefined
			                      }
			                    />
			                </div>

		                </div>

		               	<button 
		               		type="submit" 
		               		className="bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white"
		               	>
		               		Create account
		               	</button>
		               
		           </div>
		        </div>
	        </form>

	      	<form className={`w-1/2 flex justify-center ${activeBlock === 'step2' ? '' : 'hidden'}`} onSubmit={formik2.handleSubmit}>
		        <div className="flex items-center">
		           <div className="text-center">
		               <h1 className="text-main font-semibold text-2xl">You’re almost done</h1>
		               <div className="text-gray3 text-sm mt-2">Please, fill out personal info</div>

		                <div className="my-12 space-y-4 w-[360px]">

			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="country" className="text-sm text-main">Country</label>
			                    <Select
			                      id="Country"
			                      name="Country"
			                      placeholder="Choose your country"
			                      value={formik2.values.Country} 
			                      options={optionsCountry}
			                      onSelected={handleSelect}
			                      error={
			                        formik2.touched.Country && formik2.errors.Country
			                          ? formik2.errors.Country
			                          : undefined
			                      }
			                    />
			                </div>

			                <div className="w-full space-y-2 text-left mt-6">
			                    <label htmlFor="text-10" className="text-sm text-main">Street</label>
			                    <SearchAddressInput
			                      id="Street"
			                      name="Street"
			                      placeholder="Enter your street"
			                      value={formik2.values.Street}
			                      onChange={(event, addressData) => {
			                        formik2.handleChange(event);
			                        if (addressData) {
			                          formik2.setFieldValue('Zip', addressData.ZipCode);
			                          formik2.setFieldValue('City', addressData.TownName);
			                        }
			                      }}
			                      suggestionProvider={(query) => geoApiService.getStreetSuggestions(formik2.values.Zip, query)}
			                      displayFormat={(data) => `${data.StreetName}, ${data.ZipCode} ${data.Canton} ${data.TownName}`}
			                      inputFormat={(data) => data.StreetName || ''}
			                      error={
			                        formik2.touched.Street && formik2.errors.Street
			                          ? formik2.errors.Street
			                          : undefined
			                      }
			                    />
			                </div>

			                <div className="w-full space-y-2 text-left mt-6">
			                    <label htmlFor="text-10" className="text-sm text-main">Building</label>
			                    <SearchAddressInput
			                      id="House"
			                      name="House"
			                      placeholder="Enter your building number"
			                      value={formik2.values.House}
			                      onChange={formik2.handleChange} 
			                      suggestionProvider={(query) => geoApiService.getHouseSuggestions(formik2.values.Zip, formik2.values.Street, query)}
			                      displayFormat={(data) => `${data.HouseNo}`}
			                      inputFormat={(data) => data.HouseNo || ''}
			                      error={
			                        formik2.touched.House && formik2.errors.House
			                          ? formik2.errors.House
			                          : undefined
			                      }
			                    />
			                </div>

			                <div className="w-full space-y-2 text-left mt-6">
			                    <label htmlFor="text-11" className="text-sm text-main">Zip Code</label>
			                    <SearchAddressInput
			                      id="Zip"
			                      name="Zip"
			                      placeholder="Enter your zip code"
			                      value={formik2.values.Zip} 
			                      onChange={formik2.handleChange} 
			                      suggestionProvider={geoApiService.getZipSuggestions}
			                      displayFormat={(data) => `${data.ZipCode}`}
			                      inputFormat={(data) => data.ZipCode || ''}
			                      error={
			                        formik2.touched.Zip && formik2.errors.Zip
			                          ? formik2.errors.Zip
			                          : undefined
			                      }
			                    />
			                </div>

			                <div className="w-full space-y-2 text-left mt-6">
			                    <label htmlFor="city" className="text-sm text-main">City</label>
			                    <SearchAddressInput
			                      id="City"
			                      name="City"
			                      placeholder="Enter your city"
			                      value={formik2.values.City} 
			                      onChange={formik2.handleChange} 
			                      suggestionProvider={(query) => geoApiService.getCitySuggestions(formik2.values.Zip, query)}
			                      displayFormat={(data) => `${data.TownName}, ${data.ZipCode} ${data.Canton}`}
			                      inputFormat={(data) => data.TownName || ''}
			                      error={
			                        formik2.touched.City && formik2.errors.City
			                          ? formik2.errors.City
			                          : undefined
			                      }
			                    />
			                </div>

			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="Language" className="text-sm text-main">Language</label>
			                    <Select
			                      id="Language"
			                      name="Language"
			                      placeholder="Select your language"
			                      value={formik2.values.Language} 
			                      options={optionsLanguage}
			                      onSelected={handleSelect}
			                      error={
			                        formik2.touched.Language && formik2.errors.Language
			                          ? formik2.errors.Language
			                          : undefined
			                      }
			                    />
			                </div>

		                </div>

		               <button type="submit" className="bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">Submit</button>
	
		           </div>
		        </div>
	        </form>

	        <div className="w-1/2 h-auto overflow-hidden rounded relative">
	          <img src="/img/login-pic.jpg" className="absolute inset-0 w-full h-full object-cover" />
	        </div>
	      
	    </div>
  	);
};

export default SignUp;