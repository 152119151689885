import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

import { Sort, Pageable, ApiResponse } from '../interfaces';

interface ProjectTemplateData {
  id: string;
  name: string;
}

export class ProjectTemplate {
  id: string;
  name_de: string;
  name_fr: string;
  name_it: string;
  name_rm: string;
  description_de?: string;
  description_fr?: string;
  description_it?: string;
  description_rm?: string;
  partnerId: string;
  categoryId: string;

  constructor(
      id: string, 
      name_de: string, 
      name_fr: string, 
      name_it: string, 
      name_rm: string, 
      partnerId: string, 
      categoryId: string
  ) {
    this.id = id;
    this.name_de = name_de;
    this.name_fr = name_fr;
    this.name_it = name_it;
    this.name_rm = name_rm;
    this.partnerId = partnerId;
    this.categoryId = categoryId;

    this.description_de = '';
    this.description_fr = '';
    this.description_it = '';
    this.description_rm = '';
  }

  static async add
    (params: {
      name_de: string;
      name_fr: string;
      name_it: string;
      name_rm: string;
      description_de: string;
      description_fr: string;
      description_it: string;
      description_rm: string;
      categoryId: string;
      partnerId: string;
    }): Promise<ProjectTemplate | string> {

    const requestParams = {
      "names": {
        "de": params.name_de,
        "fr": params.name_fr,
        "it": params.name_it,
        "rm": params.name_rm,
      },
      "descriptions": {
        "de": params.description_de ?? '',
        "fr": params.description_fr ?? '',
        "it": params.description_it ?? '',
        "rm": params.description_rm ?? '',
      },
      "partnerId": params.partnerId,
      "categoryId": params.categoryId,
    };

    console.log("requestParams", requestParams);

    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }

    try {
      const data = await apiHandler.post<ProjectTemplate>(`/project-templates`, requestParams, config);
      return data;
    } catch (error: any) {
      return 'An error occurred while adding the project template';
    }

    return '';
  }

  static async getAll(partnerId: string): Promise<ApiResponse<ProjectTemplateData> | string> {

    const accessToken = localStorage.getItem('token');

    const params = {
      "partnerId": partnerId,
      "page": 0,
      "size": 1000,
      "sort": []
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.get<ApiResponse<ProjectTemplateData>>(`/project-templates?${ApiHandler.getQueryString(params)}`, config);
      console.log("data", data);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Project templates not found';
      } else {
        return 'An error occurred while fetching the project templates';
      }
    }
  }

  static async delete(roleId: string): Promise<string> {

    const accessToken = localStorage.getItem('token');

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.delete<string>(`/project-templates/${roleId}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Project template not found';
      } else {
        return 'An error occurred while deleting the project template';
      }
    }
    
  }

}