import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

import { Sort, Pageable, ApiResponse, SelectResponse } from '../interfaces';

interface TokenResponse {
  token: string;
  newRefreshToken: string;
}

interface UserData {
  id: string;
  firstName: string;
  lastName: string;
}

export class User {
  id: string;
  email: string;
  token: string | null;
  refreshToken: string | null;

  constructor(id: string, email: string, token: string | null = null, refreshToken: string | null = null) {
    this.id = id;
    this.email = email;
    this.token = token;
    this.refreshToken = refreshToken;
  }

  static async login(email: string, password: string): Promise<User | string> {
    const apiHandler = new ApiHandler();

    const formData = new FormData();
    formData.append('grant_type', 'password');
    formData.append('scopes', 'chargeeasy email openid');
    formData.append('username', email);
    formData.append('password', password);

    try {
      const response = await apiHandler.post<any>('/oauth/token', formData, {
        headers: {
          'Authorization': 'Basic d2ViOmRmNW5BN1Q0UlhndDNaQ3o=',
          'Content-Type': 'multipart/form-data',
        },
      });

      const { access_token, refresh_token, user_id } = response;
      const currentUser = new User(user_id, email, access_token, refresh_token);
      return currentUser;

    } catch (error: any) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        return 'Invalid email or password';
      } else {
        return 'An error occurred during login';
      }
    }
  }

  static async refreshAuthToken(refreshToken: string): Promise<TokenResponse> {
    const apiHandler = new ApiHandler();
    const formData = new FormData();

    formData.append('grant_type', 'refresh_token');
    formData.append('scopes', 'chargeeasy email openid');
    formData.append('refresh_token', refreshToken);

    try {
      const response = await apiHandler.post<any>('/oauth/token', formData, {
        headers: {
          'Authorization': 'Basic d2ViOmRmNW5BN1Q0UlhndDNaQ3o=',
          'Content-Type': 'multipart/form-data',
        },
      });

      return { token: response.access_token, newRefreshToken: response.refresh_token };

    } catch (error) {
      console.error('Error refreshing token:', error);
      return { token: '', newRefreshToken: '' };
    }
  }

  static async getAll(partnerId?: string, organizationId?: string): Promise<ApiResponse<UserData> | string> {
    const accessToken = localStorage.getItem('token');

    const params = {
      "partnerId": partnerId || '',
      "organizationId": organizationId || '',
      "page": 0,
      "size": 1000,
      "sort": []
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.get<ApiResponse<UserData>>(`/users?${ApiHandler.getQueryString(params)}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Users not found';
      } else {
        return 'An error occurred while fetching the users';
      }
    }
  }

  static async getForSelect(): Promise<SelectResponse[] | string> {
    const usersResponse = await User.getAll();

    if (typeof usersResponse === 'string') {
      return usersResponse;
    }

    const selectResponses: SelectResponse[] = usersResponse.content.map(({ id, firstName, lastName }) => ({
      id,
      name: `${firstName} ${lastName}`,
    }));

    return selectResponses;
  }

  static async add
    (params: {
      email: string;
      firstName: string;
      lastName: string;
      organizationId: string;
      partnerId: string;
      roleId: string;
    }): Promise<User | string> {

    const requestParams = {
      "email": params.email,
      "firstName": params.firstName,
      "lastName": params.lastName,
    };
    if(params.organizationId) {
      Object.assign(requestParams, { organizationId: params.organizationId });
    }
    if(params.partnerId) {
      Object.assign(requestParams, { partnerId: params.partnerId });
    }
    if(params.roleId) {
      Object.assign(requestParams, { roleId: params.roleId });
    }

    console.log("requestParams", requestParams);

    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }

    try {
      const data = await apiHandler.post<User>(`/users/add`, requestParams, config);
      return data;
    } catch (error: any) {
      return 'An error occurred while adding the user';
    }

    return '';
  }

  static async delete(userId: string): Promise<string> {

    const accessToken = localStorage.getItem('token');

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.delete<string>(`/users/${userId}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'User not found';
      } else {
        return 'An error occurred while deleting the user';
      }
    }
    
  }

  static getCookieValue(name: string) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }
    return '';
  }

  async logout(): Promise<void> {
    try {
      this.token = null;
      this.refreshToken = null;
    } catch (error) {
      console.error('Error logging out:', error);
    }
  }
}
