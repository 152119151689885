import React from 'react';


const Header = () => (

	<div className="flex w-full px-9 py-4 justify-between">
		<a href="#">
			<img src="/img/logo.svg" className="w-[5.75rem]" />
		</a>
		<a href="#">
			<img src="/img/icons/faq.svg" className="w-[1.375rem]" />
		</a>
	</div>

);

export default Header;