import { KeyboardEventHandler, ChangeEventHandler, useState } from "react";
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

type PasswordInputProps = {
  id?: string | undefined;
  name?: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  pattern?: string;
  autoComplete?: string;
  autoCorrect?: string;
  value?: string | number | readonly string[] | undefined;
  error?: string;
  className?: string;
  onChange?: ChangeEventHandler | undefined;
  onKeyDown?: KeyboardEventHandler | undefined;
};

export const PasswordInput = ({
  id,
  name,
  min,
  max,
  step,
  placeholder,
  pattern,
  autoComplete,
  autoCorrect,
  value,
  error,
  className,
  onChange,
  onKeyDown,
}: React.PropsWithChildren<PasswordInputProps>) => {

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div>
      <div className="relative">
        <input
          id={id}
          name={name}
          type={showPassword ? 'text' : 'password'}
          min={min}
          max={max}
          step={step}
          pattern={pattern}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          className={`w-full p-3 border rounded-lg outline-none text-sm ${error ? 'border-ceRed' : 'border-gray5 focus:border-main'}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
        <button
          type="button"
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-700"
          onClick={togglePasswordVisibility}
        >
          {showPassword ? <EyeSlashIcon className="h-5 w-5 stroke-gray5" /> : <EyeIcon className="h-5 w-5 stroke-gray5" />}
        </button>
      </div>
      <div className="text-ceRed text-xs mt-2 ${error ? '' : 'hidden'}">
        {error ? error : '' }
      </div>
    </div>
  );
};
