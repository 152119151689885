import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

import { Sort, Pageable, ApiResponse } from '../interfaces';

interface ProjectPhaseData {
  id: string;
  name: string;
}

export class ProjectPhase {
  id: string;
  name_de: string;
  name_fr: string;
  name_it: string;
  name_rm: string;
  partnerId: string;
  orderNum: number;

  constructor(id: string, name_de: string, name_fr: string, name_it: string, name_rm: string, partnerId: string, orderNum: number) {
    this.id = id;
    this.name_de = name_de;
    this.name_fr = name_fr;
    this.name_it = name_it;
    this.name_rm = name_rm;
    this.partnerId = partnerId;
    this.orderNum = orderNum;
  }

  static async add
    (params: {
      name_de: string;
      name_fr: string;
      name_it: string;
      name_rm: string;
      orderNum: number;
      partnerId: string;
    }): Promise<ProjectPhase | string> {

    const requestParams = {
      "names": {
        "de": params.name_de,
        "fr": params.name_fr,
        "it": params.name_it,
        "rm": params.name_rm,
      },
      "partnerId": params.partnerId,
      "orderNum": params.orderNum,
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }

    try {
      const data = await apiHandler.post<ProjectPhase>(`/project-phases`, requestParams, config);
      return data;
    } catch (error: any) {
      return 'An error occurred while adding the project phase';
    }

    return '';
  }

  static async getAll(partnerId: string): Promise<ApiResponse<ProjectPhaseData> | string> {

    const accessToken = localStorage.getItem('token');

    const params = {
      "partnerId": partnerId,
      "page": 0,
      "size": 1000,
      "sort": []
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      console.log("get", `/project-phases?${ApiHandler.getQueryString(params)}`);
      const data = await apiHandler.get<ApiResponse<ProjectPhaseData>>(`/project-phases?${ApiHandler.getQueryString(params)}`, config);
      console.log("data", data);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Project phases not found';
      } else {
        return 'An error occurred while fetching the project phases';
      }
    }
  }

  static async delete(roleId: string): Promise<string> {

    const accessToken = localStorage.getItem('token');

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.delete<string>(`/project-phases/${roleId}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Project phase not found';
      } else {
        return 'An error occurred while deleting the project phase';
      }
    }
    
  }

}