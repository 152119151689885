import React, { useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import FormGenerator from "../components/FormGenerator";

import { ProjectPhase } from '../entities/ProjectPhase';

const ProjectPhaseForm = () => {

	const location = useLocation();
  	const queryParams = new URLSearchParams(location.search);
  	const partnerId = queryParams.get('partnerId') ?? '';

	const navigate = useNavigate();

	const numericId = undefined;

  	const fields = {
		id: { type: 'hidden' },
		partnerId: { type: 'hidden', value: partnerId },
		name_de: { label: 'Name (DE)', type: 'text', required: true },
		name_fr: { label: 'Name (FR)', type: 'text', required: true },
		name_it: { label: 'Name (IT)', type: 'text', required: true },
		name_rm: { label: 'Name (RM)', type: 'text', required: true },
		orderNum: { label: 'Order', type: 'number', required: true },
	};

	const handleSubmit = async (values: any) => {
	    console.log('Form values:', values);

	    try {
        	const response = await ProjectPhase.add({
				name_de: values.name_de,
				name_fr: values.name_fr,
				name_it: values.name_it,
				name_rm: values.name_rm,
				orderNum: values.orderNum,
				partnerId: values.partnerId,
			});
        	if (typeof response === 'string') {
		    	console.error('Error adding project phase:', response);
		    } else {
			    navigate('/admin/project-phases?partnerId=' + values.partnerId);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
	    
	};

	return (
		<div className="px-8 py-3 w-full h-auto m-auto max-w-[1330px]">

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Project Phase management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<Link to="/admin/project-phases" className="underline hover:no-underline">Project Phases</Link> / <span className="text-main">New Phase</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-1/2 space-y-8">

							<h2 className="font-semibold">Create New Phase</h2>

							<FormGenerator id={numericId} fields={fields} onSubmit={handleSubmit} />
							
						</div>

					</div>

				</div>

			</div>

		</div>
	)
};

export default ProjectPhaseForm;