import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

import { Sort, Pageable, ApiResponse, SelectResponse } from '../interfaces';

export class Organization {
  id: string;
  organizationNumber: string;
  name: string;
  type: string;
  logo: string;
  adminEmail: string;
  country: string;
  canton: string;
  city: string;
  zip: string;
  street: string;
  streetNumber: string;

  constructor(id: string, name: string, type: string, adminEmail: string) {
    this.id = id;
    this.organizationNumber = name;
    this.name = name;
    this.type = type;
    this.adminEmail = adminEmail;

    this.logo = '';
    this.country = '';
    this.canton = '';
    this.city = '';
    this.zip = '';
    this.street = '';
    this.streetNumber = '';
  }

  static async getById(id: string): Promise<Organization> {
    const data = await apiHandler.get<any>(`/organizations?id=${id}`);
    return new Organization(
      data.id, 
      data.organizationNumber,
      data.name,
      data.type,
    );
  }

  static async addFiles(
    attachments: File[]
  ): Promise<string[]> {

    try {

      const userId = localStorage.getItem('userId');
      const loadedFiles = [];

      if(attachments && attachments.length) {

        for (const file of attachments) {

          const formData = new FormData();
          formData.append('file', file);
          formData.append('userId', userId || '');
          formData.append('type', file.type.includes('image') ? 'image' : 'file');

          try {
            const response = await apiHandler.post<{ id: string }>(
              '/public/assets',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            loadedFiles.push(response.id);

          } catch (error) {
            if (error instanceof ApiError) {
              console.error('Error uploading file:', error.message);
              throw new Error(error.message);
            } else {
              console.error('Unexpected error:', error);
              throw error;
            }
          }

        }

        return loadedFiles;
      }

    } catch (error: any) {
      return [];
    }

    return [];

  }

  static async add
    (params: {
      name: string,
      type: string,
      logo?: File[],
      adminEmail: string,
      country?: string,
      canton?: string,
      city?: string,
      zip?: string,
      street?: string,
      streetNumber?: string,
    }): Promise<Organization | string> {

    const requestParams: Record<string, string> = {
      "name": params.name,
      "type": params.type,
      "adminEmail": params.adminEmail,
    };
    Object.assign(requestParams, {
      country: params.country || '',
      canton: params.canton || '',
      city: params.city || '',
      zip: params.zip || '',
      street: params.street || '',
      streetNumber: params.streetNumber || '',
    });

    if(params.logo) {
      const loadedFiles = await Organization.addFiles(params.logo);

      if(loadedFiles.length) {
        Object.assign(requestParams, {
          logoId: loadedFiles[0]
        });
      }
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }

    console.log("requestParams", requestParams);

    try {
      const data = await apiHandler.post<Organization>(`/organizations`, requestParams, config);
      return data;
    } catch (error: any) {
      return 'An error occurred while adding the organization';
    }

    return '';
  }

  static async getAll(): Promise<ApiResponse<Organization> | string> {

    const accessToken = localStorage.getItem('token');

    const params = {
      "page": 0,
      "size": 1000,
      "sort": []
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.get<ApiResponse<Organization>>(`/organizations?${ApiHandler.getQueryString(params)}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Partners not found';
      } else {
        return 'An error occurred while fetching the partners';
      }
    }
  }

  static async getForSelect(): Promise<SelectResponse[] | string> {
    const response = await Organization.getAll();

    if (typeof response === 'string') {
      return response;
    }

    const selectResponses: SelectResponse[] = response.content;
    return selectResponses;
  }

  static async delete(orgId: string): Promise<string> {

    const accessToken = localStorage.getItem('token');

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.delete<string>(`/organizations/${orgId}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Organization not found';
      } else {
        return 'An error occurred while deleting the organization';
      }
    }
    
  }

}