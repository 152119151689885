import React, { useRef, useEffect } from 'react';

const TrapezoidCanvas = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas?.getContext('2d');

    if (ctx && canvas) {
        const width = canvas.width;
        const height = canvas.height;
        const radius = 20;

        ctx.lineJoin = "round";
        ctx.lineWidth = 1;

        ctx.beginPath();
        ctx.moveTo(50, 60);
        ctx.arcTo(50, 60, 60, 50, 100);
        ctx.lineTo(180, 80);
        ctx.lineTo(180, 220);
        ctx.lineTo(50, 250)
        ctx.closePath();

        ctx.stroke();
        ctx.fillStyle = 'yellow';
        ctx.fill();
    }
  }, []);

  // return <canvas className="bg-red-100" ref={canvasRef} width={400} height={300} />;
  return (
    // <div className="relative border w-[300px] h-[300px]">
    //     <div className="absolute top-0 w-[120px] h-[100px] pt-1 text-center text-white bg-red-100 z-10">
    //             Lorem ipsum dolor<br />
    //       Lorem ipsum dolor<br />
    //     </div>
    //     <div className="relative w-10/12 left-[10%] [perspective:200px] [transform-style:preserve-3d]">
    //         <div className="absolute top-0 w-full border border-main border-opacity-15 rounded-lg bg-gradient-to-tr from-[#BEFD5D] to-[#7FD001] h-40 [transform:rotateX(20deg)] origin-center"></div>
    //     </div>
    // </div>
      <div className="w-full py-6">
          <div className="flex items-center justify-between px-4">
              <div className="uppercase px-2 py-2.5 border rounded border-main tracking-widest">Demand</div>
              <div className="px-3">
                  <img src="/img/icons/arrow-big.svg" className="h-9 w-4" />
              </div>
              <div className="uppercase px-2 py-2.5 border rounded border-main tracking-widest">Planning</div>
              <div className="px-3">
                  <img src="/img/icons/arrow-big.svg" className="h-9 w-4" />
              </div>
              <div className="uppercase px-2 py-2.5 border rounded border-main tracking-widest">Offering</div>
              <div className="px-3">
                  <img src="/img/icons/arrow-big.svg" className="h-9 w-4" />
              </div>
              <div className="uppercase px-2 py-2.5 border rounded border-main tracking-widest">Construc.</div>
              <div className="px-3">
                  <img src="/img/icons/arrow-big.svg" className="h-9 w-4" />
              </div>
              <div className="uppercase px-2 py-2.5 border rounded border-main tracking-widest">Go Live</div>
              <div className="px-3">
                  <img src="/img/icons/arrow-big.svg" className="h-9 w-4" />
              </div>
              <div className="uppercase px-2 py-2.5 border rounded border-main tracking-widest">Services</div>
          </div>

          <div className="w-full mt-6 flex items-center space-x-0.5 pt-4">
              <div className="w-1/6 flex relative items-center">
                  <img src="/img/funnel-1.svg" className="w-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] drop-shadow-[0_4px_8px_rgba(0,0,0,0.05)]" />
                  <div className="absolute space-y-3 text-sm text-green5 left-[15%]">
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                  </div>
              </div>
              <div className="w-1/6 flex relative items-center">
                  <img src="/img/funnel-2.svg" className="w-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] drop-shadow-[0_4px_8px_rgba(0,0,0,0.05)]" />
                  <div className="absolute space-y-3 text-sm text-green5 left-[15%]">
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                  </div>
              </div>
              <div className="w-1/6 flex relative items-center">
                  <img src="/img/funnel-3.svg" className="w-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] drop-shadow-[0_4px_8px_rgba(0,0,0,0.05)]" />
                  <div className="absolute space-y-3 text-sm text-green5 left-[15%]">
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                  </div>
              </div>
              <div className="w-1/6 flex relative items-center">
                  <img src="/img/funnel-4.svg" className="w-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] drop-shadow-[0_4px_8px_rgba(0,0,0,0.05)]" />
                  <div className="absolute space-y-3 text-sm text-green5 left-[15%]">
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                  </div>
              </div>
              <div className="w-1/6 flex relative items-center">
                  <img src="/img/funnel-5.svg" className="w-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] drop-shadow-[0_4px_8px_rgba(0,0,0,0.05)]" />
                  <div className="absolute space-y-3 text-sm text-green5 left-[15%]">
                      <div>ID: 1098771</div>
                      <div>ID: 1098771</div>
                  </div>
              </div>
              <div className="w-1/6 flex relative items-center">
                  <img src="/img/funnel-6.svg" className="w-full drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] drop-shadow-[0_4px_8px_rgba(0,0,0,0.05)]" />
                  <div className="absolute space-y-3 text-sm text-green5 left-[15%]">
                      <div>ID: 1098771</div>
                  </div>
              </div>
          </div>
      </div>
  );
};

export default TrapezoidCanvas;