import React, { ChangeEventHandler, useState, useEffect } from "react";

type ImageInputProps = {
  id?: string | undefined;
  name?: string;
  multiple?: boolean;
  accept?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  onChange?: (files: File[]) => void;
  resetKey?: string;
};

export const ImageInput = ({
  id,
  name,
  multiple = false,
  accept,
  placeholder,
  value,
  className,
  onChange,
  resetKey,
}: React.PropsWithChildren<ImageInputProps>) => {
  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedFiles = e.target.files;

    if (!selectedFiles) {
      return;
    }

    const filesArray = Array.from(selectedFiles);
    setFiles(filesArray);
    onChange?.(filesArray);

    const previewsArray = Array.from(selectedFiles).map((file) =>
      URL.createObjectURL(file)
    );
    setPreviews(previewsArray);
  };

  useEffect(() => {
    setFiles([]);
    setPreviews([]);
  }, [resetKey]);

  useEffect(() => {
    return () => {
      previews.forEach((preview) => URL.revokeObjectURL(preview));
    };
  }, [previews]);

  return (
    <label htmlFor={id} className={`w-full block min-h-20 border rounded-lg text-sm text-gray3 cursor-pointer ${className}`}>
      <div className={`flex h-full p-5 ${files.length ? '' : ''}`}>{placeholder}</div>
      <input
        id={id}
        name={name}
        type="file"
        multiple={multiple}
        accept={accept}
        className="hidden"
        onChange={handleFileChange}
      />
      <div className="flex h-full p-5 flex-wrap gap-2">
        {previews.map((preview, index) => (
          <img
            key={index}
            src={preview}
            alt={`Preview ${index}`}
            className="max-h-20 max-w-20 object-contain"
          />
        ))}
      </div>
    </label>
  );
};
