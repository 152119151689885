import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Request = () => {

  const [selectedOption, setSelectedOption] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setErrorMessage(false);
    
    switch (selectedOption) {
      case 'request':
        navigate('/new-request');
        break;
      case 'incident':
        console.log('incident');
        break;
      default:
        console.log("Error = true");
        setErrorMessage(true);
        break;
    }
  };

  const selectOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(e.target.value);
    setErrorMessage(false);
  };

  return (
	  <div className="px-9 pt-6 pb-10 w-full flex flex-1">

      <form className="w-full flex" onSubmit={handleSubmit}>
        <div className="w-1/2 flex justify-center items-center">
           <div className="text-center">
               <h1 className="text-main font-semibold text-2xl">Get Started Today</h1>
               <div className="text-gray3 text-sm mt-2">How would you like to continue</div>

               <div className="my-12 space-y-4">
                  <label className={`p-3 w-[360px] border flex rounded-lg cursor-pointer justify-between items-center bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)] has-[:checked]:border-main ${errorMessage ? 'border-ceRed' : 'border-gray4'}`}>
                      <div className="w-12 h-12 min-w-12 border border-gray4 rounded-lg mr-3 justify-center flex items-center">
                        <img src="/img/icons/new-request.svg" className="w-6 h-6" />
                      </div>
                      <div className="text-left w-full space-y-1">
                        <div className="text-xs font-semibold text-main">New Request</div>
                        <div className="text-xs text-gray3">Short description of this section</div>
                      </div>
                      <input type="radio" name="request-type" value="request" checked={selectedOption === 'request'} onChange={selectOption} className={`box-content h-3.5 w-3.5 min-w-3.5 min-h-3.5 appearance-none rounded-full border-[1px] border-white outline-none ring-1 checked:bg-main checked:ring-main ${errorMessage ? 'ring-ceRed' : 'ring-gray4'}`} />
                  </label>

                  <label className={`p-3 w-[360px] border flex rounded-lg cursor-pointer justify-between items-center bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)] has-[:checked]:border-main ${errorMessage ? 'border-ceRed' : 'border-gray4'}`}>
                      <div className="w-12 h-12 min-w-12 border border-gray4 rounded-lg mr-3 justify-center flex items-center">
                        <img src="/img/icons/new-incident.svg" className="w-6 h-6" />
                      </div>
                      <div className="text-left w-full space-y-1">
                        <div className="text-xs font-semibold text-main">New Incident</div>
                        <div className="text-xs text-gray3">Short description of this section</div>
                      </div>
                      <input type="radio" name="request-type" value="incident" checked={selectedOption === 'incident'} onChange={selectOption} className={`box-content h-3.5 w-3.5 min-w-3.5 min-h-3.5 appearance-none rounded-full border-[1px] border-white outline-none ring-1 checked:bg-main checked:ring-main ${errorMessage ? 'ring-ceRed' : 'ring-gray4'}`} />
                  </label>
               </div>

               <button type="submit" className="bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">Continue</button>
           </div>
        </div>

        <div className="w-1/2 h-auto overflow-hidden rounded relative">
          <img src="/img/request.jpg" className="absolute inset-0 w-full h-full object-cover" />
        </div>
      </form>
		
    </div>
  );
};

export default Request;