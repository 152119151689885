import React from 'react';

const ProjectOverview = () => (

	<div>
		<h2 className="uppercase text-main font-bold text-lg tracking-[0.05em] mb-4 mt-6">Property Information</h2>

		<div className="flex w-full p-6 border-gray5 border rounded-lg space-x-6 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">

			<div className="w-1/2 space-y-4">

				<div className="flex place-items-center">
					<div className="w-16 h-16 mr-4 rounded-full border-4 border-white drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]">
						<img src="/img/avatar.png" className="w-full h-full" />
					</div>
					<div>
						<div className="text-main font-semibold text-lg">Jonathan Kelly</div>
						<div className="text-gray3 font-normal text-base">Owner</div>
					</div>
				</div>

				<div className="flex flex-wrap justify-between gap-4 min-[1400px]:grid min-[1400px]:grid-cols-12">
					<div className="space-y-2 min-[1400px]:col-span-3">
  						<div className="text-gray3 font-normal text-sm">Property ID</div>
  						<div className="text-main font-medium text-base">498496</div>
					</div>
					<div className="space-y-2 min-[1400px]:col-span-5">
  						<div className="text-gray3 font-normal text-sm">Category</div>
  						<div className="text-main font-medium text-base">Mehfamilienhauis</div>
					</div>
		            <div className="space-y-2 min-w-[180px] min-[1400px]:col-span-4">
						<div className="text-gray3 font-normal text-sm">Address</div>
						<div className="text-main font-medium text-base">🇨🇭 Switzerland, Zug, Finanzverkehr Interswiss</div>
		            </div>
				</div>

			</div>

			{/* Google Maps */}
			<div className="w-1/2 rounded-lg max-h-[245px] overflow-hidden">
				<img src="/img/map.jpg" className="w-full" />
			</div>

		</div>

		<h2 className="uppercase text-main font-bold text-lg tracking-[0.05em] mb-4 mt-6">Project Details</h2>

		<div className="flex space-x-6 mb-6 bg-white">
			<div className="p-4 border-gray5 border rounded-lg w-1/4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="uppercase text-xs text-main font-medium tracking-[0.05em] mb-3">Completion</div>
					<div className="text-xs text-gray2 font-normal mb-[1.125rem] flex">
					<div className="w-2 h-2 bg-blue1 rounded-full m-1"></div> Status: In Progress
				</div>
				<div className="flex text-main font-semibold text-2xl place-items-center">
					85%
					<div className="ml-3 w-full h-2 bg-gray6 rounded">
						<div className="w-[85%] h-2 bg-brandGreen rounded"></div>
					</div>
				</div>
			</div>

			<div className="p-4 border-gray5 border rounded-lg w-1/4 space-y-2 bg-white flex flex-col hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="uppercase text-xs text-main font-medium tracking-[0.05em]">Status</div>
				<div className="w-full text-xs text-gray2 font-normal flex justify-between max-w-[224px] place-self-center gap-x-px">
					<div className="w-8 h-8 bg-ceRed opacity-30 rounded-full border-b-2 border-b-[#AD3838]"></div>
					<div className="w-8 h-8 bg-ceOrange opacity-30 rounded-full border-b-2 border-b-[#B36D2F]"></div>
					<div className="w-8 h-8 bg-ceYellow opacity-30 rounded-full border-b-2 border-b-[#C8A63E]"></div>
					<div className="w-8 h-8 bg-green2 rounded-full border-b-2 border-b-[#0C803D]"></div>
					<div className="w-8 h-8 bg-green1 opacity-30 rounded-full border-b-2 border-b-[#04682F]"></div>
				</div>
				<div className="w-full text-main font-semibold text-2xl text-center">Status</div>
			</div>

			<div className="p-4 border-gray5 border rounded-lg w-1/4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="uppercase text-xs text-main font-medium tracking-[0.05em] mb-3">Budget</div>
				<div className="text-xs text-gray2 font-normal mb-[1.125rem] flex">
					<div className="w-2 h-2 bg-green1 rounded-full m-1"></div> Status: Approved
				</div>
				<div className="text-main font-semibold text-2xl">
					10.000 CHF
				</div>
			</div>

			<div className="p-4 border-gray5 border rounded-lg w-1/4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="uppercase text-xs text-main font-medium tracking-[0.05em] mb-3">contractor</div>
				<div className="text-xs text-gray2 font-normal mb-[1.125rem] flex">
					Person: Jonathan Kelly
				</div>
				<div className="flex text-main font-semibold text-2xl place-items-top">
					<img src="/img/company-logo.png" className="w-4.5 max-h-6 mr-2 mt-1 object-contain" />
					Company Name
				</div>
			</div>
		</div>

		<div className="space-x-6 flex">

			<div className="w-1/2 space-y-6">
				<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<h3 className="font-semibold text-main text-lg">Request Information</h3>

					<div className="grid grid-cols-12 gap-4">
						<div className="space-y-2 col-span-8 min-[1480px]:col-span-3">
  						<div className="text-gray3 font-normal text-sm">Requestor</div>
  						<div className="text-main font-medium text-base">Drew Cano</div>
						</div>
						<div className="space-y-2 min-[1480px]:col-span-3">
  						<div className="text-gray3 font-normal text-sm">Requested</div>
  						<div className="text-main font-medium text-base">14/03/2024</div>
						</div>
						<div className="space-y-2 col-span-8 min-[1480px]:col-span-3">
  						<div className="text-gray3 font-normal text-sm">Project Initiation</div>
  						<div className="text-main font-medium text-base">17/06/2024</div>
						</div>
						<div className="space-y-2 min-[1480px]:col-span-3">
  						<div className="text-gray3 font-normal text-sm">Deadline</div>
  						<div className="text-main font-medium text-base">31/01/2025</div>
						</div>
					</div>
				</div>

				<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<h3 className="font-semibold text-main text-lg">Project Log</h3>

					<table className="w-full text-xs">
						<thead>
						<tr className="text-gray3 border-b border-b-gray3 text-left">
					    	<th className="font-normal pb-4">No.</th>
					    	<th className="font-normal pb-4">Name</th>
					    	<th className="font-normal pb-4">Action</th>
					    	<th className="font-normal pb-4">Date</th>
					    	<th className="font-normal pb-4">Time</th>
						</tr>
					</thead>
						<tbody>
						<tr className="text-main border-b border-b-gray4">
					    	<td className="py-4">1.</td>
					    	<td className="flex py-4">
					    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
					    		Ammar Foley
					    	</td>
					    	<td>Action Name</td>
					    	<td>08/05/2024</td>
					    	<td>9:00</td>
						</tr>
						<tr className="text-main border-b border-b-gray4">
					    	<td className="py-4">2.</td>
					    	<td className="flex py-4">
					    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
					    		Ammar Foley
					    	</td>
					    	<td>Action Name</td>
					    	<td>01/05/2024</td>
					    	<td>10:40</td>
						</tr>
						<tr className="text-main border-b border-b-gray4">
					    	<td className="py-4">3.</td>
					    	<td className="flex py-4">
					    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
					    		Ammar Foley
					    	</td>
					    	<td>Action Name</td>
					    	<td>01/05/2024</td>
					    	<td>10:40</td>
						</tr>
						<tr className="text-main">
					    	<td className="py-4">4.</td>
					    	<td className="flex py-4">
					    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
					    		Ammar Foley
					    	</td>
					    	<td>Action Name</td>
					    	<td>01/05/2024</td>
					    	<td>10:40</td>
						</tr>
					</tbody>
					</table>
				</div>
			</div>

			<div className="w-1/2 p-4 border-gray5 border rounded-lg bg-white mb-5 space-y-4 hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="flex space-x-4 items-stretch">
					<h3 className="font-semibold text-main text-lg inline-block mr-auto">Tasks</h3>
					<div className="bg-gray6 p-1 rounded text-[11px] border border-gray4">
						<a href="#" className="rounded py-0.5 px-2 min-w-[2.375rem] text-main text-center font-semibold bg-white drop-shadow-[0_2px_2px_rgba(0,0,0,0.07)] leading-5 inline-block">All</a>
						<a href="#" className="py-0.5 px-2 min-w-[2.375rem] text-gray3 text-center font-medium drop-shadow-[0_2px_2px_rgba(0,0,0,0)] leading-5 inline-block">Due</a>
						<a href="#" className="py-0.5 px-2 min-w-[2.375rem] text-gray3 text-center font-medium drop-shadow-[0_2px_2px_rgba(0,0,0,0)] leading-5 inline-block">Upcoming</a>
						<a href="#" className="py-0.5 px-2 min-w-[2.375rem] text-gray3 text-center font-medium drop-shadow-[0_2px_2px_rgba(0,0,0,0)] leading-5 inline-block">Overdue</a>
					</div>
					<button className="bg-brandGreen px-2 py-1.5 text-[11px] font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Task</button>
				</div>

				<div className="w-full grid grid-cols-2 gap-y-4 gap-x-6">
  					<div className="p-4 w-full border-gray5 border rounded-md space-y-5 col-span-2 bg-white hover:border-brandGreen hover:border-2">
  						<div className="flex flex-wrap justify-between gap-2">
  							<div className="px-2 py-1 border border-gray5 rounded text-xs text-gray3 flex">
  								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
  								<div className="inline-block max-[1280px]:w-8 overflow-hidden">Deadline</div>: 05/05/2024
  							</div>
               				<div className="w-16 flex">
  							  	<div className="px-2 py-1 bg-gray8 text-xs text-ceRed rounded inline-block min-[1553px]:ml-auto">Overdue</div>
                			</div>
  						</div>
  						<div className="w-full">
  							<div className="w-full justify-between flex mb-2">
  								<div className="text-main font-medium text-sm">Task Description</div>
  								<div className="text-main font-medium text-sm">85%</div>
  							</div>
  							<div className="w-full h-2 bg-gray6 rounded">
	  							<div className="w-[85%] h-full bg-brandGreen rounded"></div>
	  						</div>
  						</div>
  						<div className="w-full justify-between flex place-items-center">
  							<div className="flex">
  								<div className="border-[3px] border-white rounded-full w-6 h-6 z-10"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-0"><img src="/img/avatar.png" className="w-full h-full" /></div>
  							</div>
  							<div className="text-gray3 text-xs">In work 24 days</div>
  						</div>
  					</div>

  					<div className="p-4 w-full border-gray5 border rounded-md space-y-5 bg-white hover:border-brandGreen hover:border-2">
  						<div className="flex flex-wrap justify-between gap-2">
  							<div className="px-2 py-1 border border-gray5 rounded text-xs text-gray3 flex max-[1553px]:mr-auto">
  								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
  								<div className="inline-block max-[1280px]:w-8 overflow-hidden">Deadline</div>: 11/11/2024
  							</div>
                			<div className="w-16 flex">
	  							<div className="px-2 py-1 bg-gray8 text-xs text-ceRed rounded inline-block min-[1553px]:ml-auto">Overdue</div>
	  						</div>
              			</div>
  						<div className="w-full">
  							<div className="w-full justify-between flex mb-2">
  								<div className="text-main font-medium text-sm">Task Description</div>
  								<div className="text-main font-medium text-sm">85%</div>
  							</div>
  							<div className="w-full h-2 bg-gray6 rounded">
	  							<div className="w-[85%] h-full bg-brandGreen rounded"></div>
	  						</div>
  						</div>
  						<div className="w-full justify-between flex place-items-center">
  							<div className="flex">
  								<div className="border-[3px] border-white rounded-full w-6 h-6 z-10"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-0"><img src="/img/avatar.png" className="w-full h-full" /></div>
  							</div>
  							<div className="text-gray3 text-xs">In work 24 days</div>
  						</div>
  					</div>

  					<div className="p-4 w-full border-gray5 border rounded-md space-y-5 bg-white hover:border-brandGreen hover:border-2">
  						<div className="flex flex-wrap justify-between gap-2">
  							<div className="px-2 py-1 border border-gray5 rounded text-xs text-gray3 flex">
  								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
  								<div className="inline-block max-[1280px]:w-8 overflow-hidden">Deadline</div>: 05/05/2024
  							</div>
                			<div className="w-16 flex">
	  							<div className="px-2 py-1 bg-green4 text-xs text-green1 rounded inline-block min-[1553px]:ml-auto">Due</div>
	  						</div>
              			</div>
  						<div className="w-full">
  							<div className="w-full justify-between flex mb-2">
  								<div className="text-main font-medium text-sm">Task Description</div>
  								<div className="text-main font-medium text-sm">85%</div>
  							</div>
  							<div className="w-full h-2 bg-gray6 rounded">
	  							<div className="w-[85%] h-full bg-brandGreen rounded"></div>
	  						</div>
  						</div>
  						<div className="w-full justify-between flex place-items-center">
  							<div className="flex">
  								<div className="border-[3px] border-white rounded-full w-6 h-6 z-20"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-10"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-0"><img src="/img/avatar.png" className="w-full h-full" /></div>
  							</div>
  							<div className="text-gray3 text-xs">In work 24 days</div>
  						</div>
  					</div>

  					<div className="p-4 w-full border-gray5 border rounded-md space-y-5 bg-white hover:border-brandGreen hover:border-2">
  						<div className="flex flex-wrap justify-between gap-2">
  							<div className="px-2 py-1 border border-gray5 rounded text-xs text-gray3 flex">
  								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
  								<div className="inline-block max-[1280px]:w-8 overflow-hidden">Deadline</div>: 05/05/2024
  							</div>
                			<div className="w-16 flex">
	  							<div className="px-2 py-1 bg-green4 text-xs text-green1 rounded inline-block min-[1553px]:ml-auto">Due</div>
	  						</div>
              			</div>
  						<div className="w-full">
  							<div className="w-full justify-between flex mb-2">
  								<div className="text-main font-medium text-sm">Task Description</div>
  								<div className="text-main font-medium text-sm">85%</div>
  							</div>
  							<div className="w-full h-2 bg-gray6 rounded">
	  							<div className="w-[85%] h-full bg-brandGreen rounded"></div>
	  						</div>
  						</div>
  						<div className="w-full justify-between flex place-items-center">
  							<div className="flex">
  								<div className="border-[3px] border-white rounded-full w-6 h-6 z-20"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-10"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-0"><img src="/img/avatar.png" className="w-full h-full" /></div>
  							</div>
  							<div className="text-gray3 text-xs">In work 24 days</div>
  						</div>
  					</div>

  					<div className="p-4 w-full border-gray5 border rounded-md space-y-5 bg-white hover:border-brandGreen hover:border-2">
  						<div className="flex flex-wrap justify-between gap-2">
  							<div className="px-2 py-1 border border-gray5 rounded text-xs text-gray3 flex">
  								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
  								<div className="inline-block max-[1280px]:w-8 overflow-hidden">Deadline</div>: 05/05/2024
  							</div>
                			<div className="w-16 flex">
	  							<div className="px-2 py-1 bg-gray8 text-xs text-ceRed rounded inline-block min-[1553px]:ml-auto">Overdue</div>
	  						</div>
              			</div>
  						<div className="w-full">
  							<div className="w-full justify-between flex mb-2">
  								<div className="text-main font-medium text-sm">Task Description</div>
  								<div className="text-main font-medium text-sm">85%</div>
  							</div>
  							<div className="w-full h-2 bg-gray6 rounded">
	  							<div className="w-[85%] h-full bg-brandGreen rounded"></div>
	  						</div>
  						</div>
  						<div className="w-full justify-between flex place-items-center">
  							<div className="flex">
  								<div className="border-[3px] border-white rounded-full w-6 h-6 z-10"><img src="/img/avatar.png" className="w-full h-full" /></div>
  								<div className="border-[3px] border-white rounded-full w-6 h-6 -ml-2 z-0"><img src="/img/avatar.png" className="w-full h-full" /></div>
  							</div>
  							<div className="text-gray3 text-xs">In work 24 days</div>
  						</div>
  					</div>
				</div>
			</div>

		</div>

	</div>
);

export default ProjectOverview;