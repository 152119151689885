import React, { useState, useEffect } from 'react';

import { Tab } from '../components/ui/Tab';

import ProjectOverview from './Project/Overview';
import ProjectProperty from './Project/Property';
import ProjectContractors from './Project/Contractors';
import ProjectPlans from './Project/Plans';
import ProjectDocuments from './Project/Documents';
import ProjectLog from './Project/Log';

type TabType = 'overview' | 'property' | 'cost' | 'contractors' | 'plans' | 'docs' | 'comm' | 'analysis' | 'log';

const getTabFromHash = () => {
	const hash = window.location.hash.replace('#', '');
	return hash as TabType || 'overview';
};

const Project = () => {
	const [activeTab, setActiveTab] = useState<TabType>(getTabFromHash());
	
	const handleTabChange = (tab: string) => {
	    setActiveTab(tab as TabType);
	    window.location.hash = tab;
	};

	useEffect(() => {
	  	const handleHashChange = () => {
	    	setActiveTab(getTabFromHash());
	  	};

	  	window.addEventListener('hashchange', handleHashChange);
	  	return () => {
	    	window.removeEventListener('hashchange', handleHashChange);
	  	};
	}, []);


	return (
		<div className="px-8 py-3 w-full h-full m-auto max-w-[1330px]">

			{/* Search */}
			<div className="w-full flex mb-6">
				<div className="w-full relative">
					<div className="absolute top-3 left-5 w-[1.375rem] h-[1.375rem]">
						<svg className="w-full h-full" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.625 17.75C14.56 17.75 17.75 14.56 17.75 10.625C17.75 6.68997 14.56 3.5 10.625 3.5C6.68997 3.5 3.5 6.68997 3.5 10.625C3.5 14.56 6.68997 17.75 10.625 17.75Z" stroke="#888A8D" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18.5 18.5L17 17" stroke="#888A8D" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					</div>
					<input type="text" placeholder="Search" className="w-full h-[2.875rem] pt-1 text-xs text-gray3 outline-none bg-white rounded-[2.5rem] border-b-4 border-b-brandGreen pl-[3.125rem]" />
				</div>

				<a href="#" className="ml-4">
	  			<div className="w-[2.875rem] h-[2.875rem] bg-white border border-gray5 rounded-full flex items-center justify-center hover:border-brandGreen">
	  				<svg className="w-[1.375rem] h-[1.375rem] mx-auto" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M7.85 17.2542H7.4C3.8 17.2542 2 16.3569 2 11.8704V7.38385C2 3.79462 3.8 2 7.4 2H14.6C18.2 2 20 3.79462 20 7.38385V11.8704C20 15.4596 18.2 17.2542 14.6 17.2542H14.15C13.871 17.2542 13.601 17.3888 13.43 17.6132L12.08 19.4078C11.486 20.1974 10.514 20.1974 9.92 19.4078L8.57 17.6132C8.426 17.4158 8.093 17.2542 7.85 17.2542Z" stroke="#54575B" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M14.9965 10H15.0054" strokeWidth="2" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M10.9955 10H11.0045" strokeWidth="2" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M6.99451 10H7.00349" strokeWidth="2" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
	  			</div>
	  		</a>

				<a href="#" className="ml-4">
	  			<div className="w-[2.875rem] h-[2.875rem] bg-white border border-gray5 rounded-full flex items-center justify-center hover:border-brandGreen">
	  				<svg className="w-[1.375rem] h-[1.375rem] mx-auto" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.0165 3.42578C8.25816 3.42578 6.0165 5.66745 6.0165 8.42578V10.8341C6.0165 11.3424 5.79983 12.1174 5.5415 12.5508L4.58316 14.1424C3.9915 15.1258 4.39983 16.2174 5.48316 16.5841C9.07483 17.7841 12.9498 17.7841 16.5415 16.5841C17.5498 16.2508 17.9915 15.0591 17.4415 14.1424L16.4832 12.5508C16.2332 12.1174 16.0165 11.3424 16.0165 10.8341" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M12.5584 3.66719C12.3001 3.59219 12.0334 3.53385 11.7584 3.50052C10.9584 3.40052 10.1918 3.45885 9.4751 3.66719C9.71676 3.05052 10.3168 2.61719 11.0168 2.61719C11.7168 2.61719 12.3168 3.05052 12.5584 3.66719Z" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M13.5166 17.8828C13.5166 19.2578 12.3916 20.3828 11.0166 20.3828C10.3333 20.3828 9.69993 20.0995 9.24993 19.6495C8.79993 19.1995 8.5166 18.5661 8.5166 17.8828" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<circle cx="17" cy="6" r="3" fill="#F14141"/>
					</svg>
	  			</div>
	  		</a>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Project name</h1>
				<div className="text-white text-xs px-2 py-1 mx-2 bg-main inline-block rounded-[2.5rem]">ID: 1564384</div>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<a href="#" className="underline hover:no-underline">Project</a> / <a href="#" className="underline hover:no-underline">E-Mobility</a> / <span className="text-main">MFH Hohlstrasse 52, 8008 Nurnberg</span>
			</div>

			{/* Tabs */}
			<div className="mt-5 w-full border-b-gray5 border-b text-sm text-gray3">
				<div className="inline-block">
					<Tab name="overview" activeTab={activeTab} onClick={handleTabChange}>Overview</Tab>
				</div>
				<div className="inline-block">
					<Tab name="property" activeTab={activeTab} onClick={handleTabChange}>Property</Tab>
				</div>
				<div className="inline-block">
					<Tab name="cost" activeTab={activeTab} onClick={handleTabChange}>Cost</Tab>
				</div>
				<div className="inline-block">
					<Tab name="contractors" activeTab={activeTab} onClick={handleTabChange}>Contractors</Tab>
				</div>
				<div className="inline-block">
					<Tab name="plans" activeTab={activeTab} onClick={handleTabChange}>Plans</Tab>
				</div>
				<div className="inline-block">
					<div 
						className={`py-3 px-4 ${ (activeTab === 'docs') ? 'font-semibold text-main border-b-2 border-b-main' : 'cursor-pointer hover:text-main'}`}
						onClick={() => handleTabChange('docs')}
					>
						<span className="hidden min-[1281px]:inline-block">Documents</span>
		        		<span className="hidden max-[1280px]:inline-block">Doc.</span>
					</div>
				</div>
				<div className="inline-block">
					<div 
						className={`py-3 px-4 ${ (activeTab === 'comm') ? 'font-semibold text-main border-b-2 border-b-main' : 'cursor-pointer hover:text-main'}`}
						onClick={() => handleTabChange('comm')}
					>
				        <span className="hidden min-[1281px]:inline-block">Communication</span>
				        <span className="hidden max-[1280px]:inline-block">Comm.</span>  				
					</div>
				</div>
				<div className="inline-block">
					<Tab name="analysis" activeTab={activeTab} onClick={handleTabChange}>Analysis</Tab>
				</div>
				<div className="inline-block">
					<Tab name="log" activeTab={activeTab} onClick={handleTabChange}>Progress Log</Tab>
				</div>
			</div>

			{/* Tab Content */}
			<div className={`${ activeTab !== 'overview' ? 'hidden' : '' }`}>
				<ProjectOverview />
			</div>
			<div className={`${ activeTab !== 'property' ? 'hidden' : '' }`}>
				<ProjectProperty />
			</div>
			<div className={`${ activeTab !== 'contractors' ? 'hidden' : '' }`}>
				<ProjectContractors />
			</div>
			<div className={`${ activeTab !== 'plans' ? 'hidden' : '' }`}>
				<ProjectPlans />
			</div>
			<div className={`${ activeTab !== 'docs' ? 'hidden' : '' }`}>
				<ProjectDocuments />
			</div>
			<div className={`${ activeTab !== 'log' ? 'hidden' : '' }`}>
				<ProjectLog />
			</div>
			

		</div>
	)
};

export default Project;