import React, { useState, useEffect } from 'react';
import TaskBlock from "../components/TaskBlock";
import TrapezoidCanvas from "../components/TrapezoidCanvas";
import ActionList from "../components/ActionList";

const Dashboard = () => {

	const taskTables = [
		{ title: 'Overdue Tasks', id: 'overdue' },
		{ title: 'Upcoming Tasks', id: 'upcoming' },
	];

	return (
		<div className="px-8 py-3 w-full h-auto m-auto max-w-[1330px]">

			{/* Search */}
			<div className="w-full flex mb-6">
				<div className="w-full relative">
					<div className="absolute top-3 left-5 w-[1.375rem] h-[1.375rem]">
						<svg className="w-full h-full" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.625 17.75C14.56 17.75 17.75 14.56 17.75 10.625C17.75 6.68997 14.56 3.5 10.625 3.5C6.68997 3.5 3.5 6.68997 3.5 10.625C3.5 14.56 6.68997 17.75 10.625 17.75Z" stroke="#888A8D" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M18.5 18.5L17 17" stroke="#888A8D" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					</div>
					<input type="text" placeholder="Search" className="w-full h-[2.875rem] pt-1 text-xs text-gray3 outline-none bg-white rounded-[2.5rem] border-b-4 border-b-brandGreen pl-[3.125rem]" />
				</div>

				<a href="#" className="ml-4">
		  			<div className="w-[2.875rem] h-[2.875rem] bg-white border border-gray5 rounded-full flex items-center justify-center hover:border-brandGreen">
		  				<svg className="w-[1.375rem] h-[1.375rem] mx-auto" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M7.85 17.2542H7.4C3.8 17.2542 2 16.3569 2 11.8704V7.38385C2 3.79462 3.8 2 7.4 2H14.6C18.2 2 20 3.79462 20 7.38385V11.8704C20 15.4596 18.2 17.2542 14.6 17.2542H14.15C13.871 17.2542 13.601 17.3888 13.43 17.6132L12.08 19.4078C11.486 20.1974 10.514 20.1974 9.92 19.4078L8.57 17.6132C8.426 17.4158 8.093 17.2542 7.85 17.2542Z" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M14.9965 10H15.0054" className="stroke-2" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M10.9955 10H11.0045" className="stroke-2" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
							<path d="M6.99451 10H7.00349" className="stroke-2" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>
		  			</div>
		  		</a>

				<a href="#" className="ml-4">
	  			<div className="w-[2.875rem] h-[2.875rem] bg-white border border-gray5 rounded-full flex items-center justify-center hover:border-brandGreen">
	  				<svg className="w-[1.375rem] h-[1.375rem] mx-auto" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M11.0165 3.42578C8.25816 3.42578 6.0165 5.66745 6.0165 8.42578V10.8341C6.0165 11.3424 5.79983 12.1174 5.5415 12.5508L4.58316 14.1424C3.9915 15.1258 4.39983 16.2174 5.48316 16.5841C9.07483 17.7841 12.9498 17.7841 16.5415 16.5841C17.5498 16.2508 17.9915 15.0591 17.4415 14.1424L16.4832 12.5508C16.2332 12.1174 16.0165 11.3424 16.0165 10.8341" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M12.5584 3.66719C12.3001 3.59219 12.0334 3.53385 11.7584 3.50052C10.9584 3.40052 10.1918 3.45885 9.4751 3.66719C9.71676 3.05052 10.3168 2.61719 11.0168 2.61719C11.7168 2.61719 12.3168 3.05052 12.5584 3.66719Z" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<path d="M13.5166 17.8828C13.5166 19.2578 12.3916 20.3828 11.0166 20.3828C10.3333 20.3828 9.69993 20.0995 9.24993 19.6495C8.79993 19.1995 8.5166 18.5661 8.5166 17.8828" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
						<circle cx="17" cy="6" r="3" fill="#F14141"/>
					</svg>
	  			</div>
	  		</a>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Dashboard</h1>
			</div>

			{/* Content */}
			<div className="w-full mt-6 grid grid-cols-3 gap-6 font-medium text-main">
				<div className="bg-white border border-gray5 p-4 rounded-md hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<div className="uppercase text-xs tracking-[0.05em]">Incidents</div>
					<div className="text-[2rem] leading-normal mt-2">7</div>
				</div>
				<div className="bg-white border border-gray5 p-4 rounded-md hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<div className="uppercase text-xs tracking-[0.05em]">Requests</div>
					<div className="text-[2rem] leading-normal mt-2">2</div>
				</div>
				<div className="bg-white border border-gray5 p-4 rounded-md hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<div className="uppercase text-xs tracking-[0.05em]">Projects</div>
					<div className="text-[2rem] leading-normal mt-2">9</div>
				</div>
				<div className="bg-white border border-gray5 p-4 rounded-md hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<div className="uppercase text-xs tracking-[0.05em]">Overdue tasks</div>
					<div className="text-[2rem] leading-normal mt-2">3</div>
				</div>
				<div className="bg-white border border-gray5 p-4 rounded-md hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<div className="uppercase text-xs tracking-[0.05em]">Open tasks</div>
					<div className="text-[2rem] leading-normal mt-2">4</div>
				</div>
				<div className="bg-white border border-gray5 p-4 rounded-md hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
					<div className="uppercase text-xs tracking-[0.05em]">Commissionings</div>
					<div className="text-[2rem] leading-normal mt-2">6</div>
				</div>
			</div>

			<div className="p-6 border-gray5 border rounded-lg w-full mt-6 bg-gradient-to-br from-[#FBFEF7] to-[#E7F4D1] backdrop-filter backdrop-blur-[120px] hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="flex space-x-4 items-stretch">
					<h3 className="font-semibold text-main text-lg inline-block mr-auto">Project Funnel</h3>
					<button className="bg-white border border-gray4 px-3 py-1.5 text-sm font-medium rounded drop-shadow-[0_1px_1px_rgba(0,0,0,0.05)] flex place-items-center hover:border-brandGreen">
						<img src="/img/icons/filters.svg" className="mr-1" />
						Filters
					</button>
				</div>

				<TrapezoidCanvas />
			</div>

			<div className="p-6 border-gray5 border rounded-lg bg-white space-y-8 w-full mt-6 hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="flex space-x-4 items-stretch">
					<h3 className="font-semibold text-main text-lg inline-block mr-auto">Tasks</h3>
					<button className="bg-white border border-gray4 px-2 py-1.5 text-[11px] font-semibold rounded leading-4 drop-shadow-[0_1px_1px_rgba(0,0,0,0.05)] flex place-items-center hover:border-brandGreen">
						<img src="/img/icons/filters.svg" className="mr-1" />
						Filters
					</button>
					<button className="bg-brandGreen px-2.5 py-1.5 text-[11px] font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Task</button>
				</div>

				<TaskBlock tables={taskTables} />

			</div>

			<div className="p-6 border-gray5 border rounded-lg bg-white w-full mt-6 space-y-4 hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="flex space-x-4 items-stretch">
					<h3 className="font-semibold text-main text-lg inline-block mr-auto">Projects</h3>
				</div>

				<table className="w-full text-xs">
				<thead>
					<tr className="text-gray3 border-b border-b-gray3 text-left">
				    	<th className="font-normal pb-4">No.</th>
				    	<th className="font-normal pb-4">Project Name</th>
				    	<th className="font-normal pb-4">Project ID</th>
				    	<th className="font-normal pb-4">Category</th>
				    	<th className="font-normal pb-4 w-1/6">Timeline, %</th>
				    	<th className="font-normal pb-4">Status</th>
				    	<th className="font-normal pb-4">Open Tasks</th>
				    	<th className="font-normal pb-4">Days Open</th>
				    	<th className="font-normal pb-4">Users</th>
					</tr>
				</thead>
				<tbody>
					<tr className="text-main">
				    	<td className="py-4">1.</td>
				    	<td className="font-bold">Project Name</td>
				    	<td>ID: 109875</td>
				    	<td>E-Mobility</td>
				    	<td className="pr-5">
				    		<div className="flex place-items-center">
					    		<div className="w-full h-2 bg-gray6 rounded mr-2">
	  								<div className="w-[50%] h-full bg-brandGreen rounded"></div>
	  							</div>
	  							50%
  							</div>
				    	</td>
				    	<td>
				    		<div className="px-2 py-1 bg-brandGreen text-xs text-main rounded inline-block">Active</div>
				    	</td>
				    	<td>5 Tasks</td>
				    	<td>21 Days</td>
				    	<td className="flex py-4">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white z-20" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5 z-10" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5" />
				    	</td>
					</tr>
					<tr className="border-b border-b-gray4">
						<td></td>
						<td colSpan={8} className="pb-4 w-full">
							<div className="flex items-center">
								<div className="whitespace-nowrap">Property Information:</div>
								<div className="bg-gray6 rounded-[32px] py-2 px-5 ml-3 flex w-full justify-between">
									<div className="px-3">Property Name</div>
									<div className="px-3 flex">
										<img src="/img/icons/flags/switzerland.png" className="w-4 max-h-3 object-contain" />
										8005 Zurich, Limmatstrasse 119
									</div>
									<div className="px-3 flex">
										<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
				    					Ammar Foley
									</div>
									<div className="px-3 flex whitespace-nowrap">
										<img src="/img/company-logo.png" className="w-4 max-h-6 mr-2 object-contain" />
										Company Name
									</div>
								</div>
							</div>
						</td>
					</tr>
					<tr className="text-main">
				    	<td className="py-4">2.</td>
				    	<td className="font-bold">Project Name</td>
				    	<td>ID: 109875</td>
				    	<td>E-Mobility</td>
				    	<td className="pr-5">
				    		<div className="flex place-items-center">
					    		<div className="w-full h-2 bg-gray6 rounded mr-2">
	  								<div className="w-[77%] h-full bg-brandGreen rounded"></div>
	  							</div>
	  							77%
  							</div>
				    	</td>
				    	<td>
				    		<div className="px-2 py-1 bg-brandGreen text-xs text-main rounded inline-block">Active</div>
				    	</td>
				    	<td>5 Tasks</td>
				    	<td>21 Days</td>
				    	<td className="flex py-4">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white z-20" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5 z-10" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5" />
				    	</td>
					</tr>
					<tr>
						<td></td>
						<td colSpan={8} className="pb-4 w-full">
							<div className="flex items-center">
								<div className="whitespace-nowrap">Property Information:</div>
								<div className="bg-gray6 rounded-[32px] py-2 px-5 ml-3 flex w-full justify-between">
									<div className="px-3">Property Name</div>
									<div className="px-3 flex">
										<img src="/img/icons/flags/switzerland.png" className="w-4 max-h-3 object-contain" />
										8005 Zurich, Limmatstrasse 119
									</div>
									<div className="px-3 flex">
										<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
				    					Ammar Foley
									</div>
									<div className="px-3 flex whitespace-nowrap">
										<img src="/img/company-logo.png" className="w-4 max-h-6 mr-2 object-contain" />
										Company Name
									</div>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
				</table>

			</div>

			<div className="p-6 border-gray5 border rounded-lg bg-white w-full mt-6 space-y-6 hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="flex space-x-4 items-stretch">
					<h3 className="font-semibold text-main text-lg inline-block mr-auto">Upcoming Commissionings</h3>
				</div>

				<div className="flex grid grid-cols-3 gap-x-4">
					<div className="border-gray5 border bg-white rounded-md w-full p-4 space-y-4 text-xs drop-shadow-[0_2px_8px_rgba(0,0,0,0.05)] hover:border-brandGreen hover:border-2">
						<div>
							<div className="flex mb-2">
								<img src="/img/company-logo.png" className="w-4.5 max-h-4.5 mr-1 object-contain" />
								Company Name
							</div>
							<div className="text-gray3">Contractor: Jonathan Kelly</div>
						</div>
						<div>
							<div className="flex text-base font-semibold mb-2 justify-between">
								<div>Project Name ID</div>
								<div>5/6</div>
							</div>
							<div className="text-gray3 flex">
								<img src="/img/icons/flags/switzerland.png" className="w-4 max-h-3 object-contain" />
								8005 Zurich, Limmatstrasse 119
							</div>
						</div>
						<div className="flex gap-x-2 grid grid-cols-6 h-2">
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-gray5 rounded-sm"></div>
						</div>
						<div className="text-xs text-gray3">
							<div className="mb-2 py-1 px-2 border border-gray4 rounded inline-flex">
								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
								Commissioning Date: 
								<span className="font-semibold text-main ml-1">10/05/2024</span>
							</div>
							<div className="py-1 px-2 border border-gray4 rounded inline-flex">
								<img src="/img/icons/flash.svg" className="mr-1 w-3 h-3 mt-0.5" />
								Number of Overdue Tasks:
								<span className="font-semibold text-main ml-1">1 Task</span>
							</div>
						</div>
					</div>

					<div className="border-gray5 border bg-white rounded-md w-full p-4 space-y-4 text-xs drop-shadow-[0_2px_8px_rgba(0,0,0,0.05)] hover:border-brandGreen hover:border-2">
						<div>
							<div className="flex mb-2">
								<img src="/img/company-logo.png" className="w-4.5 max-h-4.5 mr-1 object-contain" />
								Company Name
							</div>
							<div className="text-gray3">Contractor: Jonathan Kelly</div>
						</div>
						<div>
							<div className="flex text-base font-semibold mb-2 justify-between">
								<div>Project Name ID</div>
								<div>3/6</div>
							</div>
							<div className="text-gray3 flex">
								<img src="/img/icons/flags/switzerland.png" className="w-4 max-h-3 object-contain" />
								8005 Zurich, Limmatstrasse 119
							</div>
						</div>
						<div className="flex gap-x-2 grid grid-cols-6 h-2">
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-brandGreen rounded-sm"></div>
							<div className="bg-gray5 rounded-sm"></div>
							<div className="bg-gray5 rounded-sm"></div>
							<div className="bg-gray5 rounded-sm"></div>
						</div>
						<div className="text-xs text-gray3">
							<div className="mb-2 py-1 px-2 border border-gray4 rounded inline-flex">
								<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
								Commissioning Date: 
								<span className="font-semibold text-main ml-1">08/05/2024</span>
							</div>
							<div className="py-1 px-2 border border-gray4 rounded inline-flex">
								<img src="/img/icons/flash.svg" className="mr-1 w-3 h-3 mt-0.5" />
								Number of Overdue Tasks:
								<span className="font-semibold text-main ml-1">3 Tasks</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ActionList />
		</div>
	);
};

export default Dashboard;