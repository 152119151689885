import React from 'react';

import { ReactComponent as IconPDF } from './assets/pdf.svg';
import { ReactComponent as IconDownload } from './assets/download.svg';

type IconComponents = {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

const iconComponents: IconComponents = {
  pdf: IconPDF,
  download: IconDownload,
};

interface IconProps {
  name: keyof typeof iconComponents;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ name, className }) => {
  const IconComponent = iconComponents[name];

  if (!IconComponent) {
    console.warn(`Icon "${name}" not found`);
    return null;
  }

  return <IconComponent className={className} fill="currentColor" />;
};

export default Icon;