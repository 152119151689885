import { makeAutoObservable } from "mobx";
import RootStore from "./rootStore";

class UserStore {
	rootStore: typeof RootStore;

  	constructor(rootStore: typeof RootStore) {
    	makeAutoObservable(this);
    	this.rootStore = rootStore;
  	}
}

export default UserStore;