import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import AdminMainLayout from './layouts/AdminMainLayout';
import MainLayout from './layouts/MainLayout';
import RequestLayout from './layouts/RequestLayout';
import Tasks from './pages/Tasks';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import Request from './pages/Request';
import NewRequest from './pages/NewRequest';

import Dashboard from './pages/Dashboard';
import Project from './pages/Project';
import Users from './pages/Users';
import Partners from './pages/Partners';
import Organizations from './pages/Organizations';
import Roles from './pages/Roles';
import ProjectCategories from './pages/ProjectCategories';
import ProjectPhases from './pages/ProjectPhases';
import ProjectTemplates from './pages/ProjectTemplates';

import UserForm from './pages/UserForm';
import PartnerForm from './pages/PartnerForm';
import OrganizationForm from './pages/OrganizationForm';
import RoleForm from './pages/RoleForm';
import ProjectCategoryForm from './pages/ProjectCategoryForm';
import ProjectPhaseForm from './pages/ProjectPhaseForm';
import ProjectTemplateForm from './pages/ProjectTemplateForm';

import AdminLogin from './pages/AdminLogin';
import PrivateRoute from './components/auth/PrivateRoute';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/admin/*"
          element={  
            <PrivateRoute layout={AdminMainLayout}>
              <Routes>
                <Route path="users" element={<Users />} />
                  <Route path="users/add" element={<UserForm />} />
                  <Route path="users/edit/:id" element={<UserForm />} />
                <Route path="partners" element={<Partners />} />
                  <Route path="partners/add" element={<PartnerForm />} />
                <Route path="roles" element={<Roles />} />
                  <Route path="roles/add" element={<RoleForm />} />
                <Route path="orgs" element={<Organizations />} />
                  <Route path="orgs/add" element={<OrganizationForm />} />
                <Route path="project-categories" element={<ProjectCategories />} />
                  <Route path="project-categories/add" element={<ProjectCategoryForm />} />
                <Route path="project-phases" element={<ProjectPhases />} />
                  <Route path="project-phases/add" element={<ProjectPhaseForm />} />
                <Route path="project-templates" element={<ProjectTemplates />} />
                  <Route path="project-templates/add" element={<ProjectTemplateForm />} />
                <Route index element={<Navigate to="/admin/users" replace />} />
              </Routes>
            </PrivateRoute>
          }
        />
        <Route 
          path="/admin" 
          element={
            <RequestLayout>
              <AdminLogin />
            </RequestLayout>
          }
        />
        <Route 
          path="/login" 
          element={
            <RequestLayout>
              <Login />
            </RequestLayout>
          }
        />
        <Route 
          path="/signup" 
          element={
            <RequestLayout>
              <SignUp />
            </RequestLayout>
          }
        />
        <Route path="/request" element={
          <RequestLayout>
            <Request />
          </RequestLayout>
        } />
        <Route path="/new-request" element={
          <RequestLayout>
            <NewRequest />
          </RequestLayout>
        } />
        <Route path="/dashboard" element={
          <MainLayout>
            <Dashboard />
          </MainLayout>
        } />
        <Route path="/tasks" element={
          <MainLayout>
            <Tasks />
          </MainLayout>
        } />
        <Route path="/project" element={
          <MainLayout>
            <Project />
          </MainLayout>
        } />
        <Route path="*" element={<div>No match</div>} />
      </Routes>
    </Router>
  );
};

// const App = () => {
  
//   return (
//     <Router>
//       <Routes>
//         <Route
//           path="/admin/partners"
//           element={
//             <PrivateRoute layout={MainLayout}>
//               <Partners />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/admin/partners/add"
//           element={
//             <PrivateRoute layout={MainLayout}>
//               <PartnerForm />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/admin/users"
//           element={
//             <PrivateRoute layout={MainLayout}>
//               <Users />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/admin/users/add"
//           element={
//             <PrivateRoute layout={MainLayout}>
//               <UserForm />
//             </PrivateRoute>
//           }
//         />
//         <Route
//           path="/admin/users/edit/:id"
//           element={
//             <PrivateRoute layout={MainLayout}>
//               <UserForm />
//             </PrivateRoute>
//           }
//         />
//         <Route path="/login" element={
//           <RequestLayout>
//             <Login />
//           </RequestLayout>
//         } />
//       </Routes>
//     </Router>
//   );
// }

export default App;