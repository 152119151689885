import React, { ReactNode } from 'react';

type TabProps = {
  name: string;
  activeTab: string;
  onClick: (tab: string) => void;
  children: React.ReactNode;
};

export const Tab: React.FC<TabProps> = ({ name, activeTab, onClick, children }) => {

	return (
		<div 
			className={`py-3 px-4 ${ (activeTab === name) ? 'font-semibold text-main border-b-2 border-b-main' : 'cursor-pointer hover:text-main'} `}
			onClick={() => onClick(name)}
		>
			{children}
		</div>
	);
};