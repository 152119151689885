import React from 'react';
import InputMask from 'react-input-mask';

type DateInputProps = {
  id?: string | undefined;
  name?: string;
  placeholder?: string;
  value?: string | undefined;
  error?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const DateInput = ({
  id,
  name,
  placeholder,
  value,
  error,
  className,
  onChange,
  onKeyDown,
}: React.PropsWithChildren<DateInputProps>) => {
  const mask = '99-99-9999';

  return (
    <div className="w-full">
      <InputMask
        id={id}
        name={name}
        mask={mask}
        maskPlaceholder={null}
        className={`w-full p-3 border rounded-lg outline-none text-sm ${
          error ? 'border-ceRed' : 'border-gray5 focus:border-main'
        }`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <div className={`text-ceRed text-xs mt-2 ${error ? '' : 'hidden'}`}>
        {error ? error : ''}
      </div>
    </div>
  );
};
