import { ChangeEvent, useState, useEffect } from "react";

type InputPhoneProps = {
  id?: string | undefined;
  name?: string;
  placeholder?: string;
  autoComplete?: string;
  autoCorrect?: string;
  value?: string | undefined;
  error?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};

const countryCodes: { [key: string]: string } = {
    "43": "austria",
    "49": "germany",
    "41": "switzerland",
};

const formatPhoneNumber = (value: string) => {
  const cleanedValue = value.replace(/\D/g, "");
  const limitedValue = cleanedValue.slice(0, 11);

  if (limitedValue.length === 0) {
    return "";
  }

  if (limitedValue.length <= 2) {
    return "+" + limitedValue;
  }

  let formattedValue = "+" + limitedValue.slice(0, 2) + " " + limitedValue.slice(2, 5);

  if (limitedValue.length > 5) {
    formattedValue += " " + limitedValue.slice(5, 8);
  }

  if (limitedValue.length > 8) {
    formattedValue += " " + limitedValue.slice(8);
  }

  return formattedValue;
};

const getCountryCodeFromNumber = (value: string) => {
  const countryCode = value.slice(1, 3);

  var country = "switzerland"
  if((countryCode && countryCodes[countryCode]) || !value) {
    if(countryCode)
      country = countryCodes[countryCode]
    return `/img/icons/flags/${country}.png`;
  } else
    return false;
};

export const InputPhone = ({
  id,
  name,
  placeholder,
  autoComplete,
  autoCorrect,
  value,
  error,
  onChange,
  disabled = false,
}: React.PropsWithChildren<InputPhoneProps>) => {
  const [inputValue, setInputValue] = useState(value || "");

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (!newValue.startsWith("+")) {
      newValue = "+" + newValue;
    }

    setInputValue(newValue);

    if (onChange) {
      onChange(newValue);
    }
  };

  const formattedValue = formatPhoneNumber(inputValue);
  const countryFlag = getCountryCodeFromNumber(inputValue);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <div className="relative w-full">
      { countryFlag ?
        <img src={countryFlag} alt="" className="absolute w-3.5 h-3.5 mt-3.5 ml-2" />
        : ''
      }
      <input
        id={id}
        type="tel"
        maxLength={15}
        name={name}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        className={`w-full p-3 pl-[1.625rem] border rounded-lg outline-none focus:border-main text-sm ${error ? 'border-ceRed' : 'border-gray5'} disabled:bg-gray6`}
        placeholder={placeholder}
        value={formattedValue}
        onChange={handleChange}
        disabled={disabled}
      />
      <div className="text-ceRed text-xs mt-2 ${error ? '' : 'hidden'}">
        {error ? error : '' }
      </div>
    </div>
  );
};
