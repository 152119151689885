import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import ConfirmModal from '../components/ui/ConfirmModal';

import { organizationTypesDict } from '../utils/constants';

import { Organization } from '../entities/Organization';

type OrganizationsConfig = {
  [key: string]: {
    label: string;
    sortKey?: boolean;
    entityClass?: string | Record<string, string>;
  };
};

const Organizations = () => {
	const [sortOrder, setSortOrder] = useState('asc');
  	const [sortKey, setSortKey] = useState(null);
  	const [searchTerm, setSearchTerm] = useState('');
  	const [selectedRows, setSelectedRows] = useState<string[]>([]);
  	const [entityData, setEntityData] = useState<{ id: string; name: string; }[]>([]);
  	const [showConfirmModal, setShowConfirmModal] = useState(false);
  	const navigate = useNavigate();
  	const baseUrl = window.location.pathname;

  	// Entities Data
	const [entityValues, setEntityValues] = useState<Record<string, string>>({});

  	const columnConfig: OrganizationsConfig = {
		name: { label: 'Name', sortKey: true },
		organizationNumber: { label: 'Number', sortKey: true },
		category: { label: 'Category', sortKey: true, entityClass: organizationTypesDict },
	};

	const loadEntityValues = async (entityClass: string | Record<string, string>) => {
		if(typeof entityClass !== 'string') {
			setEntityValues(entityClass);
		} else {
		  	try {
		    	const dataSourceClass = (window as any)[entityClass];
		    	if (dataSourceClass && typeof dataSourceClass.getAll === 'function') {
		      		const response = await dataSourceClass.getAll();
		      		if (typeof response !== 'string') {
		        		const values = response.content.reduce((acc: Record<string, string>, item: any) => {
		          			acc[item.id] = item.name;
		          			return acc;
		        		}, {});
		        		setEntityValues((prevValues) => ({ ...prevValues, [entityClass]: values }));
		      		} else {
		        		console.error(`Error fetching ${entityClass} data:`, response);
		      		}
		    	} else {
		      		console.error(`Invalid entityClass or missing getAll method for ${entityClass}`);
		    	}
		  	} catch (error) {
		    	console.error(`Error fetching ${entityClass} data:`, error);
		  	}
	  	}
	};

  	const handleSort = (key: any) => {
	    if (sortKey === key) {
	      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	    } else {
	      setSortKey(key);
	      setSortOrder('asc');
	    }
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
	    setSearchTerm(event.target.value);
	};

	const handleRowCheckboxChange = (id: string) => {
	  	setSelectedRows((prevSelectedRows) => {
		    if (prevSelectedRows.includes(id)) {
		      	return prevSelectedRows.filter((rowId) => rowId !== id);
		    } else {
		      	return [...prevSelectedRows, id];
		    }
	  	});
	};

	const handleHeaderCheckboxChange = () => {
	  	if (selectedRows.length === entityData.length) {
	   	 	setSelectedRows([]);
	  	} else {
	    	setSelectedRows(entityData.map((item) => item.id));
	  	}
	};

	const filteredData = sortKey
	    ? entityData
	        .sort((a, b) => {
	          const valueA = (a[sortKey] as any).toString().toLowerCase();
           	  const valueB = (b[sortKey] as any).toString().toLowerCase();

	          if (valueA < valueB) {
	            return sortOrder === 'asc' ? -1 : 1;
	          }
	          if (valueA > valueB) {
	            return sortOrder === 'asc' ? 1 : -1;
	          }
	          return 0;
	        })
	        .filter((item) =>
	          Object.values(item).some((value) =>
	            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	          )
	        )
	    : entityData.filter((item) =>
	        Object.values(item).some((value) =>
	          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	        )
	      );

	const fetchOrganizations = async () => {
      	try {
        	const response = await Organization.getAll();
        	if (typeof response === 'string') {
		    	console.error('Error fetching partners:', response);
		    } else {
		    	const orgs = response.content;
			    setEntityData(orgs);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
    };

    useEffect(() => {
	    fetchOrganizations();

  		const entityClasses = Object.values(columnConfig)
    		.filter((column) => column.entityClass)
    		.map((column) => column.entityClass!);

  		entityClasses.forEach((entityClass) => {
    		loadEntityValues(entityClass);
  		});
	}, []);

	const confirmDelete = async () => {
	  	for (const rowId of selectedRows) {
	    	try {
	      		console.log("delete = ", rowId);
	      		await Organization.delete(rowId);
	    	} catch (error) {
	      		console.error(`Error deleting the organization ${rowId}:`, error);
	    	}
	  	}
	  	closeConfirmModal();
	};

	const handleDelete = async () => {
		if (selectedRows.length > 0) {
		    openConfirmModal();
		}
	};

	const openConfirmModal = () => {
	  	setShowConfirmModal(true);
	};

	const closeConfirmModal = () => {
		fetchOrganizations();
		setSelectedRows([]);
	  	setShowConfirmModal(false);
	};

	return (
		<div className="px-8 py-3 w-full h-auto min-h-screen m-auto max-w-[1330px]">
			<ConfirmModal
		        isOpen={showConfirmModal}
		        onConfirm={confirmDelete}
		        onCancel={closeConfirmModal}
		    />

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Organization management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<span className="text-main">Organizations</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-full flex mb-10 justify-end gap-8">

							<div className="h-10 flex gap-8">
								<button 
									className="bg-red-300 px-6 h-12 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] disabled:bg-gray4" 
									disabled={selectedRows.length === 0}
									onClick={handleDelete}
								>
									Delete
								</button>
								<Link to={`${baseUrl}/add`} className="bg-brandGreen px-6 h-12 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Organization</Link>
							</div>
						</div>

						<table className="w-full text-xs">
	  						<thead>
								<tr className="text-gray3 border-b border-b-gray3 text-left">
									<th className="px-2 pb-4">
										<input 
											type="checkbox" 
									        checked={selectedRows.length > 0 && selectedRows.length === entityData.length}
									        onChange={handleHeaderCheckboxChange}
										/>
									</th>
							    {Object.entries(columnConfig).map(([key, config]) => (
				                    <th
				                      key={key}
				                      className={`font-normal pb-4 ${config.sortKey ? 'cursor-pointer hover:font-semibold' : ''}`}
				                      onClick={() => config.sortKey && handleSort(key)}
				                    >
				                      {config.label}
				                      {config.sortKey && sortKey === key && (
				                        <span className="ml-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>
				                      )}
				                    </th>
				                ))}
								</tr>
							</thead>
	  						<tbody>
							{filteredData.map((item) => (
			                  <tr
							    key={item.id}
							    className={`text-main border-b border-b-gray4 cursor-pointer hover:bg-green8 ${
							        selectedRows.includes(item.id) ? 'bg-gray6' : ''
							    }`}
							  >
			                  	<td className="px-2 w-10">
									<input 
										type="checkbox" 
										id={`item_${item.id}`} 
								        checked={selectedRows.includes(item.id)}
								        onChange={() => handleRowCheckboxChange(item.id)}
									/>
								</td>
								{Object.entries(columnConfig).map(([key, config], index) => (
			                    <td 
			                    	key={`${item.id}-${key}-${index}`} 
			                    	className="py-4"
							    	onClick={() => navigate(`${baseUrl}/edit/${item.id}`)}
			                    >
			                      	{config.entityClass && typeof config.entityClass !== 'string' && entityValues
								      	? entityValues[(item as any)[key]]
								      	: (item as any)[key]
								    }

			                    </td>
			                    ))}
			                  </tr>
			                ))}
							</tbody>
	  					</table>
					</div>

				</div>

			</div>

		</div>
	)
};

export default Organizations;