import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

import { Sort, Pageable, ApiResponse, SelectResponse } from '../interfaces';

interface ProjectCategoryData {
  id: string;
  name: string;
  partnerId: string;
}

export class ProjectCategory {
  id: string;
  name_de: string;
  name_fr: string;
  name_it: string;
  name_rm: string;
  permissions: string[];
  partnerId: string;

  constructor(id: string, name_de: string, name_fr: string, name_it: string, name_rm: string, partnerId: string, permissions: string[]) {
    this.id = id;
    this.name_de = name_de;
    this.name_fr = name_fr;
    this.name_it = name_it;
    this.name_rm = name_rm;
    this.partnerId = partnerId;
    this.permissions = permissions;
  }

  static async add
    (
      partnerId: string,
      name_de: string,
      name_fr: string,
      name_it: string,
      name_rm: string,
    ): Promise<ProjectCategory | string> {

    const params = {
      "names": {
        "de": name_de,
        "fr": name_fr,
        "it": name_it,
        "rm": name_rm,
      },
      "partnerId": partnerId,
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    }

    try {
      const data = await apiHandler.post<ProjectCategory>(`/project-categories`, params, config);
      return data;
    } catch (error: any) {
      return 'An error occurred while adding the category';
    }

    return '';
  }

  static async getAll(partnerId: string): Promise<ApiResponse<ProjectCategoryData> | string> {

    const accessToken = localStorage.getItem('token');

    const params = {
      "partnerId": partnerId,
      "page": 0,
      "size": 1000,
      "sort": []
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.get<ApiResponse<ProjectCategoryData>>(`/project-categories?${ApiHandler.getQueryString(params)}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Categories not found';
      } else {
        return 'An error occurred while fetching the categories';
      }
    }
  }

  static async getForSelect(partnerId: string): Promise<SelectResponse[] | string> {
    const response = await ProjectCategory.getAll(partnerId);

    if (typeof response === 'string') {
      return response;
    }

    const selectResponses: SelectResponse[] = response.content;
    return selectResponses;
  }


  static async delete(categoryId: string): Promise<string> {

    const accessToken = localStorage.getItem('token');

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.delete<string>(`/project-categories/${categoryId}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Category not found';
      } else {
        return 'An error occurred while deleting the category';
      }
    }

  }

}