import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select } from "../components/Select";
import { SearchInput } from "../components/SearchInput";
import { SearchAddressInput } from "../components/SearchAddressInput";
import { SelectOption, OptionItem } from "../types";
import { Input } from "../components/Input";
import { FileWithCategoryInput } from "../components/FileWithCategoryInput";
import { InputPhone } from "../components/InputPhone";
import { Textarea } from "../components/Textarea";

import { Tab } from '../components/ui/Tab';

import { countriesDict, fileCategoriesDict } from '../utils/constants';

import { GeoApiService } from '../classes/GeoApiService';

import { Property } from '../entities/Property';
import { Request } from '../entities/Request';

import { useFormik, FormikHelpers, FormikProps, FormikErrors } from 'formik';
import * as Yup from 'yup';

type TabType = 'property' | 'address' | 'success' | 'error';

const optionsCountry = countriesDict;
const fileCategoriesCountry = fileCategoriesDict;

interface FileData {
  id: string;
  file: File;
  category: string;
}

const validationSchema = Yup.object().shape({
  PropertyID: Yup.string().required('PropertyID is required'),
  Category: Yup.string().required('Category is required'),
  Comment: Yup.string().required('Comment is required'),
  Lastname: Yup.string().required('Last Name is required'),
  Firstname: Yup.string().required('First Name is required'),
  Email: Yup.string().email('E-mail wrong format').required('E-mail is required'),
  Phone: Yup.string().matches(/^\+\d{2}\s\d{3}\s\d{3}\s\d{3}$/, 'Number wrong format').required('Phone Number is required'),
  Documents: Yup.array()
    .of(
      Yup.object().shape({
        file: Yup.mixed().required(),
        category: Yup.string().required('Category is required')
      })
    )
});

const addressValidationSchema = Yup.object().shape({
  Country: Yup.string().required('Country is required'),
  Zip: Yup.string().required('Zip Code is required'),
  City: Yup.string().required('City is required'),
  Street: Yup.string().required('Street is required'),
  House: Yup.string().required('Building is required')
});

// Form in PropertyID tab
interface FormValues {
  PropertyID: string;
  Category: string;
  Comment: string;
  Documents: FileData[],
  Firstname: string;
  Lastname: string;
  Email: string;
  Phone: string;
};

const initialValues: FormValues = {
  PropertyID: '',
  Category: '',
  Comment: '',
  Documents: [] as FileData[],
  Firstname: '',
  Lastname: '',
  Email: '',
  Phone: '',
};

// Search Property ID by address form
interface FormAddressValues {
  Country: string;
  Zip: string;
  City: string;
  Street: string;
  House: string;
};

const initialFormAddressValues: FormAddressValues = {
  Country: 'CH',
  Zip: '',
  City: '',
  Street: '',
  House: '',
};

// Form in Address tab
interface FormAddressTabValues {
  PropertyID: string;
  Category: string;
  Comment: string;
  Documents: FileData[],
  Firstname: string;
  Lastname: string;
  Email: string;
  Phone: string;
};

const initialFormAddressTabValues: FormAddressTabValues = {
  PropertyID: '',
  Category: '',
  Comment: '',
  Documents: [] as FileData[],
  Firstname: '',
  Lastname: '',
  Email: '',
  Phone: '',
};


interface RequestProps {
  formik: FormikProps<FormValues>;
}

const getTabFromHash = () => {
  const hash = window.location.hash.replace('#', '');
  return hash as TabType || 'property';
};

const NewRequest = () => {

  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isFormSubmit2, setIsFormSubmit2] = useState(false);
  const [isFormSubmit3, setIsFormSubmit3] = useState(false);
  const [activeBlock, setActiveBlock] = useState(getTabFromHash());
  const [activeForm, setActiveForm] = useState(getTabFromHash());
  const [requestNum, setRequestNum] = useState('');
  const [propertyFound, setPropertyFound] = useState(false);
  const [propertyFound2, setPropertyFound2] = useState(false);

  const [propertyDetails, setPropertyDetails] = useState<Property | null | string>(null);
  const [optionsCategory, setOptionsCategory] = useState<OptionItem>({});
  const [optionsCategory2, setOptionsCategory2] = useState<OptionItem>({});
  const [resetKey, setResetKey] = useState(Date.now().toString());

  const navigate = useNavigate();

  useEffect(() => {
      const handleHashChange = () => {
        setActiveBlock(getTabFromHash());
      };

      window.addEventListener('hashchange', handleHashChange);
      return () => {
        window.removeEventListener('hashchange', handleHashChange);
      };
  }, []);

  const handleSubmit = async (values: FormValues, formHelper: FormikHelpers<FormValues>) => {
    try {
      if (!propertyDetails) {
        const property = await Property.getByNumber(values.PropertyID);
        setPropertyDetails(property || null);
      }
      formHelper.setFieldError('PropertyID', undefined);

      const errors = await formHelper.validateForm();

      if (Object.keys(errors).length === 0) {
        console.log('Submitted values:', values);
        if (propertyDetails instanceof Property) {
          setIsFormSubmit(true);
          setIsFormSubmit3(true);

          const request = new Request(propertyDetails.id,
                                      values.Category,
                                      values.Comment,
                                      values.Firstname,
                                      values.Lastname,
                                      values.Email,
                                      values.Phone,
                                    );
          const requestNumber = await request.createRequest(values.Documents);

          if(requestNumber != null) {
            setRequestNum(requestNumber);
            setActiveBlock('success');
            resetForm();
          } else {
            setActiveBlock('error');
          }
          
          setIsFormSubmit(false);
          setIsFormSubmit3(false);
        }
      }

    } catch (error) {
      console.error('Error fetching property by number in component:', error);
      setPropertyDetails(null);
      formHelper.setFieldError('PropertyID', 'Property not found');
    }
  };

  const handleSubmit2 = async (values: FormAddressValues, formHelper: FormikHelpers<FormAddressValues>) => {
    console.log("values", values);

    if (values) {

      const params = {
        country: values.Country,
        zip: values.Zip,
        city: values.City,
        street: values.Street,
        streetNumber: values.House,
      };

      const result = await Property.getByAddress(params);

      if (typeof result === 'string') {
        formik3.setFieldError('PropertyID', result);
        console.log("error", result);
        setPropertyFound2(false);
      } else {
        setPropertyDetails(result || null);
        formik3.setFieldError('PropertyID', undefined);

        if (result) {
          formik3.setFieldValue('PropertyID', result.propertyNumber);
          setPropertyFound2(true);

          await result.getCategories();
          setOptionsCategory2(
            result.categories.reduce((options, category) => {
              options[category.id] = category.name;
              return options;
            }, {} as OptionItem)
          );
        }
      }
    }

  };

  const resetForm = () => {
    formik.resetForm();
    formik2.resetForm();
    formik3.resetForm();
    setPropertyFound(false);
    setPropertyFound2(false);
    setResetKey(Date.now().toString());
  };

  const formik: FormikProps<FormValues> = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const formik2: FormikProps<FormAddressValues> = useFormik<FormAddressValues>({
    initialValues: initialFormAddressValues,
    validationSchema: addressValidationSchema,
    onSubmit: handleSubmit2,
  });

  const formik3: FormikProps<FormAddressTabValues> = useFormik<FormAddressTabValues>({
    initialValues: initialFormAddressTabValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleTabClick = (tab: string) => {
    setActiveBlock(tab as TabType);
    setActiveForm(tab as TabType);
    window.location.hash = tab;
  };

  const handleSelect = (field: string, option: SelectOption, form: FormikProps<FormAddressTabValues>) => {
    form.setFieldValue(field, option.value);
  };
  const handleCountrySelect = (field: string, option: SelectOption) => {
    formik2.setFieldValue(field, option.value);
  };
  const handlePhoneNumberChange = (formattedValue: string, form: FormikProps<FormAddressTabValues>) => {
    form.setFieldValue('Phone', formattedValue);
  };

  const handlePropertySearch = async (value: string) => {

    if (value) {
      const result = await Property.getByNumber(value);

      if (typeof result === 'string') {
        formik.setFormikState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, PropertyID: result },
          touched: { ...prevState.touched, PropertyID: true },
        }));
        setPropertyFound(false);
      } else {
        setPropertyDetails(result || null);
        formik.setFieldError('PropertyID', undefined);

        if (result) {
          setPropertyFound(true);

          await result.getCategories();
          setOptionsCategory(
            result.categories.reduce((options, category) => {
              options[category.id] = category.name;
              return options;
            }, {} as OptionItem)
          );
        }
      }
    }

  };

  const handleFileDataChange = (fileData: FileData[], form: FormikProps<FormAddressTabValues>) => {
    form.setFieldValue('Documents', fileData);
  };

  const geoApiService = new GeoApiService();


  return (
	  <div className="w-full flex p-12">

      <div className={`mx-auto ${activeBlock === 'property' || activeBlock === 'address' ? '' : 'hidden'}`}>

        <div className="w-[550px] flex rounded-lg bg-white border border-gray5 p-8 text-center flex-col">

            <div>
              <h1 className="text-main font-semibold text-2xl">New Request</h1>
              <div className="text-gray3 text-sm mt-2">Fill out the information for this property</div>

              <div className="my-6 w-full text-sm text-gray3 flex border-b border-b-gray5">
                <div className="w-1/2">
                  <Tab name="property" activeTab={activeBlock} onClick={handleTabClick}>Property ID</Tab>
                </div>
                <div className="w-1/2">
                  <Tab name="address" activeTab={activeBlock} onClick={handleTabClick}>Local Address</Tab>
                </div>
              </div>

              <div className={`${ activeBlock === 'address' ? 'hidden' : '' }`}>

                <form onSubmit={formik.handleSubmit}>

                  <div className="w-full space-y-2 text-left">
                    <label htmlFor="PropertyID" className={`text-sm ${formik.touched.PropertyID && formik.errors.PropertyID ? 'text-ceRed' : 'text-main'}`}>Property ID</label>
                    <div className="flex w-full space-x-4 items-start">
                      <Input 
                        type="text" 
                        id="PropertyID" 
                        placeholder="Enter your Property ID"
                        name="PropertyID"
                        value={formik.values.PropertyID} 
                        onChange={formik.handleChange}
                        success={propertyFound ? 'Your property ID was successfully found' : ''}
                        error={
                          formik.touched.PropertyID && formik.errors.PropertyID
                            ? formik.errors.PropertyID
                            : undefined
                        }
                      />
                      <button 
                        className="bg-brandGreen px-4 py-3 text-[11px] min-w-[120px] font-semibold rounded leading-5 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white"
                        type="button"
                        onClick={() => handlePropertySearch(formik.values.PropertyID)}
                      >Search</button>
                    </div>
                  </div>

                  <div className="w-full space-y-2 text-left mt-6">
                    <label htmlFor="Category" className={`text-sm ${formik.touched.Category && formik.errors.Category ? 'text-ceRed' : 'text-main'}`}>Category</label>
                    <Select
                      id="Category"
                      placeholder="Choose the appropriate category"
                      value={formik.values.Category} 
                      options={optionsCategory}
                      name="Category"
                      onSelected={(field, option) => handleSelect(field, option, formik)} 
                      disabled={!propertyFound}
                      error={
                        formik.touched.Category && formik.errors.Category
                          ? formik.errors.Category
                          : undefined
                      }
                    />
                  </div>

                  <div className="w-full space-y-2 text-left mt-4">
                    <label htmlFor="Comment" className={`text-sm ${formik.touched.Comment && formik.errors.Comment ? 'text-ceRed' : 'text-main'}`}>Comment</label>
                    <Textarea 
                      id="Comment" 
                      placeholder="Enter your comment" 
                      name="Comment"
                      onChange={formik.handleChange} 
                      value={formik.values.Comment} 
                      disabled={!propertyFound}
                      error={
                        formik.touched.Comment && formik.errors.Comment
                          ? formik.errors.Comment
                          : undefined
                      }
                    />
                  </div>

                  <div className="grid grid-cols-2 w-full gap-x-6 gap-y-4 text-left mt-6">
                    <div className="w-full space-y-2">
                      <label htmlFor="Firstname" className={`text-sm ${formik.touched.Firstname && formik.errors.Firstname ? 'text-ceRed' : 'text-main'}`}>First Name</label>
                      <Input 
                        type="text" 
                        id="Firstname" 
                        placeholder="What’s your first name?"  
                        name="Firstname"
                        onChange={formik.handleChange} 
                        value={formik.values.Firstname} 
                        disabled={!propertyFound}
                        error={
                          formik.touched.Firstname && formik.errors.Firstname
                            ? formik.errors.Firstname
                            : undefined
                        }
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label htmlFor="Lastname" className={`text-sm ${formik.touched.Lastname && formik.errors.Lastname ? 'text-ceRed' : 'text-main'}`}>Last Name</label>
                      <Input 
                        type="text" 
                        id="Lastname" 
                        placeholder="What’s your last name?" 
                        name="Lastname"
                        onChange={formik.handleChange} 
                        value={formik.values.Lastname} 
                        disabled={!propertyFound}
                        error={
                          formik.touched.Lastname && formik.errors.Lastname
                            ? formik.errors.Lastname
                            : undefined
                        }
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label htmlFor="Email" className={`text-sm ${formik.touched.Email && formik.errors.Email ? 'text-ceRed' : 'text-main'}`}>Email</label>
                      <Input 
                        type="email" 
                        id="Email" 
                        placeholder="Enter your email" 
                        name="Email"
                        onChange={formik.handleChange} 
                        value={formik.values.Email} 
                        disabled={!propertyFound}
                        error={
                          formik.touched.Email && formik.errors.Email
                            ? formik.errors.Email
                            : undefined
                        }
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label htmlFor="Phone" className={`text-sm ${formik.touched.Phone && formik.errors.Phone ? 'text-ceRed' : 'text-main'}`}>Phone Number</label>
                      <InputPhone 
                        id="Phone" 
                        placeholder="+41" 
                        name="Phone"
                        onChange={(value) => handlePhoneNumberChange(value, formik)} 
                        value={formik.values.Phone} 
                        disabled={!propertyFound}
                        error={
                          formik.touched.Phone && formik.errors.Phone
                            ? formik.errors.Phone
                            : undefined
                        }
                      />
                    </div>
                  </div>

                  <div className="w-full space-y-2 text-left mt-4">
                    <label htmlFor="Documents" className={`text-sm text-main`}>Documents</label>
                    <p className="text-gray3 text-sm pb-2">
                      Please attach any available documents (plans, pictures, etc.).<br/>
                      You can upload documents only in .pdf, .jpg, .jpeg, .png format.
                    </p>
                    <FileWithCategoryInput
                      id="Documents"
                      placeholder="Choose files"
                      name="Documents"
                      accept="image/*,.pdf"
                      multiple
                      resetKey={resetKey} 
                      onChange={(fileData) => handleFileDataChange(fileData, formik)}
                      categories={fileCategoriesCountry}
                      disabled={!propertyFound}
                      errors={
                        formik.touched.Documents && formik.errors.Documents
                          ? formik.errors.Documents as FormikErrors<FileData>[]
                          : undefined
                      }
                    />
                  </div>

                  <button 
                    type="submit" 
                    disabled={isFormSubmit}
                    className="mt-12 bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white disabled:text-white disabled:bg-green3">
                    {isFormSubmit ? 'Loading ...' : 'Submit'}
                  </button>

                </form>
              </div>

              <div className={`${ activeBlock === 'property' ? 'hidden' : '' }`}>

                <form onSubmit={formik2.handleSubmit}>

                  <div className="w-full space-y-2 text-left">
                    <label htmlFor="country" className="text-sm text-main">Country</label>
                    <Select
                      id="Country"
                      name="Country"
                      placeholder="Choose your country"
                      value={formik2.values.Country} 
                      options={optionsCountry}
                      onSelected={handleCountrySelect}
                      error={
                        formik2.touched.Country && formik2.errors.Country
                          ? formik2.errors.Country
                          : undefined
                      }
                    />
                  </div>

                  <div className="w-full space-y-2 text-left mt-6">
                    <label htmlFor="text-10" className="text-sm text-main">Street</label>
                    <SearchAddressInput
                      id="Street"
                      name="Street"
                      placeholder="Enter your street"
                      value={formik2.values.Street}
                      onChange={(event, addressData) => {
                        formik2.handleChange(event);
                        if (addressData) {
                          formik2.setFieldValue('Zip', addressData.ZipCode);
                          formik2.setFieldValue('City', addressData.TownName);
                        }
                      }}
                      suggestionProvider={(query) => geoApiService.getStreetSuggestions(formik2.values.Zip, query)}
                      displayFormat={(data) => `${data.StreetName}, ${data.ZipCode} ${data.Canton} ${data.TownName}`}
                      inputFormat={(data) => data.StreetName || ''}
                      error={
                        formik2.touched.Street && formik2.errors.Street
                          ? formik2.errors.Street
                          : undefined
                      }
                    />
                  </div>

                  <div className="w-full space-y-2 text-left mt-6">
                    <label htmlFor="text-10" className="text-sm text-main">Building</label>
                    <SearchAddressInput
                      id="House"
                      name="House"
                      placeholder="Enter your building number"
                      value={formik2.values.House}
                      onChange={formik2.handleChange} 
                      suggestionProvider={(query) => geoApiService.getHouseSuggestions(formik2.values.Zip, formik2.values.Street, query)}
                      displayFormat={(data) => `${data.HouseNo}`}
                      inputFormat={(data) => data.HouseNo || ''}
                      error={
                        formik2.touched.House && formik2.errors.House
                          ? formik2.errors.House
                          : undefined
                      }
                    />
                  </div>

                  <div className="w-full space-y-2 text-left mt-6">
                    <label htmlFor="text-11" className="text-sm text-main">Zip Code</label>
                    <SearchAddressInput
                      id="Zip"
                      name="Zip"
                      placeholder="Enter your zip code"
                      value={formik2.values.Zip} 
                      onChange={formik2.handleChange} 
                      suggestionProvider={geoApiService.getZipSuggestions}
                      displayFormat={(data) => `${data.ZipCode}`}
                      inputFormat={(data) => data.ZipCode || ''}
                      error={
                        formik2.touched.Zip && formik2.errors.Zip
                          ? formik2.errors.Zip
                          : undefined
                      }
                    />
                  </div>

                  <div className="w-full space-y-2 text-left mt-6">
                    <label htmlFor="city" className="text-sm text-main">City</label>
                    <SearchAddressInput
                      id="City"
                      name="City"
                      placeholder="Enter your city"
                      value={formik2.values.City} 
                      onChange={formik2.handleChange} 
                      suggestionProvider={(query) => geoApiService.getCitySuggestions(formik2.values.Zip, query)}
                      displayFormat={(data) => `${data.TownName}, ${data.ZipCode} ${data.Canton}`}
                      inputFormat={(data) => data.TownName || ''}
                      error={
                        formik2.touched.City && formik2.errors.City
                          ? formik2.errors.City
                          : undefined
                      }
                    />
                  </div>

                  <button 
                    type="submit"
                    disabled={isFormSubmit2}
                    className="mt-6 bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white disabled:text-white disabled:bg-green3">
                    {isFormSubmit2 ? 'Loading ...' : 'Search'}
                  </button>

                  {formik3.errors.PropertyID && <div className="text-ceRed text-xs mt-4 text-left">{formik3.errors.PropertyID}</div>}
                  {propertyFound2 && <div className="text-green1 text-xs mt-4 text-left">Your property ID was successfully found</div>}

                </form>

                <form onSubmit={formik3.handleSubmit}>

                  <div className="w-full space-y-2 text-left mt-8">
                    <label htmlFor="requestCategory" className="text-sm text-main">Category</label>
                    <Select
                      id="Category"
                      placeholder="Choose the appropriate category"
                      value={formik3.values.Category} 
                      options={optionsCategory2}
                      name="Category"
                      onSelected={(field, option) => handleSelect(field, option, formik3)} 
                      disabled={!propertyFound2}
                      error={
                        formik3.touched.Category && formik3.errors.Category
                          ? formik3.errors.Category
                          : undefined
                      }
                    />
                  </div>

                  <div className="w-full space-y-2 text-left mt-6">
                    <label htmlFor="text-13" className="text-sm text-main">Comment</label>
                    <Textarea 
                      id="Comment" 
                      placeholder="Enter your comment" 
                      name="Comment"
                      onChange={formik3.handleChange} 
                      value={formik3.values.Comment} 
                      disabled={!propertyFound2}
                      error={
                        formik3.touched.Comment && formik3.errors.Comment
                          ? formik3.errors.Comment
                          : undefined
                      }
                    />
                  </div>

                  <div className="grid grid-cols-2 w-full gap-x-6 gap-y-4 text-left mt-6">
                    <div className="w-full space-y-2">
                      <label htmlFor="Firstname" className={`text-sm ${formik.touched.Firstname && formik.errors.Firstname ? 'text-ceRed' : 'text-main'}`}>First Name</label>
                      <Input 
                        type="text" 
                        id="Firstname" 
                        placeholder="What’s your first name?"  
                        name="Firstname"
                        onChange={formik3.handleChange} 
                        value={formik3.values.Firstname} 
                        disabled={!propertyFound2}
                        error={
                          formik3.touched.Firstname && formik3.errors.Firstname
                            ? formik3.errors.Firstname
                            : undefined
                        }
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label htmlFor="Lastname" className={`text-sm ${formik.touched.Lastname && formik.errors.Lastname ? 'text-ceRed' : 'text-main'}`}>Last Name</label>
                      <Input 
                        type="text" 
                        id="Lastname" 
                        placeholder="What’s your last name?" 
                        name="Lastname"
                        onChange={formik3.handleChange} 
                        value={formik3.values.Lastname} 
                        disabled={!propertyFound2}
                        error={
                          formik3.touched.Lastname && formik3.errors.Lastname
                            ? formik3.errors.Lastname
                            : undefined
                        }
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label htmlFor="Email" className={`text-sm ${formik.touched.Email && formik.errors.Email ? 'text-ceRed' : 'text-main'}`}>Email</label>
                      <Input 
                        type="email" 
                        id="Email" 
                        placeholder="Enter your email" 
                        name="Email"
                        onChange={formik3.handleChange} 
                        value={formik3.values.Email} 
                        disabled={!propertyFound2}
                        error={
                          formik3.touched.Email && formik3.errors.Email
                            ? formik3.errors.Email
                            : undefined
                        }
                      />
                    </div>
                    <div className="w-full space-y-2">
                      <label htmlFor="Phone" className={`text-sm ${formik.touched.Phone && formik.errors.Phone ? 'text-ceRed' : 'text-main'}`}>Phone Number</label>
                      <InputPhone 
                        id="Phone" 
                        placeholder="+41" 
                        name="Phone"
                        onChange={(value) => handlePhoneNumberChange(value, formik3)} 
                        value={formik3.values.Phone} 
                        disabled={!propertyFound2}
                        error={
                          formik3.touched.Phone && formik3.errors.Phone
                            ? formik3.errors.Phone
                            : undefined
                        }
                      />
                    </div>
                  </div>

                  <div className="w-full space-y-2 text-left mt-4">
                    <label htmlFor="Documents" className={`text-sm text-main`}>Documents</label>
                    <p className="text-gray3 text-sm pb-2">
                      Please attach any available documents (plans, pictures, etc.).<br/>
                      You can upload documents only in .pdf, .jpg, .jpeg, .png format.
                    </p>
                    <FileWithCategoryInput
                      id="Documents2"
                      placeholder="Choose files"
                      name="Documents"
                      accept="image/*,.pdf"
                      multiple
                      resetKey={resetKey} 
                      onChange={(fileData) => handleFileDataChange(fileData, formik3)}
                      categories={fileCategoriesCountry}
                      disabled={!propertyFound2}
                    />
                  </div>

                  <button 
                    type="submit" 
                    disabled={isFormSubmit3}
                    className="mt-12 bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white disabled:text-white disabled:bg-green3">
                    {isFormSubmit3 ? 'Loading ...' : 'Submit'}
                  </button>

                </form>
              </div>

            </div>
        </div>

      </div>

    {/* Success message */}
      <div className={`w-4/5 flex pb-20 text-center items-center justify-center mx-auto ${ activeBlock === 'success' ? '' : 'hidden' }`}>
          <div>
            <div className="flex justify-center">
              <img src="/img/request-success.png" className="w-[300px] h-[300px]" />
            </div>
            <h1 className="text-main font-semibold text-[44px] mt-8">Thanks for submitting!</h1>
            <div className="text-gray3 text-lg mt-1 mb-8">Your request #{requestNum} has been sent.</div>
            <button 
              className="max-w-[360px] bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white"
              onClick={() => {
                  handleTabClick('property');
                }}
              >
              New request
            </button>
          </div>
      </div>

      {/* Error message */}
      <div className={`w-4/5 flex pb-20 text-center items-center justify-center mx-auto ${ activeBlock === 'error' ? '' : 'hidden' }`}>
          <div>
            <div className="flex justify-center">
              <img src="/img/request-error.png" className="w-[300px] h-[300px]" />
            </div>
            <h1 className="text-main font-semibold text-[44px] mt-8">Ooops... Something get wrong!</h1>
            <div className="text-gray3 text-lg mt-1 mb-8">
              Please contact our support by email: <a href="mailto:support@charge-easy.ch" className="text-brandGreen underline hover:no-underline">support@charge-easy.ch</a>
            </div>
            <button 
              className="max-w-[360px] bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white"
              onClick={() => {
                  handleTabClick(activeForm);
                }}
              >
              Try Again
            </button>
          </div>
      </div>
		
    </div>
  );
};

export default NewRequest;
