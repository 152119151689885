import React, { useState, useEffect, useRef } from 'react';
import TaskTableRow from "./TaskTableRow";

type TaskTableProps = {
	title: string;
};

const TaskTable = ({
  title
}: React.PropsWithChildren<TaskTableProps>) => {
	const [rowCount, setRowCount] = useState(0);
	const [isOpen, setIsOpen] = useState(true);
	const tableRef = useRef<HTMLTableElement>(null);

	useEffect(() => {
		if (tableRef.current) {
		  	const tableRows = tableRef.current.querySelectorAll('tbody tr');
		  	setRowCount(tableRows.length);
		}
	}, []);

	const handleTaskExpand = () => {
	    setIsOpen(!isOpen);
	};

	return (
		<div>
			<div className="cursor-pointer w-full font-bold text-main text-lg tracking-[0.05em] uppercase flex place-items-center" onClick={handleTaskExpand}>
				<img src="/img/icons/arrow.svg" className={`h-2 w-2 mr-2 transition-all duration-300 ${isOpen ? 'rotate-90' : ''}`} />
				{title} ({rowCount})
			</div>
			<div className={`overflow-hidden transition-all duration-300 ${isOpen ? 'max-h-screen' : 'max-h-0'}`}>
				<table ref={tableRef} className="w-full text-xs mt-4">
					<thead>
					<tr className="text-gray3 border-b border-b-gray3 text-left">
				    	<th className="font-normal pb-4 px-3">Task Name</th>
				    	<th className="font-normal pb-4 px-3">Initiator & Date</th>
				    	<th className="font-normal pb-4 px-3 w-[10%]">Category</th>
				    	<th className="font-normal pb-4 px-3 w-[10%]">Status</th>
				    	<th className="font-normal pb-4 px-3 w-[15%]">Progress</th>
				    	<th className="font-normal pb-4 px-3 w-[10%]">Priority</th>
				    	<th className="font-normal pb-4 px-3">Assigness & Deadline</th>
					</tr>
					</thead>
					<tbody className="divide-y">
					
					<TaskTableRow title="" />
					<TaskTableRow title="" />
					<TaskTableRow title="" />
					<TaskTableRow title="" />

					
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default TaskTable;