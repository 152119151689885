import React from 'react';

const ContractorCard = () => (

	<div className="p-4 border-gray5 border rounded-lg w-1/4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
		<div className="uppercase text-xs text-main font-medium tracking-[0.05em] mb-3">contractor</div>

		<div className="mb-[1.125rem] text-xs text-gray2 font-normal space-y-1 leading-normal">
			<div>
				<span className="font-medium mr-1">User ID:</span> U123456
			</div>
			<div>
				<span className="font-medium mr-1">Person:</span> Jonathan Kelly
			</div>
			<div>
				<span className="font-medium mr-1">Telephone:</span> +41 76 890 12 34
			</div>
			<div>
				<span className="font-medium mr-1">E-mail:</span> example@gmail.com
			</div>
		</div>

		<div className="flex text-main font-semibold text-2xl place-items-top">
			<img src="/img/company-logo.png" className="w-4.5 max-h-6 mr-2 mt-1 object-contain" />
			Company Name
		</div>
	</div>

);

export default ContractorCard;