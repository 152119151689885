import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

interface AdminMenuProps {
  userName: string;
  onLogout: () => void;
}

export const AdminMenu = () => {
  	const location = useLocation();
  	const navigate = useNavigate();
  	const userName = localStorage.getItem('userName') || 'Guest';

  	const handleLogout = () => {
	    localStorage.removeItem('token');
    	localStorage.removeItem('userName');
	    navigate('/login');
	};

	return (
		<div className="w-[17rem] min-h-[600px] h-screen bg-white px-8 py-6 flex flex-col fixed">

			<div className="flex justify-between font-semibold text-lg">
				<a href="#">
					<img src="/img/logo.svg" className="w-[5.75rem]" />
				</a>
				<div>Admin</div>
			</div>
			
			<hr className="w-full h-px bg-gray5 mt-6" />

			<div className="my-8">
				<Link to="/admin/partners" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/partners') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/partners') ? 'font-semibold' : 'font-normal'}`}>Partners</div>
					</div>
				</Link>
				<Link to="/admin/orgs" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/orgs') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/orgs') ? 'font-semibold' : 'font-normal'}`}>Organizations</div>
					</div>
				</Link>
				<Link to="/admin/roles" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/roles') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/roles') ? 'font-semibold' : 'font-normal'}`}>Roles</div>
					</div>
				</Link>
				<Link to="/admin/users" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/users') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/users') ? 'font-semibold' : 'font-normal'}`}>Users</div>
					</div>
				</Link>
				<Link to="/admin/project-categories" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/project-categories') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/project-categories') ? 'font-semibold' : 'font-normal'}`}>Project Categories</div>
					</div>
				</Link>
				<Link to="/admin/project-phases" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/project-phases') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/project-phases') ? 'font-semibold' : 'font-normal'}`}>Project Phases</div>
					</div>
				</Link>
				<Link to="/admin/project-templates" className="group">
					<div className={`p-3 flex rounded ${location.pathname.includes('/admin/project-templates') ? 'bg-brandGreen' : 'group-hover:bg-gray7'}`}>
						<div className="w-[1.125rem] h-[1.125rem] mr-2"></div>
						<div className={`text-sm text-gray2 ${location.pathname.includes('/admin/project-templates') ? 'font-semibold' : 'font-normal'}`}>Project Templates</div>
					</div>
				</Link>
			</div>

			<div className="mt-auto">
				<a href="#" className="group">
					<div className="p-3 flex rounded group-hover:bg-gray7">
						<div className="w-[1.125rem] h-[1.125rem] mr-2">
							<svg className="w-full h-full stroke-[1.2px]" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M8.99939 11.3992C10.3242 11.3992 11.3982 10.3252 11.3982 9.00039C11.3982 7.67555 10.3242 6.60156 8.99939 6.60156C7.67457 6.60156 6.60059 7.67555 6.60059 9.00039C6.60059 10.3252 7.67457 11.3992 8.99939 11.3992Z" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M1.00391 9.7042V8.29689C1.00391 7.4653 1.68357 6.77764 2.52315 6.77764C3.97042 6.77764 4.56213 5.75414 3.83449 4.49875C3.4187 3.77911 3.66657 2.84356 4.39421 2.42777L5.77752 1.63616C6.4092 1.26034 7.2248 1.48423 7.60061 2.11592L7.68856 2.26785C8.4082 3.52323 9.59161 3.52323 10.3192 2.26785L10.4072 2.11592C10.783 1.48423 11.5986 1.26034 12.2303 1.63616L13.6136 2.42777C14.3412 2.84356 14.5891 3.77911 14.1733 4.49875C13.4457 5.75414 14.0374 6.77764 15.4847 6.77764C16.3163 6.77764 17.0039 7.4573 17.0039 8.29689V9.7042C17.0039 10.5358 16.3242 11.2235 15.4847 11.2235C14.0374 11.2235 13.4457 12.247 14.1733 13.5023C14.5891 14.23 14.3412 15.1575 13.6136 15.5733L12.2303 16.3649C11.5986 16.7408 10.783 16.5169 10.4072 15.8852L10.3192 15.7332C9.59961 14.4779 8.4162 14.4779 7.68856 15.7332L7.60061 15.8852C7.2248 16.5169 6.4092 16.7408 5.77752 16.3649L4.39421 15.5733C3.66657 15.1575 3.4187 14.222 3.83449 13.5023C4.56213 12.247 3.97042 11.2235 2.52315 11.2235C1.68357 11.2235 1.00391 10.5358 1.00391 9.7042Z" stroke="#54575B" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</div>
						<div className="text-sm font-normal text-gray2">Settings</div>
					</div>
				</a>

				<hr className="w-full h-px bg-gray5 my-4" />

				<div className="flex place-items-center w-full rounded justify-between">
					<a href="#" className="group flex place-items-center">
						<div className="text-xs font-medium text-gray2 max-w-[170px] overflow-hidden group-hover:font-semibold">{userName}</div>
					</a>

					<a href="#" onClick={handleLogout}>
						<div className="w-[1.125rem] h-[1.125rem] ml-auto">
							<svg className="w-full h-full stroke-1 hover:stroke-2" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M13.4277 11.1287L15.5077 9.04875L13.4277 6.96875" stroke="#54575B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M7.1875 9.04883H15.4507" stroke="#54575B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M8.81255 15.5C5.22127 15.5 2.3125 13.0625 2.3125 9C2.3125 4.9375 5.22127 2.5 8.81255 2.5" stroke="#54575B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</div>
					</a>
				</div>

			</div>
		</div>
  	);
};
