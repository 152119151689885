import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import FormGenerator from "../components/FormGenerator";

import { FieldConfig } from '../interfaces';

import { ProjectTemplate } from '../entities/ProjectTemplate';
import { ProjectCategory } from '../entities/ProjectCategory';
(window as any).ProjectCategory = ProjectCategory;


const ProjectTemplateForm = () => {

	const location = useLocation();
	const navigate = useNavigate();
  	const queryParams = new URLSearchParams(location.search);
  	const partnerId = queryParams.get('partnerId') ?? '';
	const numericId = undefined;

  	const [fields, setFields] = useState<Record<string, FieldConfig>>({
		id: { type: 'hidden' },
		partnerId: { type: 'hidden', value: partnerId },
		name_de: { label: 'Name (DE)', type: 'text', required: true },
		name_fr: { label: 'Name (FR)', type: 'text', required: true },
		name_it: { label: 'Name (IT)', type: 'text', required: true },
		name_rm: { label: 'Name (RM)', type: 'text', required: true },
		description_de: { label: 'Description (DE)', type: 'text' },
		description_fr: { label: 'Description (FR)', type: 'text' },
		description_it: { label: 'Description (IT)', type: 'text' },
		description_rm: { label: 'Description (RM)', type: 'text' },
		categoryId: { label: 'Category', type: 'select', required: true, options: {}, dataSource: 'ProjectCategory', dataFilter: [partnerId] },
	});

	const handleSubmit = async (values: any) => {
	    console.log('Form values:', values);

	    try {
        	const response = await ProjectTemplate.add({
				name_de: values.name_de,
				name_fr: values.name_fr,
				name_it: values.name_it,
				name_rm: values.name_rm,
				description_de: values.description_de,
				description_fr: values.description_fr,
				description_it: values.description_it,
				description_rm: values.description_rm,
				categoryId: values.categoryId,
				partnerId: values.partnerId,
			});
        	if (typeof response === 'string') {
		    	console.error('Error adding project template:', response);
		    } else {
			    navigate('/admin/project-templates?partnerId=' + values.partnerId);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
	    
	};

	return (
		<div className="px-8 py-3 w-full h-auto m-auto max-w-[1330px]">

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Project Template management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<Link to="/admin/project-templates" className="underline hover:no-underline">Project Templates</Link> / <span className="text-main">New Template</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-1/2 space-y-8">

							<h2 className="font-semibold">Create New Template</h2>

							<FormGenerator id={numericId} fields={fields} onSubmit={handleSubmit} />
							
						</div>

					</div>

				</div>

			</div>

		</div>
	)
};

export default ProjectTemplateForm;