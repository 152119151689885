import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import ConfirmModal from '../components/ui/ConfirmModal';

import { ProjectCategory } from '../entities/ProjectCategory';
import { Partner } from '../entities/Partner';

import { Select } from "../components/Select";
import { SelectOption, OptionItem } from "../types";

(window as any).Partner = Partner;


interface ColumnConfig {
  	label: string;
  	sortKey?: boolean;
  	entityClass?: string;
}

interface ProjectCategoriesConfig {
  	[key: string]: ColumnConfig;
}

interface ProjectCategoryData {
	id: string;
	name: string;
	partnerId: string;
}


const ProjectCategories = () => {
	const [sortOrder, setSortOrder] = useState('asc');
  	const [sortKey, setSortKey] = useState(null);
  	const [searchTerm, setSearchTerm] = useState('');
  	const [selectedRows, setSelectedRows] = useState<string[]>([]);
  	const [entityData, setEntityData] = useState<ProjectCategoryData[]>([]);
  	const [filteredData, setFilteredData] = useState<ProjectCategoryData[]>([]);
  	const [showConfirmModal, setShowConfirmModal] = useState(false);

  	const navigate = useNavigate();
  	const baseUrl = window.location.pathname;

  	// Filters
  	const [optionsPartner, setOptionsPartner] = useState<Record<string, string>>({});
  	const [selectedPartner, setSelectedPartner] = useState('');

  	// Entities Data
	const [entityValues, setEntityValues] = useState<Record<string, Record<string, string>>>({});

  	const columnConfig: ProjectCategoriesConfig = {
		name: { label: 'Name', sortKey: true },
		partnerId: { label: 'Partner', sortKey: true, entityClass: 'Partner' },
	};

	const loadEntityValues = async (entityClass: string) => {
	  	try {
	    	const dataSourceClass = (window as any)[entityClass];
	    	if (dataSourceClass && typeof dataSourceClass.getAll === 'function') {
	      		const response = await dataSourceClass.getAll();
	      		if (typeof response !== 'string') {
	        		const values = response.content.reduce((acc: Record<string, string>, item: any) => {
	          			acc[item.id] = item.name;
	          			return acc;
	        		}, {});
	        		setEntityValues((prevValues) => ({ ...prevValues, [entityClass]: values }));
	      		} else {
	        		console.error(`Error fetching ${entityClass} data:`, response);
	      		}
	    	} else {
	      		console.error(`Invalid entityClass or missing getAll method for ${entityClass}`);
	    	}
	  	} catch (error) {
	    	console.error(`Error fetching ${entityClass} data:`, error);
	  	}
	};

  	const handleSort = (key: any) => {
	    if (sortKey === key) {
	      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	    } else {
	      setSortKey(key);
	      setSortOrder('asc');
	    }
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
	    setSearchTerm(event.target.value);
	};

	const handleRowCheckboxChange = (id: string) => {
	  	setSelectedRows((prevSelectedRows) => {
		    if (prevSelectedRows.includes(id)) {
		      	return prevSelectedRows.filter((rowId) => rowId !== id);
		    } else {
		      	return [...prevSelectedRows, id];
		    }
	  	});
	};

	const handleHeaderCheckboxChange = () => {
	  	if (selectedRows.length === entityData.length) {
	   	 	setSelectedRows([]);
	  	} else {
	    	setSelectedRows(entityData.map((item) => item.id));
	  	}
	};

	const filterAndSortData = () => {
	    let data = [...entityData];

	    if (sortKey) {
	        data = data.sort((a, b) => {
	          	const valueA = a[sortKey as keyof ProjectCategoryData].toString().toLowerCase();
            	const valueB = b[sortKey as keyof ProjectCategoryData].toString().toLowerCase();

	          	if (valueA < valueB) {
	            	return sortOrder === 'asc' ? -1 : 1;
	          	}
	          	if (valueA > valueB) {
	            	return sortOrder === 'asc' ? 1 : -1;
	          	}
	          	return 0;
	        });
	    }

	    console.log("sortKey", sortKey);

	    data = data.filter((item) =>
	      	Object.values(item).some((value) =>
	       		value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	      	)
	    );

	    console.log("data", data);

	    setFilteredData(data);
	};

	const fetchProjectCategories = async () => {
      	try {
        	const response = await ProjectCategory.getAll('');
        	if (typeof response === 'string') {
		    	console.error('Error fetching category:', response);
		    } else {
		    	const categories = response.content;
			    setEntityData(categories);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
    };

    const fetchFilter = async () => {
    	try {
        	const response = await Partner.getAll();
        	if (typeof response === 'string') {
		    	console.error('Error fetching partners:', response);
		    } else {
		    	const partners = response.content;
		    	const optionsPartnerObj = partners.reduce((acc, option) => {
				  acc[option.id] = option.name;
				  return acc;
				}, { '0': '----' } as Record<string, string>);

			    setOptionsPartner(optionsPartnerObj);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
    };

    useEffect(() => {
	    fetchFilter();

  		const entityClasses = Object.values(columnConfig)
    		.filter((column) => column.entityClass)
    		.map((column) => column.entityClass!);

  		entityClasses.forEach((entityClass) => {
    		loadEntityValues(entityClass);
  		});
	}, []);

	useEffect(() => {
	  	fetchProjectCategories();
	}, []);

	useEffect(() => {
	    filterAndSortData();
	}, [sortKey, sortOrder, searchTerm, entityData]);

	useEffect(() => {
		if(selectedPartner === '0') {
			setFilteredData(entityData);
		} else {
		    const filteredList = entityData.filter(item => item.partnerId === selectedPartner);
		    setFilteredData(filteredList);
	   	}
	}, [selectedPartner]);

	const filterChange = (value: string, option: SelectOption) => {
		const selectedVal = option.value;
		setSelectedPartner(selectedVal);
	};

	const confirmDelete = async () => {
	  	for (const rowId of selectedRows) {
	    	try {
	      		console.log("delete = ", rowId);
	      		await ProjectCategory.delete(rowId);
	    	} catch (error) {
	      		console.error(`Error deleting project category ${rowId}:`, error);
	    	}
	  	}
	  	closeConfirmModal();
	};

	const handleDelete = async () => {
		if (selectedRows.length > 0) {
		    openConfirmModal();
		}
	};

	const openConfirmModal = () => {
	  	setShowConfirmModal(true);
	};

	const closeConfirmModal = () => {
		fetchProjectCategories();
		setSelectedRows([]);
	  	setShowConfirmModal(false);
	};

	return (
		<div className="px-8 py-3 w-full h-auto min-h-screen m-auto max-w-[1330px]">
			<ConfirmModal
		        isOpen={showConfirmModal}
		        onConfirm={confirmDelete}
		        onCancel={closeConfirmModal}
		    />

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Project Category management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<span className="text-main">Project Categories</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						{/* Filters */}
						<div className="w-full flex mb-10 justify-between gap-8">
							<div className="w-1/3 relative h-10">
								<Select
				                    id="partner"
				                    placeholder={`Choose partner`}
				                    value={selectedPartner}
				                    options={optionsPartner}
				                    name="partner"
				                    onSelected={filterChange} 
				                />
							</div>

							<div className="h-10 flex gap-8">
								<button 
									className="bg-red-300 px-6 h-12 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] disabled:bg-gray4" 
									disabled={selectedRows.length === 0}
									onClick={handleDelete}
								>
									Delete
								</button>
								<Link to={`${baseUrl}/add`} className="bg-brandGreen px-6 h-12 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Category</Link>
							</div>
						</div>

						<table className="w-full text-xs">
	  						<thead>
								<tr className="text-gray3 border-b border-b-gray3 text-left">
									<th className="px-2 pb-4 w-10">
										<input 
											type="checkbox" 
									        checked={selectedRows.length === entityData.length}
									        onChange={handleHeaderCheckboxChange}
										/>
									</th>
							    {Object.entries(columnConfig).map(([key, config]) => (
				                    <th
				                      key={key}
				                      className={`font-normal pb-4 ${config.sortKey ? 'cursor-pointer hover:font-semibold' : ''}`}
				                      onClick={() => config.sortKey && handleSort(key)}
				                    >
				                      {config.label}
				                      {config.sortKey && sortKey === key && (
				                        <span className="ml-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>
				                      )}
				                    </th>
				                ))}
								</tr>
							</thead>
	  						<tbody>
							{filteredData.map((item) => (
			                  <tr
							    key={item.id}
							    className={`text-main border-b border-b-gray4 cursor-pointer hover:bg-green8 ${
							        selectedRows.includes(item.id) ? 'bg-gray6' : ''
							    }`}
							  >
			                  	<td className="px-2 w-10">
									<input 
										type="checkbox" 
										id={`item_${item.id}`}
								        checked={selectedRows.includes(item.id)}
								        onChange={() => handleRowCheckboxChange(item.id)}
									/>
								</td>
								{Object.entries(columnConfig).map(([key, config], index) => (
								  <td 
								  	key={`${item.id}-${key}-${index}`} 
								  	className="py-4"
							    	onClick={() => navigate(`${baseUrl}/edit/${item.id}`)}
								  >
								    {config.entityClass && entityValues[config.entityClass]
								      ? entityValues[config.entityClass][(item as any)[key]] || (item as any)[key]
								      : (item as any)[key]}
								  </td>
								))}
			                  </tr>
			                ))}
							</tbody>
	  					</table>
					</div>

				</div>

			</div>

		</div>
	)
};

export default ProjectCategories;