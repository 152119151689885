import { apiHandler, ApiHandler, ApiError } from '../api';

type Categories = { [key: string]: string };

interface FileData {
  id: string;
  file: File;
  category: string;
}


export class Request {
  id?: string;
  requestNumber?: string;
  propertyId: string;
  categoryId: string;
  userId?: string;
  comment: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  files: {}[] = [];

  constructor(
    propertyId: string,
    categoryId: string,
    comment: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
  ) {
    this.propertyId = propertyId;
    this.categoryId = categoryId;
    this.comment = comment;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phoneNumber = phoneNumber;
  }

  async createRequest(
    attachments?: FileData[]
  ): Promise<string | null> {

    try {
      const data = await apiHandler.post<any>(`/public/anonymous-users`);
      this.userId = data.id;

      if(this.userId && attachments && attachments.length) {

        for (let i = 0; i < attachments.length; i++) {
          const fileData = attachments[i];

          const formData = new FormData();
          formData.append('file', fileData.file);
          formData.append('userId', this.userId);
          formData.append('type', fileData.file.type.includes('image') ? 'image' : 'file');

          const fileCategory = fileData.category;

          try {
            const response = await apiHandler.post<{ id: string }>(
              '/public/assets',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            const fileId = response.id;
            this.files.push({ assetId: fileId, type: fileCategory });

          } catch (error) {
            if (error instanceof ApiError) {
              console.error('Error uploading file:', error.message);
              throw new Error(error.message);
            } else {
              console.error('Unexpected error:', error);
              throw error;
            }
          }
        }
      }

      if(this.userId) {

        try {
          const fields = {
            userId: this.userId,
            propertyId: this.propertyId,
            categoryId: this.categoryId,
            comment: this.comment,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: this.phoneNumber,
            attachments: this.files,
          };

          console.log("fields = " + JSON.stringify(fields, null, 2));
          const formData = JSON.stringify(fields);

          console.log("formData", formData);

          const response = await apiHandler.post<{ id: string, requestNumber: string }>(
            '/public/requests',
            formData
          );

          this.id = response.id;
          this.requestNumber = response.requestNumber;

          return this.requestNumber;
          // throw new Error('error.message');

         } catch(error) {
           if (error instanceof ApiError) {
              console.error('Error creating request:', error.message);
              throw new Error(error.message);
            } else {
              console.error('Unexpected error:', error);
              throw error;
            }
         }
      }

    } catch (error: any) {
      return null;
    }

    return null;

  }
}
