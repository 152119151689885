import React from 'react';

const ProjectProperty = () => (

	<div>
		<h2 className="uppercase text-main font-bold text-lg tracking-[0.05em] mb-4 mt-6">Property Information</h2>

		<div className="flex w-full p-6 border-gray5 border rounded-lg space-x-6 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">

			<div className="w-1/2 space-y-4">

				<div className="flex place-items-center">
					<div className="w-16 h-16 mr-4 rounded-full border-4 border-white drop-shadow-[0_2px_8px_rgba(0,0,0,0.1)]">
						<img src="/img/avatar.png" className="w-full h-full" />
					</div>
					<div>
						<div className="text-main font-semibold text-lg">Jonathan Kelly</div>
						<div className="text-gray3 font-normal text-base">Owner</div>
					</div>
				</div>

				<div className="flex flex-wrap justify-between gap-4 min-[1400px]:grid min-[1400px]:grid-cols-12">
					<div className="space-y-2 min-[1400px]:col-span-6">
  						<div className="text-gray3 font-normal text-sm leading-normal">Property ID</div>
  						<div className="text-main font-medium text-base leading-normal">498496</div>
					</div>
					<div className="space-y-2 min-[1400px]:col-span-6">
  						<div className="text-gray3 font-normal text-sm leading-normal">Category</div>
  						<div className="text-main font-medium text-base leading-normal">Mehfamilienhauis</div>
					</div>
					<div className="space-y-2 min-[1400px]:col-span-12">
  						<div className="text-gray3 font-normal text-sm leading-normal">Partner / Company</div>
  						<div className="text-main font-medium text-base flex leading-normal">
  							<img src="/img/wincasa-logo.png" className="w-6 h-6 mr-2" />
  							Wincasa AG
  						</div>
					</div>
					<div className="space-y-2 min-[1400px]:col-span-12">
  						<div className="text-gray3 font-normal text-sm leading-normal">House name</div>
  						<div className="text-main font-medium text-base leading-normal">Immobilien AG</div>
					</div>
					<div className="space-y-2 min-[1400px]:col-span-12">
  						<div className="text-gray3 font-normal text-sm leading-normal">Kanton</div>
  						<div className="text-main font-medium text-base leading-normal">ZG</div>
					</div>
		            <div className="space-y-2 min-w-[180px] min-[1400px]:col-span-12">
						<div className="text-gray3 font-normal text-sm leading-normal">Address</div>
						<div className="text-main font-medium text-base leading-normal">🇨🇭 Switzerland, Zug, Finanzverkehr Interswiss</div>
		            </div>
				</div>

			</div>

			{/* Google Maps */}
			<div className="w-1/2 rounded-lg max-h-[420px] overflow-hidden">
				<img src="/img/map-2.jpg" className="w-full" />
			</div>

		</div>

		<h2 className="uppercase text-main font-bold text-lg tracking-[0.05em] mb-4 mt-6">Contacts</h2>

		<div className="flex space-x-6 mb-6">
			<div className="flex grid grid-cols-12 gap-4 p-6 border-gray5 border rounded-lg w-1/2 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="uppercase text-lg text-main font-semibold tracking-[0.05em] col-span-12">PM-O</div>
				<div className="flex flex-col gap-2 col-span-12">
					<div className="text-gray3 font-normal text-sm">PM-O Name</div>
					<div className="text-main font-medium text-base flex">
						<img src="/img/avatar.png" className="w-6 h-6 rounded-full mr-2" />
						William Brown
					</div>
				</div>
				<div className="flex flex-col gap-2 col-span-8">
					<div className="text-gray3 font-normal text-sm">E-mail</div>
					<div className="text-main font-medium text-base flex">w.brown@charge-easy.ch</div>
				</div>
				<div className="flex flex-col gap-2 col-span-4">
					<div className="text-gray3 font-normal text-sm">Telephone</div>
					<div className="text-main font-medium text-base flex">+41 76 456 78 90</div>
				</div>
			</div>

			<div className="flex grid grid-cols-12 gap-4 p-6 border-gray5 border rounded-lg w-1/2 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="uppercase text-lg text-main font-semibold tracking-[0.05em] col-span-12">Supervisor</div>
				<div className="flex flex-col gap-2 col-span-12">
					<div className="text-gray3 font-normal text-sm">Supervisor Name</div>
					<div className="text-main font-medium text-base flex">
						<img src="/img/avatar-2.png" className="w-6 h-6 rounded-full mr-2" />
						Mia Harris
					</div>
				</div>
				<div className="flex flex-col gap-2 col-span-8">
					<div className="text-gray3 font-normal text-sm">E-mail</div>
					<div className="text-main font-medium text-base flex">m.harris@charge-easy.ch</div>
				</div>
				<div className="flex flex-col gap-2 col-span-4">
					<div className="text-gray3 font-normal text-sm">Telephone</div>
					<div className="text-main font-medium text-base flex">+41 78 012 34 56</div>
				</div>
			</div>
		</div>

	</div>
);

export default ProjectProperty;