import React, { useState, useLayoutEffect, useRef } from 'react';
import TaskTable from "./TaskTable";

type TabType = 'table-view' | 'gantt-view';

type TaskTable = {
  title: string;
  id: string;
};

type TaskBlockProps = {
	tables: TaskTable[];
};

const months = [
	{ num: 5, name: 'May', days: 31 },
	{ num: 6, name: 'June', days: 30 },
];

const taskList = [
	{
		id: 1,
		title: 'Task 1',
		startDate: '2024-05-22',
		endDate: '2024-06-01',
		progress: 50,
		type: 1,
		assignees: 2,
		subtasks: [
			{
				id: 11,
				title: 'Subtask 1.1',
				startDate: '2024-05-22',
				endDate: '2024-05-25',
				progress: 100,
				type: 1,
				assignees: 1
			},
			{
				id: 12,
				title: 'Subtask 1.2',
				startDate: '2024-05-26',
				endDate: '2024-05-29',
				progress: 35,
				type: 0,
				assignees: 1
			},
			{
				id: 13,
				title: 'Subtask 1.3',
				startDate: '2024-05-30',
				endDate: '2024-06-01',
				progress: 0,
				type: 0,
				assignees: 1
			}
		]
	},
	{
		id: 2,
		title: 'Task 2',
		startDate: '2024-05-25',
		endDate: '2024-06-02',
		progress: 65,
		type: 1,
		assignees: 1,
		subtasks: []
	},
	{
		id: 3,
		title: 'Task 3',
		startDate: '2024-05-24',
		endDate: '2024-06-08',
		progress: 20,
		type: 1,
		assignees: 1,
		subtasks: []
	},
	{
		id: 4,
		title: 'Task 4',
		startDate: '2024-05-27',
		endDate: '2024-06-07',
		progress: 55,
		type: 1,
		assignees: 3,
		subtasks: []
	}
];

const TaskBlock = ({ tables }: React.PropsWithChildren<TaskBlockProps>) => {

	const [activeTab, setActiveTab] = useState<TabType>('table-view');
  	const [showView, setShowView] = useState('table-view');
  	const tableRef = useRef<HTMLTableElement>(null);
  	const containerRef = useRef<HTMLDivElement>(null);
  	const [ganttBodyHeight, setGanttBodyHeight] = useState(0);
  	const [ganttHeadHeight, setGanttHeadHeight] = useState(0);
  	const [todayCellLeft, setTodayCellLeft] = useState(0);

  	const handleTabClick = (tab: TabType) => {
	    setActiveTab(tab);
	    setShowView(tab);
	};

	const topStyle = `top-[${ganttHeadHeight}px]`;

	const getCellSize = () => {
		const firstCell = tableRef.current?.querySelector('tbody tr:first-child td:first-child') as HTMLTableCellElement | null;

		if(firstCell) {
			const cellWidth = firstCell.offsetWidth;
			const cellHeight = firstCell.offsetHeight;

			return { cellWidth: cellWidth, cellHeight: cellHeight };
		}

		return { cellWidth: 0, cellHeight: 0 };
	};

	const getCellCoord = (month: number, day: number, taskId: string) => {
		const cellId = `${taskId}-${month}-${day}`;
		const cell = tableRef.current?.querySelector(`td[id="${cellId}"]`) as HTMLTableCellElement | null;

		if (cell && cell.parentNode) {
			const {cellWidth, cellHeight} = getCellSize();
		    const cellIndex = Array.from(cell.parentNode.children).indexOf(cell);
		    const cellLeft = cellIndex * cellWidth + cellWidth / 2;
		    const cellTop = cell.offsetTop + cellHeight / 4;

		    return [cellLeft, cellTop ];
		}
		return [0, 0];
	};

	const calculateLengthBetweenDates = (startDate: string, endDate: string) => {
        const start = new Date(startDate).getTime();
        const end = new Date(endDate).getTime();
        const differenceInTime = end - start;
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);

        const {cellWidth, cellHeight} = getCellSize();

        // console.log(differenceInDays + ' == ' + cellWidth);

        return differenceInDays * cellWidth;
    };

	useLayoutEffect(() => {
		const tableContainer = containerRef.current;
	    const table = tableRef.current;
	    const thead = table?.querySelector('thead');

	    if (thead && table && tableContainer) {
	        const tableHeight = table.offsetHeight;
	        const theadHeight = thead.offsetHeight;
	        const tbodyHeight = tableHeight - theadHeight;

		    setGanttBodyHeight(tbodyHeight+4);
		    setGanttHeadHeight(theadHeight-4);


		    const today = new Date();
    		const currentMonth = today.getMonth() + 1;
    		const currentDay = today.getDate();

    		const todayCellId = `${currentMonth}-${currentDay}`;
    		const todayCell = tableRef.current?.querySelector(`td[id="${todayCellId}"]`) as HTMLTableCellElement | null;

    		if (todayCell && todayCell.parentNode) {
		      	const cellWidth = todayCell.offsetWidth;
		      	const cellIndex = Array.from(todayCell.parentNode.children).indexOf(todayCell);
		      	const cellLeft = cellIndex * cellWidth + cellWidth / 2;
		      	setTodayCellLeft(cellLeft);

		      	tableContainer.scrollLeft = cellLeft - tableContainer.offsetWidth / 2;
		    }
    	}

	}, [showView == 'gantt-view']);


	return (
		<>
			{/* Tabs */}
			<div className="mt-5 w-full border-b-gray5 border-b text-sm text-gray3">
				<div
					className={`py-3 px-4 inline-block ${
	                    activeTab === 'table-view'
	                      ? 'font-semibold text-main border-b-2 border-b-main'
	                      : 'cursor-pointer hover:text-main'
	                }`}
					onClick={() => handleTabClick('table-view')}
				>
					Table
				</div>
				<div 
					className={`py-3 px-4 inline-block ${
	                    activeTab === 'gantt-view'
	                      ? 'font-semibold text-main border-b-2 border-b-main'
	                      : 'cursor-pointer hover:text-main'
	                }`}
					onClick={() => handleTabClick('gantt-view')}
				>
					Gantt Chart
				</div>
			</div>

			<div className={`space-y-8 ${ showView === 'gantt-view' ? 'hidden' : '' }`}>
				{tables.map((block) => (
			    	<TaskTable title={block.title} />
			    ))}
		    </div>

		    <div className={`${ showView === 'table-view' ? 'hidden' : '' }`}>

		    	<div className="w-full border border-gray5 rounded-md flex flex-row">

		    		<div className="w-60 min-w-60 border-r border-r-gray5 divide-y text-main text-sm">
		    			<div className="w-full px-3 py-6 text-gray3 text-xs">Task Name</div>

		    			{taskList.map((task) => (
					        <>
					            <div id={`task${task.id}`} className="py-6 px-3 font-semibold h-[69px]">
					                <div className="flex place-items-center">
					                    <img src="/img/icons/arrow.svg" className="h-2 w-1.5 mr-3 cursor-pointer rotate-90" />
					                    {task.title}
					                    <img src="/img/icons/hint.svg" className="h-3 w-3 ml-2 cursor-pointer" />
					                </div>
					            </div>

					            {task.subtasks && task.subtasks.length > 0 && (
					                task.subtasks.map((subtask) => (
					                    <div id={`subtask${subtask.id}`} className="py-6 px-3 pl-7 h-[69px]">
					                        <div className="flex place-items-center">
					                            → {subtask.title}
					                            <img src="/img/icons/hint.svg" className="h-3 w-3 ml-2 cursor-pointer" />
					                        </div>
					                    </div>
					                ))
					            )}
					        </>
					    ))}
		    			
		    		</div>

		    		<div className="flex-grow overflow-y-hidden" ref={containerRef}>
		    			<div className="relative" ref={tableRef}>

		    				{/*Tasks & Lines*/}

		    				{taskList.map((task) => {

		    					const [year, month, day] = task.startDate.split('-').map(Number);
                				const [ left, top ] = getCellCoord(month, day, 'task' + task.id);
                				const lengthBetween = calculateLengthBetweenDates(task.startDate, task.endDate);

                				return (
							        <>
							            <div className="absolute flex space-x-3 z-1" style={{ left: `${left}px`, top: `${top}px` }}>
						    				<div className={`h-8 rounded-2xl p-2 flex space-x-2 justify-between ${task.type ? 'bg-green7' : 'bg-blue4'}`} style={{ minWidth: `${lengthBetween}px` }}>
						    					<div className={`rounded-4xl h-full ${task.type ? 'bg-brandGreen' : 'bg-blue1'}`} style={{ width: `${task.progress}%` }}></div>
						    					<div className="bg-main rounded-2xl h-full text-white text-xs px-3 font-semibold leading-normal">{task.progress}%</div>
						    				</div>
						    				<div className="flex items-center" style={{ width: `100px` }}>
							    				{Array.from({ length: task.assignees }, (_, i) => (
					    							<img src="/img/avatar.png" className="w-7 h-7 rounded-full border border-[2px] border-white -ml-2" style={{ zIndex: task.assignees - i }} />
					    						))}
						    				</div>
					    				</div>

							            {task.subtasks && task.subtasks.length > 0 && (
							                task.subtasks.map((subtask) => {

							                	const [subtaskYear, subtaskMonth, subtaskDay] = subtask.startDate.split('-').map(Number);
							                	const [ subtaskLeft, subtaskTop ] = getCellCoord(subtaskMonth, subtaskDay, 'subtask' + subtask.id);
							                	const subtaskLengthBetween = calculateLengthBetweenDates(subtask.startDate, subtask.endDate);

							                	return (
								                    <div className="absolute flex space-x-3 z-1" style={{ left: `${subtaskLeft}px`, top: `${subtaskTop}px` }}>
									    				<div className={`h-8 rounded-2xl p-2 flex space-x-2 justify-between ${subtask.type ? 'bg-green7' : 'bg-blue4'}`} style={{ minWidth: `${subtaskLengthBetween}px` }}>
									    					<div className={`rounded-4xl h-full ${subtask.type ? 'bg-brandGreen' : 'bg-blue1'}`} style={{ width: `${subtask.progress}%` }}></div>
									    					<div className="bg-main rounded-2xl h-full text-white text-xs px-3 font-semibold leading-normal">{subtask.progress}%</div>
									    				</div>
									    				<div className="flex items-center" style={{ width: `100px` }}>
										    				{Array.from({ length: subtask.assignees }, (_, i) => (
								    							<img src="/img/avatar.png" className="w-7 h-7 rounded-full border border-[2px] border-white -ml-2" style={{ zIndex: subtask.assignees - i }} />
								    						))}
									    				</div>
								    				</div>
								    			);
							                })
							            )}
							        </>
							    );
						    })}

		    				<div className="absolute z-100" style={{ top: `${ganttHeadHeight}px`, left: `${todayCellLeft}px` }}>
		    					<div 
		    						style={{ height: `${ganttBodyHeight}px`}}
		    						className={`w-0.5 bg-green6 before:w-[9px] before:h-[9px] before:bg-green6 before:content-[''] before:absolute before:top-0 before:-left-1 before:rotate-45 after:w-[9px] after:h-[9px] after:bg-green6 after:content-[''] after:absolute after:bottom-0 after:-left-1 after:rotate-45`}
		    					></div>
		    				</div>


			    			<table 
			    				className="border-collapse border-0 calendar -mt-[1px] -mb-[1px]" 
			    			>
			    				<thead>
			    				<tr>
			    					{months.map((month) => (
								    	<td colSpan={month.days} className="text-xs text-main font-bold p-2 text-center">{month.name}</td>
								    ))}
			    				</tr>
			    				<tr className="text-xs text-gray3">
			    					{months.map((month) => (
			    						Array.from({ length: month.days }, (_, i) => (
			    							<td className="w-10 min-w-10 h-8 text-center" id={month.num + '-' + (i+1)}>{i+1}</td>
			    						))
			    					))}
			    				</tr>
			    				</thead>
			    				<tbody>
			    				{taskList.map((task) => (
							        <>
							            <tr className="h-[69px]">
					    					{months.map((month) => (
					    						Array.from({ length: month.days }, (_, i) => (
					    							<td id={'task' + task.id + '-' + month.num + '-' + (i+1)}></td>
					    						))
					    					))}
					    				</tr>

							            {task.subtasks && task.subtasks.length > 0 && (
							                task.subtasks.map((subtask) => (
							                    <tr className="h-[69px]">
							    					{months.map((month) => (
							    						Array.from({ length: month.days }, (_, i) => (
							    							<td id={'subtask' + subtask.id + '-' + month.num + '-' + (i+1)}></td>
							    						))
							    					))}
							    				</tr>
							                ))
							            )}
							        </>
							    ))}
			    				</tbody>
			    			</table>
			    		</div>
		    		</div>

		    	</div>

		    </div>

		</>
	);
};

export default TaskBlock;