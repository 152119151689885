import { makeAutoObservable } from "mobx";
import UserStore from "./userStore";

class RootStore {
	userStore: UserStore;

  	constructor() {
    	makeAutoObservable(this);
    	this.userStore = new UserStore(this);
  	}
}

const store = new RootStore();
export default store;