import React from 'react';

const ProjectLog = () => (

	<div className="p-6 mt-6 border-gray5 border rounded-lg space-y-4 bg-white hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
		<h3 className="font-semibold text-main text-lg">Project Log</h3>

		<table className="w-full text-xs">
			<thead>
			<tr className="text-gray3 border-b border-b-gray3 text-left">
		    	<th className="font-normal pb-4 min-w-4">No.</th>
		    	<th className="font-normal pb-4">Name</th>
		    	<th className="font-normal pb-4 w-[60%]">Action</th>
		    	<th className="font-normal pb-4">Date</th>
		    	<th className="font-normal pb-4">Time</th>
			</tr>
		</thead>
			<tbody>
			<tr className="text-main border-b border-b-gray4">
		    	<td className="py-4">1.</td>
		    	<td className="flex py-4">
		    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
		    		Ammar Foley
		    	</td>
		    	<td>Action Name</td>
		    	<td>08/05/2024</td>
		    	<td>9:00</td>
			</tr>
			<tr className="text-main border-b border-b-gray4">
		    	<td className="py-4">2.</td>
		    	<td className="flex py-4">
		    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
		    		Ammar Foley
		    	</td>
		    	<td>Action Name</td>
		    	<td>01/05/2024</td>
		    	<td>10:40</td>
			</tr>
			<tr className="text-main border-b border-b-gray4">
		    	<td className="py-4">3.</td>
		    	<td className="flex py-4">
		    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
		    		Ammar Foley
		    	</td>
		    	<td>Action Name</td>
		    	<td>01/05/2024</td>
		    	<td>10:40</td>
			</tr>
			<tr className="text-main">
		    	<td className="py-4">4.</td>
		    	<td className="flex py-4">
		    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
		    		Ammar Foley
		    	</td>
		    	<td>Action Name</td>
		    	<td>01/05/2024</td>
		    	<td>10:40</td>
			</tr>
		</tbody>
		</table>
	</div>

);

export default ProjectLog;