import React, { useState, useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

import { User } from '../../entities/User';

interface PrivateRouteProps {
  children: React.ReactNode;
  layout: React.ComponentType<{ children: React.ReactNode }>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, layout: Layout }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const refreshToken = User.getCookieValue('refreshToken');
          // console.log("checkAuthentication refreshToken = ", refreshToken);

        if (refreshToken) {
          const { accessToken } = await fetchNewAccessToken(refreshToken);

          if(accessToken) {
            localStorage.setItem('token', accessToken);
            setIsAuthenticated(true);
          } else {
            navigate('/login');
          }

        } else {
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
        navigate('/login');
      } finally {
        setIsLoading(false);
      }
    };

    checkAuthentication();
  }, [navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Layout>{children}</Layout> : <Navigate to="/login" />;
};

async function fetchNewAccessToken(refreshToken: string) {
	const { token, newRefreshToken } = await User.refreshAuthToken(refreshToken);
  	return { accessToken: token };
}

export default PrivateRoute;