import React, { ReactNode } from 'react';

import Header from './Header';

import './MainLayout.css'; 

interface RequestLayoutProps {
	children: ReactNode;
}

const RequestLayout: React.FC<RequestLayoutProps> = ({ children }) => (
	<div className="flex flex-col w-full bg-gray7 min-h-screen">
		<Header />
		<div className="w-full flex flex-1">
			{children}
		</div>
	</div>
);

export default RequestLayout;