import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

import { Sort, Pageable, ApiResponse, SelectResponse } from '../interfaces';


export class Partner {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }

  static async getById(id: string): Promise<Partner> {
    const data = await apiHandler.get<any>(`/partners?id=${id}`);
    return new Partner(data.id, data.name);
  }

  static async add(name: string): Promise<Partner | string> {

    const params = {
      "name": name
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    }

    try {
      const data = await apiHandler.post<Partner>(`/partners`, params, config);
      return data;
    } catch (error: any) {
      return 'An error occurred while adding the partner';
    }

    return '';
  }

  static async getAll(): Promise<ApiResponse<Partner> | string> {

    const accessToken = localStorage.getItem('token');

    const params = {
      "page": 0,
      "size": 1000,
      "sort": []
    };

    const config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
    }

    try {
      const data = await apiHandler.get<ApiResponse<Partner>>(`/partners?${ApiHandler.getQueryString(params)}`, config);
      return data;
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Partners not found';
      } else {
        return 'An error occurred while fetching the partners';
      }
    }
  }

  static async getForSelect(): Promise<SelectResponse[] | string> {
    const response = await Partner.getAll();

    if (typeof response === 'string') {
      return response;
    }

    const selectResponses: SelectResponse[] = response.content;
    return selectResponses;
  }

}