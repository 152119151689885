import { ChangeEventHandler, useState } from "react";

type ChecklistProps = {
  id?: string | undefined;
  name?: string;
  options: Record<string, string>;
  value: string[] | undefined;
  error?: string;
  className?: string;
  onChange?: (value: string[]) => void;
};

export const Checklist = ({
  id,
  name,
  options,
  value = [],
  error,
  className,
  onChange,
}: React.PropsWithChildren<ChecklistProps>) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(value || []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value: optionValue, checked } = e.target;
    const newValue = checked
      ? [...checkedValues, optionValue]
      : checkedValues.filter((v) => v !== optionValue);

    setCheckedValues(newValue);
    onChange && onChange(newValue);
  };

  return (
    <div className="w-full">
      <div className={`flex flex-col space-y-2 ${className}`}>
        {Object.entries(options).map(([optionValue, optionLabel]) => (
          <div key={optionValue} className="flex items-center">
            <input
              id={`${id}-${optionValue}`}
              name={`${name}[]`}
              type="checkbox"
              value={optionValue}
              checked={Array.isArray(checkedValues) && checkedValues.includes(optionValue)}
              onChange={handleChange}
              className="mr-2"
            />
            <label htmlFor={`${id}-${optionValue}`} className="text-sm">{optionLabel}</label>
          </div>
        ))}
      </div>
      <div className={`text-ceRed text-xs mt-2 ${error ? '' : 'hidden'}`}>
        {error ? error : ''}
      </div>
    </div>
  );
};
