import React, { ChangeEventHandler, useState, useEffect, useRef } from "react";

import { FormikErrors } from "formik"; 

import { Select } from "../components/Select";
import { SelectOption, OptionItem } from "../types";


type FileWithCategoryInputProps = {
  id?: string | undefined;
  name?: string;
  multiple?: boolean;
  accept?: string;
  placeholder?: string;
  value?: string;
  className?: string;
  onChange?: (fileData: FileData[]) => void;
  resetKey?: string;
  categories: Record<string, string>;
  errors?: FormikErrors<FileData>[];
  disabled?: boolean;
};

interface FileData {
  id: string;
  file: File;
  category: string;
}

export const FileWithCategoryInput = ({
  id,
  name,
  multiple = false,
  accept,
  placeholder,
  value,
  className,
  onChange,
  resetKey,
  categories,
  errors,
  disabled = false,
}: React.PropsWithChildren<FileWithCategoryInputProps>) => {
  const [files, setFiles] = useState<FileData[]>([]);
  const dropAreaRef = useRef<HTMLLabelElement>(null);


  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedFiles = e.target.files;

    if (!selectedFiles) {
      return;
    }

    const newFilesArray = Array.from(selectedFiles).map(file => ({
      id: `file_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      file,
      category: "",
    }));

    setFiles(prevFiles => [...prevFiles, ...newFilesArray]);
    onChange?.([...files, ...newFilesArray]);
  };

  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const droppedFiles = Array.from(e.dataTransfer.files).map(file => ({
      id: `file_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      file,
      category: "",
    }));

    setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
    onChange?.([...files, ...droppedFiles]);
  };

  const removeFile = (fileToRemove: FileData) => {
    const updatedFiles = files.filter(file => file.id !== fileToRemove.id);
    setFiles(updatedFiles);
    onChange?.(updatedFiles);
  };

  const handleCategoryChange = (fileKey: string, option: SelectOption) => {
    const updatedFiles = files.map(file => 
      file.id === fileKey ? { ...file, category: option.value } : file
    );
    setFiles(updatedFiles);
    onChange?.(updatedFiles);
  };

  useEffect(() => {
    setFiles([]);
  }, [resetKey]);

  useEffect(() => {
    const dropArea = dropAreaRef.current;

    if (dropArea) {
      dropArea.addEventListener("dragover", handleDragOver as unknown as EventListener);
      dropArea.addEventListener("drop", handleDrop as unknown as EventListener);
    }

    return () => {
      if (dropArea) {
        dropArea.removeEventListener("dragover", handleDragOver as unknown as EventListener);
        dropArea.removeEventListener("drop", handleDrop as unknown as EventListener);
      }
    };
  }, []);


  return (
    <div>
      <label 
        htmlFor={id} 
        className={`w-full block border rounded-lg text-sm text-gray3 p-3 text-center ${className} ${disabled ? 'bg-gray6' : ''}`}
        ref={dropAreaRef}
      >
        <input
          id={id}
          name={name}
          type="file"
          multiple={multiple}
          accept={accept}
          className="hidden"
          disabled={disabled}
          onChange={handleFileChange}
        />
        <div className="flex justify-center">
          <img src="/img/uploadfiles.svg" className="w-[60px] h-[60px] mb-2" />
        </div>
        <p className="text-gray3 text-sm">Drag and drop your file here<br/>OR</p>
        <div className="text-main text-sm font-medium p-2 mt-4 border border-gray4 w-[360px] rounded-lg mx-auto drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)]  ${disabled ? '' : 'cursor-pointer hover:border-brandGreen'}">Select files</div>
      </label>
      {files.length > 0 && (
        <div className="flex w-full flex-col gap-y-4 mt-4">
          {files.map((fileData, index) => (
            <div key={fileData.id}>
              <div
                className="border border-gray5 rounded-lg p-3 flex items-center text-gray3 text-sm justify-between gap-2"
               >
                <div className="w-1/2 overflow-hidden">{fileData.file.name}</div>
                <div className="w-1/2 flex gap-2">
                  <Select
                    id={fileData.id}
                    value={fileData.category}
                    options={categories}
                    name={fileData.id}
                    onSelected={handleCategoryChange}
                  />
                  <button
                    className="text-red-500 hover:text-red-700"
                    onClick={() => removeFile(fileData)}
                  >
                    X
                  </button>
                </div>
              </div>
              {errors && errors[index] && errors[index].category && (
                <div className="text-ceRed text-xs mt-2 text-right">{errors[index].category}</div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
