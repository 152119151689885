import React, { useState } from 'react';
import { Input } from "../components/Input";
import { PasswordInput } from "../components/PasswordInput";
import { useFormik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';


const validationSchema = Yup.object().shape({
  	Email: Yup.string()
                .email('E-mail wrong format')
                .required('E-mail is required'),
    Password: Yup.string()
    			.required('Password is required'),
});

interface FormValues {
  Email: string;
  Password: string;
};

const initialValues: FormValues = {
  Email: '',
  Password: '',
};

interface RequestProps {
  formik: FormikProps<FormValues>;
}

const Login = () => {

	const [activeBlock, setActiveBlock] = useState('login');

	const switchBlocks = (tab: string) => {
	    console.log(tab);
	    setActiveBlock(tab);
	};

	const handleSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>) => {
	    try {
	      const errors = await formik.validateForm();

	      if (Object.keys(errors).length === 0) {
	        console.log('Submitted values:', formik.values);
	      }

	      formik.handleSubmit();
	    } catch (error) {
	      console.error('Error fetching property by number in component:', error);
	    }
	};

	const formik: FormikProps<FormValues> = useFormik<FormValues>({
	    initialValues,
	    validationSchema,
	    onSubmit: handleSubmit,
	});


  	return (
	  	<div className="px-9 pt-6 pb-10 w-full flex flex-1">

	      	<form className={`w-1/2 flex justify-center ${activeBlock === 'login' ? '' : 'hidden'}`} onSubmit={formik.handleSubmit}>
		        <div className="flex items-center">
		           <div className="text-center">
		               <h1 className="text-main font-semibold text-2xl">Log In</h1>
		               <div className="text-gray3 text-sm mt-2">Welcome.</div>

		                <div className="my-12 space-y-4 w-[360px]">

			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="Email" className={`text-sm ${formik.touched.Email && formik.errors.Email ? 'text-ceRed' : 'text-main'}`}>Email</label>
			                    <Input 
			                      type="email" 
			                      id="Email" 
			                      placeholder="Enter your email" 
			                      name="Email"
			                      onChange={formik.handleChange} 
			                      error={
			                        formik.touched.Email && formik.errors.Email
			                          ? formik.errors.Email
			                          : undefined
			                      }
			                    />
			                </div>
			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="Password" className={`text-sm ${formik.touched.Password && formik.errors.Password ? 'text-ceRed' : 'text-main'}`}>Password</label>
			                    <PasswordInput 
			                      id="Password" 
			                      placeholder="Enter your password" 
			                      name="Password"
			                      onChange={formik.handleChange} 
			                      error={
			                        formik.touched.Password && formik.errors.Password
			                          ? formik.errors.Password
			                          : undefined
			                      }
			                    />
			                </div>

		                </div>

		               <button type="submit" className="bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">Continue</button>
		               
		               <div className="text-right mt-2">
		               		<div className="text-gray3 underline hover:no-underline text-xs cursor-pointer" onClick={() => switchBlocks('restore')}>Forgot password?</div>
		               </div>
		           </div>
		        </div>
	        </form>

	      	<form className={`w-1/2 flex justify-center ${activeBlock === 'restore' ? '' : 'hidden'}`} onSubmit={formik.handleSubmit}>
		        <div className="flex items-center">
		           <div className="text-center">
		               <h1 className="text-main font-semibold text-2xl">Restore password</h1>
		               <div className="text-gray3 text-sm mt-2">Use your email to reset your password.</div>

		                <div className="my-12 space-y-4 w-[360px]">

			                <div className="w-full space-y-2 text-left">
			                    <label htmlFor="Email" className={`text-sm ${formik.touched.Email && formik.errors.Email ? 'text-ceRed' : 'text-main'}`}>Email</label>
			                    <Input 
			                      type="email" 
			                      id="Email" 
			                      placeholder="Enter your email" 
			                      name="Email"
			                      onChange={formik.handleChange} 
			                      error={
			                        formik.touched.Email && formik.errors.Email
			                          ? formik.errors.Email
			                          : undefined
			                      }
			                    />
			                </div>

		                </div>

		               <button type="submit" className="bg-brandGreen w-full px-4 py-3 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">Continue</button>
		               
		               <div className="text-right mt-2">
		               		<div className="text-brandGreen underline hover:no-underline text-xs cursor-pointer" onClick={() => switchBlocks('login')}>Log In</div>
		               </div>
		           </div>
		        </div>
	        </form>

	        <div className="w-1/2 h-auto overflow-hidden rounded relative">
	          <img src="/img/login-pic.jpg" className="absolute inset-0 w-full h-full object-cover" />
	        </div>
	      
	    </div>
  	);
};

export default Login;