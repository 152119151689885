import { KeyboardEventHandler, ChangeEventHandler } from "react";

type InputProps = {
  id?: string | undefined;
  name?: string;
  type: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
  pattern?: string;
  autoComplete?: string;
  autoCorrect?: string;
  value?: string | number | readonly string[] | undefined;
  error?: string;
  success?: string;
  className?: string;
  onChange?: ChangeEventHandler | undefined;
  onKeyDown?: KeyboardEventHandler | undefined;
  disabled?: boolean;
};

export const Input = ({
  id,
  name,
  type,
  min,
  max,
  step,
  placeholder,
  pattern,
  autoComplete,
  autoCorrect,
  value,
  error,
  success,
  className,
  onChange,
  onKeyDown,
  disabled = false,
}: React.PropsWithChildren<InputProps>) => {
  return (
    <div className="w-full">
      <input
        id={id}
        name={name}
        type={type}
        min={min}
        max={max}
        step={step}
        pattern={pattern}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
        className={`w-full p-3 border rounded-lg outline-none text-sm ${error ? 'border-ceRed' : 'border-gray5 focus:border-main'} disabled:bg-gray6`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      {error && <div className="text-ceRed text-xs mt-2">{error}</div>}
      {success && <div className="text-green1 text-xs mt-2">{success}</div>}
    </div>
  );
};
