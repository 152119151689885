import React, { useState, useEffect, useRef } from 'react';
import { useFormik, FormikErrors } from 'formik';
import * as Yup from 'yup';

import { Input } from "../components/Input";
import { DateInput } from "../components/DateInput";
import { TimeInput } from "../components/TimeInput";
import { Select } from "../components/Select";
import { SelectOption, OptionItem } from "../types";
import { ImageInput } from "../components/ImageInput";
import { Checklist } from "../components/Checklist";

import { FieldConfig, FieldRelation } from '../interfaces';

import { useLoadFormFields } from '../hooks/useLoadFormFields';


type FormGeneratorProps = {
	id?: string;
	fields: Record<string, FieldConfig>;
	fieldRelations?: FieldRelation[];
	onSubmit: (values: Record<string, any>) => void;
};

const FormGenerator: React.FC<FormGeneratorProps> = ({ id, fields: initialFields, fieldRelations, onSubmit }) => {

	const [fields, setFields] = useState(initialFields);
	const [initialValues, setInitialValues] = useState<Record<string, any>>({});
	const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
	const [disabledFields, setDisabledFields] = useState<Record<string, boolean>>({});
  	const [filteredOptions, setFilteredOptions] = useState<Record<string, Record<string, string>>>({});
  	const updatedFieldsRef = useRef<string[]>([]);

  	const { loadingStates: fieldLoadingStates, loadSelectOptions } = useLoadFormFields(fields, setFields);

	const validationSchema = Object.entries(fields).reduce((acc, [key, field]) => {

		if (field.type === 'image') {
		    return acc;
		}

		acc[key] = Yup.string();

		if (field.type === 'checklist') {
		    acc[key] = Yup.array().of(Yup.string());
		}

		switch (field.type) {
		  case 'date':
		    acc[key] = Yup.string().matches(/^(0?[1-9]|[12][0-9]|3[01])-(0?[1-9]|1[0-2])-\d{4}$/, 'Invalid date format');
		    break;
		  case 'time':
		    acc[key] = Yup.string().matches(/^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, 'Invalid time format');
		    break;
		  case 'email':
		    acc[key] = Yup.string().email('E-mail wrong format');
		    break;
		}

		if(field.required) {
			acc[key] = acc[key].required(`${field.label} is required`);
		}

		return acc;

	}, {} as Record<string, Yup.AnySchema>);

	if(fieldRelations) {
		fieldRelations.forEach(relation => {
		    if (relation.type === 'eitherOr' && relation.fields.length === 2) {
		      	const [field1, field2] = relation.fields;

		      	validationSchema[field1] = Yup.string().test(
			        'either-or',
			        relation.message,
			        function (value) {
			          const { [field2]: otherValue } = this.parent;
			          return !!value || !!otherValue;
			        }
			    );

			    validationSchema[field2] = Yup.string().test(
			        'either-or',
			        relation.message,
			        function (value) {
			          const { [field1]: otherValue } = this.parent;
			          return !!value || !!otherValue;
			        }
			    );
		    }
		});
	}


	useEffect(() => {
	    const initialValues: Record<string, any> = Object.entries(fields).reduce((acc, [key, field]) => {
	      acc[key] = '';

	   	  if(field.value) {
	   	  	acc[key] = field.value;
	   	  	formik.setFieldValue(key, field.value);
	   	  }
	      return acc;
	    }, {} as Record<string, any>);
	    setInitialValues(initialValues);

	}, [fields]);

	const formik = useFormik<Record<string, any>>({
	    initialValues,
	    validationSchema: Yup.object(validationSchema),
	    onSubmit,
	});

	useEffect(() => {
	    const newDisabledFields: Record<string, boolean> = {};

	    Object.entries(fields).forEach(([key, field]) => {
	      	if (field.dependsOn) {
	        	const dependentValue = formik.values[field.dependsOn.field];
	        	const condition = field.dependsOn.condition(dependentValue);

	        	if (field.dependsOn.effect === 'disable' || field.dependsOn.effect === 'enable') {
			        const newDisabledState = field.dependsOn.effect === 'disable' ? condition : !condition;
			        newDisabledFields[key] = newDisabledState;
			        if (newDisabledState) {
			          	formik.setFieldValue(key, '');
			        }
			    }
	      	}
	    });

	    setDisabledFields(newDisabledFields);
	}, [formik.values]);

	const getErrorMessage = (error: FormikErrors<Record<string, any>> | undefined, key: string): string | undefined => {
		if (!error) {
			return undefined;
		}

		const errorMessage = error[key];
		if (Array.isArray(errorMessage)) {
			return errorMessage.join(', ');
		}
		if (typeof errorMessage === 'string') {
			return errorMessage;
		}

		return JSON.stringify(errorMessage);
	};

	const handleSelect = (field: string, option: SelectOption) => {
		formik.setFieldValue(field, option.value);

		const dependentFields = Object.entries(fields).filter(([key, fieldConfig]) => {
	    	return fieldConfig.dependsOn && fieldConfig.dependsOn.field === field && fieldConfig.dataFilter;
	    });

	    dependentFields.forEach(([key, fieldConfig]) => {
	    	setFields((prevFields) => {
	            const updatedFields = {
	                ...prevFields,
	                [key]: {
	                    ...prevFields[key],
	                    dataFilter: [option.value]
	                }
	            };
	            updatedFieldsRef.current.push(key);
	            return updatedFields;
	        });
	    });
	};

	useEffect(() => {
	    updatedFieldsRef.current.forEach(key => {
	    	const field = fields[key];
	        if (field && field.dataFilter && field.dataFilter.length > 0) {
	            loadSelectOptions(key);
	            updatedFieldsRef.current = updatedFieldsRef.current.filter(k => k !== key);
	        }
	    });
	}, [fields]);

	const handleChecklist = (key: string, value: string[]) => {
	  	formik.setFieldValue(key, value);
	};

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
	    e.preventDefault();

	    const isValid = await formik.validateForm();

	    // console.log("isValid = ", isValid);
	    if (isValid) {
	      setFormSubmitted(true);
	      formik.submitForm();
	    }
	};

	return (
		<div className="w-full">

			<form onSubmit={handleSubmit}>

			{id && (
	            <Input
	                type="hidden"
	                id="ID"
	                name="id"
	                value={id}
	            />
	        )}
			
			{Object.entries(fields).map(([key, field]) => (
				<div className={`${field.type && ['date', 'time'].includes(field.type) ? 'w-1/2' : 'w-full'} space-y-2 text-left mt-4`}>
                	<label htmlFor={`${key}`} className="text-sm text-main">{field.label}</label>
                  
                  {field.type && field.type === 'hidden' && (
                    <Input
		                type={`hidden`}
		                id={key}
		                name={key}
		                value={formik.values[key]}
		                disabled={disabledFields[key] || fieldLoadingStates?.[key]}
		            />
                  )}                   

                  {field.type && (field.type === 'text' || field.type === 'number') && (
                    <Input
		                type={field.type}
		                id={key}
		                placeholder={`Enter ${field.label}`}
		                name={key}
		                value={formik.values[key]}
		                onChange={formik.handleChange}
		                error={formik.touched[key] || formSubmitted ? getErrorMessage(formik.errors, key) : undefined}
		                disabled={disabledFields[key] || fieldLoadingStates?.[key]}
		            />
                  )}  

                  {field.type && field.type === 'email' && (
                    <Input
		                type={`email`}
		                id={key}
		                placeholder={`Enter ${field.label}`}
		                name={key}
		                value={formik.values[key]}
		                onChange={formik.handleChange}
		                error={formik.touched[key] || formSubmitted ? getErrorMessage(formik.errors, key) : undefined}
		                disabled={disabledFields[key] || fieldLoadingStates?.[key]}
		            />
                  )}                  

                  {field.type && field.options && field.type === 'select' && (
		            <Select
	                    id={key}
	                    placeholder={`Choose ${field.label}`}
	                    value={formik.values[key]}
	                    options={filteredOptions[key] || field.options}
	                    name={key}
	                    onSelected={handleSelect} 
	                    error={formik.touched[key] || formSubmitted ? getErrorMessage(formik.errors, key) : undefined}
	                    disabled={disabledFields[key] || fieldLoadingStates?.[key]}
	                 />
                  )}

                  {field.type && field.type === 'image' && (
                    <ImageInput
	                    id={key}
	                    placeholder="Choose file"
	                    name={key} 
	                    accept="image/*"
	                    onChange={(files) => {
	                      formik.setFieldValue(key, files);
	                    }}
	                />
                  )}

                  {field.type && field.type === 'checklist' && (
                    <Checklist
	                    id={key}
	                    name={key} 
	                    options={field.options ?? {}}
	                    value={formik.values[key] || []}
	                    onChange={(value) => handleChecklist(key, value)}
	                />
                  )}

                  {field.type && field.type === 'date' && (
                    <DateInput
		                id={key}
		                placeholder="DD-MM-YYYY"
		                name={key}
		                value={formik.values[key]}
		                onChange={formik.handleChange}
		                error={formik.touched[key] || formSubmitted ? getErrorMessage(formik.errors, key) : undefined}
		            />
                  )}

                  {field.type && field.type === 'time' && (
                    <TimeInput
		                id={key}
		                placeholder="HH:MM"
		                name={key}
		                value={formik.values[key]}
		                onChange={formik.handleChange}
		                error={formik.touched[key] || formSubmitted ? getErrorMessage(formik.errors, key) : undefined}
		            />
                  )}
                </div>
            ))}


            	<button 
            		type="submit" 
            		disabled={formik.isSubmitting}
            		className="bg-brandGreen w-full px-4 py-3 mt-12 rounded-lg font-semibold text-main text-base drop-shadow-[0_4px_8px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white"
            	>
            	Submit
            	</button>

            </form>

		</div>
	);
};

export default FormGenerator;