import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import { Partner } from '../entities/Partner';

type PartnersConfig = {
  [key: string]: {
    label: string;
    sortKey?: boolean;
  };
};

const Partners = () => {
	const [sortOrder, setSortOrder] = useState('asc');
  	const [sortKey, setSortKey] = useState(null);
  	const [searchTerm, setSearchTerm] = useState('');
  	const [selectedRows, setSelectedRows] = useState<string[]>([]);
  	const [entityData, setEntityData] = useState<{ id: string; name: string; }[]>([]);
  	const navigate = useNavigate();
  	const baseUrl = window.location.pathname;

  	const columnConfig: PartnersConfig = {
		name: { label: 'Name', sortKey: true },
	};

  	const handleSort = (key: any) => {
	    if (sortKey === key) {
	      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	    } else {
	      setSortKey(key);
	      setSortOrder('asc');
	    }
	};

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
	    setSearchTerm(event.target.value);
	};

	const handleRowCheckboxChange = (id: string) => {
	  	setSelectedRows((prevSelectedRows) => {
		    if (prevSelectedRows.includes(id)) {
		      	return prevSelectedRows.filter((rowId) => rowId !== id);
		    } else {
		      	return [...prevSelectedRows, id];
		    }
	  	});
	};

	const handleHeaderCheckboxChange = () => {
	  	if (selectedRows.length === entityData.length) {
	   	 	setSelectedRows([]);
	  	} else {
	    	setSelectedRows(entityData.map((item) => item.id));
	  	}
	};

	const filteredData = sortKey
	    ? entityData
	        .sort((a, b) => {
	          const valueA = a[sortKey];
	          const valueB = b[sortKey];

	          if (valueA < valueB) {
	            return sortOrder === 'asc' ? -1 : 1;
	          }
	          if (valueA > valueB) {
	            return sortOrder === 'asc' ? 1 : -1;
	          }
	          return 0;
	        })
	        .filter((item) =>
	          Object.values(item).some((value) =>
	            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	          )
	        )
	    : entityData.filter((item) =>
	        Object.values(item).some((value) =>
	          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
	        )
	      );

	const fetchPartners = async () => {
      	try {
        	const response = await Partner.getAll();
        	if (typeof response === 'string') {
		    	console.error('Error fetching partners:', response);
		    } else {
		    	const partners = response.content;
			    setEntityData(partners);
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
    };

    useEffect(() => {
	    fetchPartners();
	}, []);

	return (
		<div className="px-8 py-3 w-full h-auto min-h-screen m-auto max-w-[1330px]">

			{/* Search */}
			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Partner management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<span className="text-main">Partners</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-full flex mb-10 justify-between gap-8">
							<div className="w-2/3 relative">
								<div className="absolute top-3 left-5 w-[1.375rem] h-[1.375rem]">
									<svg className="w-full h-full" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M10.625 17.75C14.56 17.75 17.75 14.56 17.75 10.625C17.75 6.68997 14.56 3.5 10.625 3.5C6.68997 3.5 3.5 6.68997 3.5 10.625C3.5 14.56 6.68997 17.75 10.625 17.75Z" stroke="#888A8D" strokeLinecap="round" strokeLinejoin="round"/>
									<path d="M18.5 18.5L17 17" stroke="#888A8D" strokeLinecap="round" strokeLinejoin="round"/>
								</svg>
								</div>
								<input 
									type="text" 
									placeholder="Search"
									value={searchTerm}
									onChange={handleSearch}
									className="w-full h-[2.875rem] pt-1 text-xs text-gray3 outline-none bg-white rounded-[2.5rem] border border-gray4 pl-[3.125rem]" 
								/>
							</div>

							<Link to={`${baseUrl}/add`} className="bg-brandGreen px-6 py-1.5 inline-block flex items-center text-xs font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Partner</Link>
						</div>

						<table className="w-full text-xs">
	  						<thead>
								<tr className="text-gray3 border-b border-b-gray3 text-left">
									<th className="px-2 pb-4 w-10">
										<input 
											type="checkbox" 
									        checked={selectedRows.length === entityData.length}
									        onChange={handleHeaderCheckboxChange}
										/>
									</th>
							    {Object.entries(columnConfig).map(([key, config]) => (
				                    <th
				                      key={key}
				                      className={`font-normal pb-4 ${config.sortKey ? 'cursor-pointer hover:font-semibold' : ''}`}
				                      onClick={() => config.sortKey && handleSort(key)}
				                    >
				                      {config.label}
				                      {config.sortKey && sortKey === key && (
				                        <span className="ml-2">{sortOrder === 'asc' ? '▲' : '▼'}</span>
				                      )}
				                    </th>
				                ))}
								</tr>
							</thead>
	  						<tbody>
							{filteredData.map((item) => (
			                  <tr
							    key={item.id}
							    className={`text-main border-b border-b-gray4 cursor-pointer hover:bg-green8 ${
							        selectedRows.includes(item.id) ? 'bg-gray6' : ''
							    }`}
							    onClick={() => navigate(`${baseUrl}/edit/${item.id}`)}
							  >
			                  	<td className="px-2 w-10">
									<input 
										type="checkbox" 
										id={`item_${item.id}`} 
								        checked={selectedRows.includes(item.id)}
								        onChange={() => handleRowCheckboxChange(item.id)}
									/>
								</td>
								{Object.entries(columnConfig).map(([key, value], index) => (
			                    <td key={`${item.id}-${key}-${index}`} className="py-4">
			                      {(item as any)[key]}
			                    </td>
			                    ))}
			                  </tr>
			                ))}
							</tbody>
	  					</table>
					</div>

				</div>

			</div>

		</div>
	)
};

export default Partners;