import { useEffect, useRef, useState } from "react";
import { SelectOption } from "../types";

type SelectProps = {
  id: string;
  name: string;
  placeholder?: string;
  options: Record<string, string>;
  value: string;
  onSelected?: (name: string, value: SelectOption) => void;
  error?: string;
  disabled?: boolean; 
};

export const selectOptions = (options: Record<string, string>): Array<SelectOption> => {
  const arr: Array<SelectOption> = [];
  
  for (const [key, value] of Object.entries(options)) {
    if (typeof value === 'string') {
      arr.push({ type: 'item', label: value, value: key });
    } else if (typeof value === 'object') {
      arr.push({ type: 'group', label: key, value: `g_${key.replace(/ /g, "_").toUpperCase()}` });
      Object.values<string>(value).forEach((v) => {
        arr.push({ type: 'item', label: v, value: v.replace(/ /g, "_").toUpperCase() });
      });
    }
  }
  return arr;
}

export const Select = ({
  id,
  name,
  placeholder = "Select one",
  options,
  value,
  error,
  onSelected,
  disabled = false,
}: SelectProps) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedLabel, setSelectedLabel] = useState('');
  const [listOpenStatus, setListOpenStatus] = useState(false);
  const [optionsArray, setOptionsArray] = useState(selectOptions(options));
  const excludedElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (excludedElementRef.current && !excludedElementRef.current.contains(e.target as Node)) {
        setListOpenStatus(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  useEffect(() => {
    setOptionsArray(selectOptions(options));
  }, [options]);

  useEffect(() => {
    if (value === '') {
      setSelectedLabel('');
    } else {
      const selectedOption = Object.entries(options).find(([key, label]) => key === value);
      if (selectedOption) {
        const [_, label] = selectedOption;
        setSelectedLabel(label);
      } else {
        setSelectedLabel('');
      }
    }
    
    setSelectedValue(value);

  }, [value, options]);

  const selectValue = (value: string) => {
    setSelectedValue(value);
    if (typeof onSelected === 'function') {
      const selectedOption = optionsArray.find(option => option.value === value);
      if (selectedOption) {
        onSelected(name, selectedOption);
      }
    }
  };

  const handleItemChange = (e: React.MouseEvent<HTMLDivElement>) => {
    const selectedOptionValue = e.currentTarget.dataset.value;
    if (selectedOptionValue !== undefined) {
      selectValue(selectedOptionValue);
      const selectedOptionText = e.currentTarget.textContent;
      setSelectedLabel(selectedOptionText || '');
      setListOpenStatus(false);
    }
  };

  const toggleList = () => {
    if(disabled) return false;
    setListOpenStatus(prev => !prev);
  };

  return (
    <div className="custom-select-wrapper w-full relative z-1">
      <div className="custom-select w-full mb-0 relative text-left" ref={excludedElementRef}>
        <select
          id={id}
          name={name}
          value={selectedValue}
          onChange={(e) => selectValue(e.target.value)}
          className="hidden"
        >
          {optionsArray.map((option) =>
            option.type === "item" ? (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ) : null
          )}
        </select>
        <div
          className={`right-4 top-0 w-[7px] h-full absolute flex items-center ${listOpenStatus ? "rotate-90" : ""}`}
        >
          <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path className={`${!listOpenStatus ? "stroke-gray3" : "stroke-main"}`} d="M5.94213 5.35591L1.3588 0.564199C1.27905 0.4816 1.14722 0.477928 1.06421 0.557695C0.9812 0.637246 0.978349 0.769083 1.0577 0.852287L5.50309 5.49995L1.05769 10.1476C0.978329 10.2308 0.981181 10.3627 1.06419 10.4422C1.10448 10.4809 1.15655 10.5 1.20823 10.5C1.26317 10.5 1.31769 10.4784 1.35878 10.4357L5.94213 5.64399C6.01905 5.56343 6.01905 5.43647 5.94213 5.35591Z" strokeWidth="0.7" />
          </svg>
        </div>
        <div
          className={`selected-item w-full p-3 border rounded-lg outline-none text-sm bg-white ${selectedValue ? "text-main" : "text-gray3"} ${listOpenStatus ? "border-main" : ""} ${error ? 'border-ceRed' : 'border-gray5'} ${disabled ? 'bg-gray6' : 'cursor-pointer'}`}
          onClick={toggleList}
        >
          <div className="w-full h-full overflow-hidden whitespace-nowrap">
          {selectedLabel || placeholder}
          </div>
        </div>
        {listOpenStatus && (
          <div className="all-items absolute top-full left-0 w-full max-h-52 overflow-scroll bg-white border border-gray5 rounded-lg p-3 mt-2 text-sm space-y-2 z-50">
            {optionsArray.map((option) =>
              option.type === "item" ? (
                <div
                  key={option.value}
                  className="item cursor-pointer leading-normal hover:font-medium"
                  data-value={option.value}
                  onClick={handleItemChange}
                >
                  {option.label}
                </div>
              ) : (
                <div key={option.value} className="item-group">
                  {option.label}
                </div>
              )
            )}
          </div>
        )}
      </div>
      {error && <div className="text-ceRed text-xs mt-2">{error}</div>}
    </div>
  );
};
