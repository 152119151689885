import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { AdminMenu } from './AdminMenu';

import './MainLayout.css'; 

interface AdminMainLayoutProps {
	children: ReactNode;
}

const AdminMainLayout: React.FC<AdminMainLayoutProps> = ({ children }) => (
	<div className="flex">
		<AdminMenu />
		<div className="flex w-full h-auto bg-gray7 pl-[17rem] pb-10">
			<Outlet />
			{children}
		</div>
	</div>
);

export default AdminMainLayout;