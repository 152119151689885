import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import Modal from 'react-modal';

import App from './App';

import rootStore from './stores/rootStore';

const rootElement = document.getElementById('root');

Modal.setAppElement('#root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={rootStore}>
      <App />
    </Provider>
  );
} else {
  console.error('Root element not found');
}