import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import FormGenerator from "../components/FormGenerator";

import { FieldConfig, FieldRelation } from '../interfaces';

import { User } from '../entities/User';
import { Organization } from '../entities/Organization';
import { Role } from '../entities/Role';
(window as any).Organization = Organization;
(window as any).Role = Role;


const UserForm = () => {

	const navigate = useNavigate();

	const { id } = useParams();
	const numericId = id || undefined;

	const [formValues, setFormValues] = useState({});

  	const [fields, setFields] = useState<Record<string, FieldConfig>>({
		id: { type: 'hidden' },
		organizationId: { 
			label: 'Organization', 
			type: 'select', 
			options: {}, 
			dataSource: 'Organization',
			dependsOn: {
		    	field: 'partnerId',
		    	condition: (value) => !!value,
		    	effect: 'disable'
		    },
		    emptyOption: true
		},
		partnerId: { 
			label: 'Partner', 
			type: 'select', 
			options: {}, 
			dataSource: 'Partner',
			dependsOn: {
				field: 'organizationId',
				condition: (value) => !!value,
				effect: 'disable'
		    },
		    emptyOption: true
		},
		roleId: { 
			label: 'Role', 
			type: 'select', 
			options: {}, 
			dataSource: 'Role',
			dependsOn: {
		      	field: 'partnerId',
		      	condition: (value) => !!value,
		      	effect: 'enable'
		    },
		    dataFilter: []
		},
		firstName: { label: 'First name', type: 'text', required: true },
		lastName: { label: 'Last name', type: 'text', required: true },
		email: { label: 'Email', type: 'text', required: true },
	});

	const fieldRelations: FieldRelation[] = [
		{
			type: 'eitherOr',
			fields: ['organizationId', 'partnerId'],
			message: 'Either Organization or Partner is required'
		}
	];

	const handleSubmit = async (values: any) => {
	    console.log('Form values:', values);

	    try {
        	const response = await User.add({
				email: values.email,
				firstName: values.firstName,
				lastName: values.lastName,
				organizationId: values.organizationId,
				partnerId: values.partnerId,
				roleId: values.roleId,
			});
        	if (typeof response === 'string') {
		    	console.error('Error adding user:', response);
		    } else {
			    navigate('/admin/users');
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
	};

	return (
		<div className="px-8 py-3 w-full h-auto m-auto max-w-[1330px]">

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">User management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<Link to="/admin/users" className="underline hover:no-underline">Users</Link> / <span className="text-main">{numericId ? 'Edit User' : 'New User'}</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-1/2 space-y-8">

							<h2 className="font-semibold">{numericId ? 'Edit User' : 'Create New User'}</h2>

							<FormGenerator 
								id={numericId} 
								fields={fields}
								fieldRelations={fieldRelations}
								onSubmit={handleSubmit} 
							/>
							
						</div>

					</div>

				</div>

			</div>

		</div>
	)
};

export default UserForm;