import React, { useState } from 'react';

import Icon from '../../components/ui/Icon';

const ProjectPlans = () => {
	const [activeRow, setActiveRow] = useState(false);

	return (
		<div className="flex gap-6 items-start">

			<div className="p-6 mt-6 border-gray5 border rounded-lg space-y-4 bg-white w-3/5 hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]">
				<div className="flex space-x-4 items-stretch">
					<h3 className="font-semibold text-main text-lg inline-block mr-auto">Plans</h3>
					<button className="bg-brandGreen px-2 py-1.5 text-[11px] font-semibold rounded leading-4 drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)] hover:bg-green1 hover:text-white active:bg-green3 active:text-white focus:bg-green2 focus:text-white">+ New Plan</button>
				</div>

				<table className="w-full text-xs">
					<thead>
					<tr className="text-gray3 border-b border-b-gray3 text-left">
				    	<th className="font-normal pb-4 min-w-5"></th>
				    	<th className="font-normal pb-4">Plan name</th>
				    	<th className="font-normal pb-4">Uploaded by</th>
				    	<th className="font-normal pb-4">Uploaded date</th>
				    	<th className="font-normal pb-4 max-w-5"></th>
					</tr>
				</thead>
					<tbody>
					<tr 
						className="text-main border-b border-b-gray4 group"
						onMouseEnter={() => setActiveRow(true)}
      					onMouseLeave={() => setActiveRow(false)}
					>
				    	<td className="py-4">
				    		<Icon name="pdf" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    	</td>
				    	<td className="group-hover:text-green1">Plan name.pdf</td>
				    	<td className="flex py-4 items-center group-hover:text-green1">
				    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
				    		Ammar Foley
				    	</td>
				    	<td className="group-hover:text-green1">08/05/2024</td>
				    	<td>
				    		<a href="#">
				    			<Icon name="download" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    		</a>
				    	</td>
					</tr>
					<tr 
						className="text-main border-b border-b-gray4 group"
						onMouseEnter={() => setActiveRow(true)}
      					onMouseLeave={() => setActiveRow(false)}
					>
				    	<td className="py-4">
				    		<Icon name="pdf" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    	</td>
				    	<td className="group-hover:text-green1">Plan name.pdf</td>
				    	<td className="flex py-4 items-center group-hover:text-green1">
				    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
				    		Ammar Foley
				    	</td>
				    	<td className="group-hover:text-green1">01/05/2024</td>
				    	<td>
				    		<a href="#">
				    			<Icon name="download" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    		</a>
				    	</td>
					</tr>
					<tr 
						className="text-main border-b border-b-gray4 group"
						onMouseEnter={() => setActiveRow(true)}
      					onMouseLeave={() => setActiveRow(false)}
					>
				    	<td className="py-4">
				    		<Icon name="pdf" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    	</td>
				    	<td className="group-hover:text-green1">Plan name.pdf</td>
				    	<td className="flex py-4 items-center group-hover:text-green1">
				    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
				    		Ammar Foley
				    	</td>
				    	<td className="group-hover:text-green1">01/05/2024</td>
				    	<td>
				    		<a href="#">
				    			<Icon name="download" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    		</a>
				    	</td>
					</tr>
					<tr 
						className="text-main group"
						onMouseEnter={() => setActiveRow(true)}
      					onMouseLeave={() => setActiveRow(false)}
					>
				    	<td className="py-4">
				    		<Icon name="pdf" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    	</td>
				    	<td className="group-hover:text-green1">Plan name.pdf</td>
				    	<td className="flex py-4 items-center group-hover:text-green1">
				    		<img src="/img/avatar.png" className="w-[1.125rem] h-[1.125rem] rounded-full mr-2" />
				    		Ammar Foley
				    	</td>
				    	<td className="group-hover:text-green1">01/05/2024</td>
				    	<td>
				    		<a href="#">
				    			<Icon name="download" className="w-4 h-4 fill-main group-hover:fill-green1" />
				    		</a>
				    	</td>
					</tr>
				</tbody>
				</table>
			</div>

			<div className="w-2/5">
				<div className={`p-6 mt-6 border-gray5 border rounded-lg space-y-4 bg-white w-full ${activeRow ? '' : 'hidden'} hover:drop-shadow-[0_6px_20px_rgba(0,0,0,0.14)]`}>
					<h3 className="font-semibold text-main text-lg">Plan Preview</h3>

					<img src="/img/plan-example.png" className="w-full" />
				</div>
			</div>

		</div>
	);
};

export default ProjectPlans;