import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

const BASE_URL = 'https://app-dev.charge-easy.ch/api/v1';
//const BASE_URL = 'http://localhost:8081';

interface ApiErrorResponse {
  message: string;
  code: number;
}

export class ApiError extends Error {
  public statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

export class ApiHandler {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const errorData = error.response.data;
          throw new ApiError(errorData.error, errorData.status);
        } else {
          throw new ApiError(error.message, 0);
        }
      }
    );
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.get(url, config).then((response) => response.data);
  }

  public post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.post(url, data, config).then((response) => response.data);
  }

  public put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.put(url, data, config).then((response) => response.data);
  }

  public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.axiosInstance.delete(url, config).then((response) => response.data);
  }

  public static objectToFormData(obj: Record<string, any>, form?: FormData, namespace?: string): FormData {
    const fd = form || new FormData();

    for (let property in obj) {
      const formKey = namespace ? `${namespace}[${property}]` : property;

      if (obj[property] instanceof Date) {
        fd.append(formKey, obj[property].toISOString());
      } else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        ApiHandler.objectToFormData(obj[property], fd, formKey);
      } else {
        console.log("formKey = " + formKey + " | obj = " + obj[property]);
        fd.append(formKey, obj[property]);
      }
    }

    return fd;
  }

  public static getQueryString(params: Record<string, string | number | boolean | (string | number | boolean)[]>) {
    const queryString = Object.entries(params)
      .filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.some((item) => item !== null && item !== undefined && item !== '');
        }
        return value !== null && value !== undefined && value !== '';
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((item) => {
            if (typeof item === 'string' || typeof item === 'number' || typeof item === 'boolean') {
              return `${key}=${encodeURIComponent(item.toString())}`;
            }
            throw new Error('Invalid value type');
          }).join('&');
        }
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
          return `${key}=${encodeURIComponent(value.toString())}`;
        }
        throw new Error('Invalid value type');
      })
      .join('&');

    return queryString;
  }
}

export const apiHandler = new ApiHandler();