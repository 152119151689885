import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

type TaskTableRowProps = {
	title: string;
};

const TaskTableRow = ({
  title
}: React.PropsWithChildren<TaskTableRowProps>) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleTaskExpand = () => {
	    setIsOpen(!isOpen);
	};

	return (
		<>
			<tr className="text-main">
		    	<td className="py-4 px-3 font-semibold text-main">
		    		<div className="flex place-items-center">
			    		<img src="/img/icons/arrow.svg" className={`h-2 w-1.5 mr-3 cursor-pointer ${isOpen ? 'rotate-90' : ''}`} onClick={handleTaskExpand} />
			    		Task Name
						<img src="/img/icons/hint.svg" className="h-3 w-3 ml-2 cursor-pointer" />
		    		</div>
		    	</td>
		    	<td className="flex py-4 px-3 place-items-center">
		    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white mr-2" />
		    		04.04.2024
		    	</td>
		    	<td className="px-3">Category</td>
		    	<td className="px-3">
		    		<div className="px-2 py-1 bg-green4 text-xs text-green1 rounded inline-block min-[1553px]:ml-auto">Done</div>
		    	</td>
		    	<td className="px-3">
		    		<div className="flex place-items-center">
			    		<div className="w-full h-2 bg-gray6 rounded mr-2">
								<div className="w-[100%] h-full bg-brandGreen rounded"></div>
							</div>
							100%
						</div>
		    	</td>
		    	<td className="px-3">Medium</td>
		    	<td className="flex px-3 place-items-center">
		    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white z-10" />
		    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5" />
		    		<div className="px-2 py-1 border border-gray5 rounded text-xs text-main flex ml-1">
							<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
							31.04.2024
						</div>
		    	</td>
			</tr>

			<CSSTransition
			  in={isOpen}
			  timeout={100}
			  classNames="collapse"
			  unmountOnExit
			>
				<>
					<tr className={`text-main border-b border-b-gray4`}>
				    	<td className="py-4 pr-3 pl-7 text-main">
				    		<div className="flex place-items-center">
					    		→ Subtask 1
								<img src="/img/icons/hint.svg" className="h-3 w-3 ml-2 cursor-pointer" />
				    		</div>
				    	</td>
				    	<td className="flex py-4 px-3 place-items-center">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white mr-2" />
				    		04.04.2024
				    	</td>
				    	<td className="px-3">Category</td>
				    	<td className="px-3">
				    		<div className="px-2 py-1 bg-green4 text-xs text-green1 rounded inline-block min-[1553px]:ml-auto">Done</div>
				    	</td>
				    	<td className="px-3">
				    		<div className="flex place-items-center">
					    		<div className="w-full h-2 bg-gray6 rounded mr-2">
										<div className="w-[100%] h-full bg-brandGreen rounded"></div>
									</div>
									100%
								</div>
				    	</td>
				    	<td className="px-3">Medium</td>
				    	<td className="flex px-3 place-items-center">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white z-10" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5" />
				    		<div className="px-2 py-1 border border-gray5 rounded text-xs text-main flex ml-1">
									<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
									31.04.2024
								</div>
				    	</td>
					</tr>
					<tr className={`text-main border-b border-b-gray4`}>
				    	<td className="py-4 pr-3 pl-7 text-main">
				    		<div className="flex place-items-center">
					    		→ Subtask 2
								<img src="/img/icons/hint.svg" className="h-3 w-3 ml-2 cursor-pointer" />
				    		</div>
				    	</td>
				    	<td className="flex py-4 px-3 place-items-center">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white mr-2" />
				    		04.04.2024
				    	</td>
				    	<td className="px-3">Category</td>
				    	<td className="px-3">
				    		<div className="px-2 py-1 bg-green4 text-xs text-green1 rounded inline-block min-[1553px]:ml-auto">Done</div>
				    	</td>
				    	<td className="px-3">
				    		<div className="flex place-items-center">
					    		<div className="w-full h-2 bg-gray6 rounded mr-2">
										<div className="w-[100%] h-full bg-brandGreen rounded"></div>
									</div>
									100%
								</div>
				    	</td>
				    	<td className="px-3">Medium</td>
				    	<td className="flex px-3 place-items-center">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white z-10" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5" />
				    		<div className="px-2 py-1 border border-gray5 rounded text-xs text-main flex ml-1">
									<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
									31.04.2024
								</div>
				    	</td>
					</tr>
					<tr className={`text-main border-b border-b-gray4`}>
				    	<td className="py-4 pr-3 pl-7 text-main">
				    		<div className="flex place-items-center">
					    		→ Subtask 3
								<img src="/img/icons/hint.svg" className="h-3 w-3 ml-2 cursor-pointer" />
				    		</div>
				    	</td>
				    	<td className="flex py-4 px-3 place-items-center">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white mr-2" />
				    		04.04.2024
				    	</td>
				    	<td className="px-3">Category</td>
				    	<td className="px-3">
				    		<div className="px-2 py-1 bg-green4 text-xs text-green1 rounded inline-block min-[1553px]:ml-auto">Done</div>
				    	</td>
				    	<td className="px-3">
				    		<div className="flex place-items-center">
					    		<div className="w-full h-2 bg-gray6 rounded mr-2">
										<div className="w-[100%] h-full bg-brandGreen rounded"></div>
									</div>
									100%
								</div>
				    	</td>
				    	<td className="px-3">Medium</td>
				    	<td className="flex px-3 place-items-center">
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white z-10" />
				    		<img src="/img/avatar.png" className="w-[1.875rem] h-[1.875rem] rounded-full border border-[3px] border-white -ml-2.5" />
				    		<div className="px-2 py-1 border border-gray5 rounded text-xs text-main flex ml-1">
									<img src="/img/icons/flag.svg" className="mr-1 w-3 h-3 mt-0.5" />
									31.04.2024
								</div>
				    	</td>
					</tr>
				</>
			</CSSTransition>
		</>
	);
};

export default TaskTableRow;