import { ChangeEventHandler } from "react";

type TextareaProps = {
  id?: string | undefined;
  name?: string;
  placeholder?: string;
  value?: string | number | readonly string[] | undefined;
  error?: string;
  className?: string;
  onChange?: ChangeEventHandler | undefined;
  disabled?: boolean;
};

export const Textarea = ({
  id,
  name,
  placeholder,
  value,
  error,
  className,
  onChange,
  disabled = false,
}: React.PropsWithChildren<TextareaProps>) => {

  return (
    <div>
      <textarea 
        id={id}
        placeholder={placeholder}
        name={name}
        className={`w-full p-3 h-[130px] border rounded-lg focus:outline-main text-sm resize-none ${error ? 'border-ceRed' : 'border-gray5'} disabled:bg-gray6`}
        value={value}
        onChange={onChange}
        disabled={disabled}
      ></textarea>
      <div className="text-ceRed text-xs mt-1 ${error ? '' : 'hidden'}">
        {error ? error : '' }
      </div>
    </div>
  );
};
