import { apiHandler, ApiHandler, ApiError } from '../api';
import axios, { AxiosError } from 'axios';

export class Property {
  id: string;
  category: string;
  propertyNumber: string;
  categories: { id: string; name: string }[] = [];
  files: { id: string; name: string }[] = [];

  constructor(id: string, category: string, propertyNumber: string) {
    this.id = id;
    this.category = category;
    this.propertyNumber = propertyNumber;
  }

  static async getById(id: string): Promise<Property> {
    const data = await apiHandler.get<any>(`/properties/${id}`);
    return new Property(data.id, data.category, data.comment);
  }

  static async getByNumber(propertyNumber: string): Promise<Property | string | undefined> {
    try {
      const data = await apiHandler.get<any>(`/public/properties/search?propertyNumber=${propertyNumber}`);
      return new Property(data.id, '', data.propertyNumber);
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Property not found';
      } else {
        return 'An error occurred while fetching the property';
      }
    }
  }

  static async getByAddress(params: {
      country: string;
      zip: string;
      city: string;
      street: string;
      streetNumber: string;
    }): Promise<Property | string | undefined> {
    try {
      const data = await apiHandler.get<any>(`/public/properties/search?${ApiHandler.getQueryString(params)}`);
      return new Property(data.id, '', data.propertyNumber);
    } catch (error: any) {
      if (error.statusCode === 404) {
        return 'Property not found';
      } else {
        return 'An error occurred while fetching the property';
      }
    }
  }

  async getCategories(): Promise<void> {
    try {
      const categories = await apiHandler.get<{ id: string; name: string }[]>(`/public/project-categories?propertyId=${this.id}`);
      this.categories = categories;
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  }

}