import React from 'react';

interface ConfirmModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-brandGreen bg-opacity-10">
      <div className="w-[400px] bg-white border border-gray4 rounded-lg p-8 text-center">
        <h2 className="text-xl font-medium">Confirm Delete</h2>
        <p className="my-4 text-sm">Do you confirm deleting of the selected rows?</p>
        <div className="flex gap-8 justify-center mt-8">
          <button
            className="bg-red-300 px-6 h-10 inline-block flex items-center text-xs font-semibold rounded drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)]"
            onClick={onConfirm}
          >
            Confirm
          </button>
          <button
            className="bg-gray4 px-6 h-10 inline-block flex items-center text-xs font-semibold rounded drop-shadow-[0_2px_2px_rgba(0,0,0,0.1)]"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;