import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import FormGenerator from "../components/FormGenerator";

import { organizationTypesDict, countriesDict } from '../utils/constants';

import { Organization } from '../entities/Organization';
import { User } from '../entities/User';
(window as any).User = User;

interface FieldConfig {
	type: string;
	label?: string;
	required?: boolean;
	options?: Record<string, string>;
	dataSource?: string;
}

const typeOptions = organizationTypesDict;
const countryOptions = countriesDict;

const OrganizationForm = () => {

	const { id } = useParams();
	const navigate = useNavigate();

	const numericId = id || undefined;

  	const [fields, setFields] = useState<Record<string, FieldConfig>>({
		id: { type: 'hidden' },
		name: { label: 'Name', type: 'text', required: true },
		type: { label: 'Type', type: 'select', required: true, options: typeOptions },
		logo: { label: 'Logo', type: 'image' },
		adminEmail: { label: 'Admin Email', type: 'email', required: true },
		country: { label: 'Country', type: 'select', options: countryOptions },
		canton: { label: 'Region', type: 'text' },
		city: { label: 'City', type: 'text' },
		zip: { label: 'Zip', type: 'text' },
		street: { label: 'Street', type: 'text' },
		streetNumber: { label: 'Street number', type: 'text' },
	});

	const handleSubmit = async (values: any) => {
	    console.log('Form values:', values);

	    try {
        	const response = await Organization.add({
        		name: values.name,
        		type: values.type,
        		logo: values.logo,
        		adminEmail: values.adminEmail,
        		country: values.country,
        		canton: values.canton,
        		city: values.city,
        		zip: values.zip,
        		street: values.street,
        		streetNumber: values.streetNumber,
        	});
        	if (typeof response === 'string') {
		    	console.error('Error adding organization:', response);
		    } else {
			    navigate('/admin/orgs');
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
	    
	};

	return (
		<div className="px-8 py-3 w-full h-auto m-auto max-w-[1330px]">

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Organization management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<Link to="/admin/orgs" className="underline hover:no-underline">Organizations</Link> / <span className="text-main">New Organization</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-1/2 space-y-8">

							<h2 className="font-semibold">Create New Organization</h2>

							<FormGenerator id={numericId} fields={fields} onSubmit={handleSubmit} />
							
						</div>

					</div>

				</div>

			</div>

		</div>
	)
};

export default OrganizationForm;