export const organizationTypesDict = {
  "electrician": "Electrician",
  "pmo": "PMO",
  "property_management": "Property Management",
  "property_owner": "Property Owner",
  "utility": "Utility",
};

export const countriesDict = {
  // "AT": "🇦🇹 Austria",
  // "DE": "🇩🇪 Germany",
  "CH": "🇨🇭 Switzerland",
};

export const permissionsDict = {
	"view_own_projects": "View own projects",
	"view_all_partner_projects": "View all partner projects",
	"manage_requests": "Manage requests",
	"view_requests": "View requests",
	"manage_projects": "Manage projects",
};

export const fileCategoriesDict = {
	"floor_plan": "Floor Plan",
	"legal_document": "Legal Document",
	"offer": "Offer",
	"customer_communication": "Customer Communication",
	"others": "Other",
};

export const languagesDict = {
	"DE": "Deutsch",
	"FR": "Francais",
	"IT": "Italiano",
	"RM": "Rumaunas",
};