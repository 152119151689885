import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import FormGenerator from "../components/FormGenerator";

import { ProjectCategory } from '../entities/ProjectCategory';
import { Partner } from '../entities/Partner';
(window as any).Partner = Partner;

interface FieldConfig {
	type: string;
	label?: string;
	required?: boolean;
	options?: Record<string, string>;
	dataSource?: string;
}

const ProjectCategoryForm = () => {

	const location = useLocation();
	const navigate = useNavigate();
  	const queryParams = new URLSearchParams(location.search);
  	const partnerId = queryParams.get('partnerId') ?? '';
	const numericId = undefined;

  	const [fields, setFields] = useState<Record<string, FieldConfig>>({
		id: { type: 'hidden' },
		partnerId: { label: 'Partner', type: 'select', required: true, options: {}, dataSource: 'Partner' },
		name_de: { label: 'Category name (DE)', type: 'text', required: true },
		name_fr: { label: 'Category name (FR)', type: 'text', required: true },
		name_it: { label: 'Category name (IT)', type: 'text', required: true },
		name_rm: { label: 'Category name (RM)', type: 'text', required: true },
	});


	const handleSubmit = async (values: any) => {
	    console.log('Form values:', values);

	    try {
        	const response = await ProjectCategory.add(
        		values.partnerId,
        		values.name_de,
        		values.name_fr,
        		values.name_it,
        		values.name_rm,
        	);
        	if (typeof response === 'string') {
		    	console.error('Error adding role:', response);
		    } else {
			    navigate('/admin/project-categories');
		    }
      	} catch (error) {
        	console.error('Error fetching:', error);
      	}
	};

	return (
		<div className="px-8 py-3 w-full h-auto m-auto max-w-[1330px]">

			<div className="w-full flex mb-6">
				<div className="w-full relative h-5"></div>
			</div>

			{/* Heading */}
			<div className="flex items-center">
				<h1 className="font-semibold text-2xl text-main inline-block">Project Category management</h1>
			</div>

			{/* Breadcrump */}
			<div className="mt-2 text-sm text-gray3">
				<Link to="/admin/project-categories" className="underline hover:no-underline">Project Categories</Link> / <span className="text-main">New Category</span>
			</div>

			<div className="pt-6">

				<div className="space-x-6 flex w-full">

					<div className="p-6 border-gray5 border rounded-lg space-y-4 bg-white w-full">

						<div className="w-1/2 space-y-8">

							<h2 className="font-semibold">Create New Project Category</h2>

							<FormGenerator id={numericId} fields={fields} onSubmit={handleSubmit} />
							
						</div>

					</div>

				</div>

			</div>

		</div>
	)
};

export default ProjectCategoryForm;